import React, { useEffect } from "react";
import s from "../InterlinkingMain.module.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getInterlinkLocation } from "../../../redux/settlement_Reducer";
import { StateType } from "../../../redux/redux-store";
import { scrollToTop } from "../../../common/Logic/supporting";
import { useTranslation } from "react-i18next";
import { InterlinkSettlement } from "../../../types/settlementTypes";

interface Props {
	page: "Home" | "MainCategory" | "SearchResults" | "OutputBordAD";
	categoryTree: { alias: string; name: string; name_ru: string }[] | null;
}

const InterLinkLocation: React.FC<Props> = ({ page, categoryTree }) => {
	const dispatch: any = useDispatch();
	const { i18n } = useTranslation();
	const getLinks = useSelector(() => getInterlinkLocation);

	const interlinkSettlements = useSelector(
		(state: StateType) => state.settlementData.interlinkSettlements
	);

	const languagePath = i18n.language === "ru" ? "" : "ua/";
	const baseUrl = categoryTree
		?.map((s, index) => {
			if (index === 0) {
				if (categoryTree?.length === 2) {
					return `${s.alias}/c2/${s}`;
				} else {
					return `${s.alias}/c2`;
				}
			} else {
				return s.alias;
			}
		})
		.join("/");

	const linkGenerator = (slug: string) => {
		switch (page) {
			case "Home":
				return `/${languagePath}search_result?search-location=${slug}`;
			case "MainCategory":
				return ``;
			case "SearchResults":
				return `/${languagePath}c/${baseUrl}?search-location=${slug}`;
				case "OutputBordAD":
					return ``
		}
	};

	useEffect(() => {
		dispatch(getLinks());
	}, [i18n.language]);

	return (
		<ul className={s.linksContainer}>
			{interlinkSettlements?.map((item:InterlinkSettlement) => (
				<li className={s.linkItem} key={`interlinkSettlements_${item.id}`}>
					<Link to={linkGenerator(item.slug)} onClick={() => scrollToTop()}>
						{i18n.language === "ru" ? item.description_ru : item.description}
					</Link>
				</li>
			))}
		</ul>
	);
};

export default InterLinkLocation;
