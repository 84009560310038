import { ActionType } from "../types/reduxStoreTypes";
import { settlementAPI } from "../api/api";
import { Dispatch } from "redux";
import {
	SearchingLocations,
	settlementDataType,
} from "../types/settlementTypes";

const SET_ALL_SETTLEMENT_LIST =
	"bago/settlementReducer/SET_ALL_SETTLEMENT_LIST";
const SET_SETTLEMENT_INDEX = "bago/settlementReducer/SET_SETTLEMENT_INDEX";
const SET_SETTLEMENT_BY_COORDINATES =
	"bago/settlementReducer/SET_SETTLEMENT_BY_COORDINATES";
const SET_SEARCHING_LOCATION_INFO =
	"bago/settlementReducer/SET_SEARCHING_LOCATION_INFO";
const SET_ALL_DISTRICT_LIST = "bago/settlementReducer/SET_ALL_DISTRICT_LIST";
const SET_SETTLEMENT_VIEW = "bago/settlementReducer/SET_SETTLEMENT_VIEW";
const SET_INTERLINK_SETTLEMENTS = "bago/settlementReducer/SET_INTERLINK_SETTLEMENTS";

const initialState: settlementDataType = {
	status: "",
	settlementByLocation: [],
	settlementByLocationOnlyDistrict: { id: "", district: "" },
	settlements: [],
	settlementsForSearch: [],
	searchingLocationInfo: null,
	allDistrictList: [],
	settlementIndex: [],
	settlementView: null,
	interlinkSettlements:null
};

const settlement_Reducer = (state = initialState, action: ActionType) => {
	switch (action.type) {
		case SET_ALL_SETTLEMENT_LIST:
			type ActionItem =any// { id: string; text: string; slug: string };

			const dataArray = Array.isArray(action.data) ? action.data : [action.data];
			const newOptions = dataArray?.map((item: ActionItem) => ({
				value: item?.id ?? "",
				label: item?.text ?? "",
				slug: item?.slug ?? "",
			}));
			const formattingLocation = dataArray?.map((item: ActionItem) => {
				const parts = item?.text?.split(",").map((part:any) => part.trim());
				return {
					id: item?.id ?? "",
					city: parts?.[0] || "",
					district: parts?.[1] || "",
					slug: item?.slug ?? "",
				};
			});

			return {
				...state,
				settlements: newOptions,
				settlementsForSearch: formattingLocation,
			};
		case SET_SETTLEMENT_BY_COORDINATES:
			const currentUserLocation = action.data.map((item: any) => ({
				value: item.id,
				label: item.text,
			}));
			const districtsLocation = action.data;

			return {
				...state,
				settlementByLocation: currentUserLocation,
				settlementByLocationOnlyDistrict: {
					id: districtsLocation[0]?.area_id || "",
					district: districtsLocation[0]?.center_name || "",
				},
			};
		case SET_SEARCHING_LOCATION_INFO:
			return {
				...state,
				searchingLocationInfo: action.data,
			};
		case SET_ALL_DISTRICT_LIST:
			return {
				...state,
				allDistrictList: action.data,
			};
		case SET_SETTLEMENT_INDEX:
			return {
				...state,
				settlementIndex: action.data,
			};
		case SET_SETTLEMENT_VIEW:
			return {
				...state,
				settlementView: action.data,
			};
		case SET_INTERLINK_SETTLEMENTS:

			return {
				...state,
				interlinkSettlements: action.data,
			};
		default:
			return state;
	}
};

export const setSettlementList = (data: any) => ({
	type: SET_ALL_SETTLEMENT_LIST,
	data,
});
export const setSettlementByLocation = (data: any) => ({
	type: SET_SETTLEMENT_BY_COORDINATES,
	data,
});
export const setSearchingLocationInfo = (data: SearchingLocations | null) => ({
	type: SET_SEARCHING_LOCATION_INFO,
	data,
});

export const setSettlementIndexList = (data: any) => ({
	type: SET_SETTLEMENT_INDEX,
	data,
});
export const setInterlinkSettlements = (data: any) => ({
	type: SET_INTERLINK_SETTLEMENTS,
	data,
});

export const getSettlementList =
	(searchLetters: string, format?: number) => async (dispatch: Dispatch) => {
		try {
			const response: any = await settlementAPI.getSettlementsSearch(
				searchLetters,
				format
			);

			if (response.data?.settlements) {
				dispatch(setSettlementList(response.data?.settlements));
			}
		} catch (error: any) {
			console.log(error.message);
		}
	};
export const getSettlementIndex =
	(
		onlyCities?: number,
		description?: string | null,
		page?: string,
		perPage?: string
	) =>
	async (dispatch: Dispatch) => {
		try {
			const response: any = await settlementAPI.getSettlementIndex(
				onlyCities,
				description,
				page,
				perPage
			);
			if (response.data.items) {
				dispatch(setSettlementIndexList(response.data.items));
			}
		} catch (error: any) {
			console.log(error.message);
		}
	};

export const getSettlementByLocation =
	(location: { latitude: string; longitude: string }) =>
	async (dispatch: Dispatch) => {
		try {
			const response: any =
				await settlementAPI.getSettlementByLocation(location);
			if (response.data?.settlements) {
				dispatch(setSettlementByLocation(response.data?.settlements));
			}
		} catch (error: any) {
			console.log(error.message);
		}
	};
export const getSettlementView =
	(slug: string,removeQuery:()=>void) => async (dispatch: Dispatch) => {
		try {
			const response: any = await settlementAPI.getSettlementBySlug(slug);
			dispatch(setSearchingLocationInfo(response.data.settlement));
		} catch (error: any) {
			removeQuery()
			console.log(error.message);
		}
	};
export const getInterlinkLocation =
	() => async (dispatch: Dispatch) => {
		try {
			const response: any = await settlementAPI.getCrossLink();
			if (response.data?.status === "ok") {
				dispatch(setInterlinkSettlements(response.data.settlements));
			}

		} catch (error: any) {
			console.log(error.message);
		}
	};

export default settlement_Reducer;
