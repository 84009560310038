import React from "react";
import s from "./Registration&AuthForms.module.css";
import AuthSvgSelector from "./AuthSvgSelector";
import { Desktop, Mobile } from "../../responsive";
import { useTranslation } from "react-i18next";
import { useGoogleLogin } from "@react-oauth/google";
//import FacebookLogin from "@greatsumini/react-facebook-login";
import { useDispatch, useSelector } from "react-redux";
import { getNetworkAuthUser, setNavigateAfterSuccess } from "../../redux/user_Reducer";
import AppleAuth from "./AppleAuth";
import { useNavigate } from "react-router";
import { type StateType } from "../../redux/redux-store";

type Props = {
	closeModal: (b: boolean) => void;
	setErrorApple: (d: string) => void;
	errorApple: string;
};
const FastEnter = ({ closeModal, setErrorApple, errorApple }: Props) => {
	const dispatch: any = useDispatch();
	const getAuth = useSelector(() => getNetworkAuthUser);
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const navigateAfterSuccess = useSelector((state: StateType) => state.userData.navigateAfterSuccess);
	const languagePath = i18n.language === "ru" ? "" : "ua/";
	const navigateAfterSuccessfull = () => {
		if (navigateAfterSuccess) {
			navigate(`/${languagePath}personal_cabinet`);
		} else {
			dispatch(setNavigateAfterSuccess(true));
		}
	};
	const googleLogin = useGoogleLogin({
		redirect_uri: "https://bago.ua",
		onSuccess: async ({ code }) => {
			const params = {
				code: code,
				clientName: "google",
			};
			dispatch(getAuth(params, closeModal, setErrorApple, navigateAfterSuccessfull));
		},
		flow: "auth-code",
	});

	return (
		<div className={s.fastEnter}>
			<p>{t(`Швидкий вхід`)}:</p>
			<div className={s.buttonsContainer}>
				<button onClick={() => googleLogin()}>
					<span className={s.icon}>
						<AuthSvgSelector id={"googleIcon"} />
					</span>
					<Desktop>
						<span>Google</span>
					</Desktop>
				</button>
				<AppleAuth
					setErrorApple={setErrorApple}
					closeModal={closeModal}
					type={"reg"}
				/>

			</div>
			<Mobile>
				<div className={s.buttonsNamesContainer}>
					{/* <span>Facebook</span> */}
					<span>Google</span>
					<span>Apple ID</span>
				</div>
			</Mobile>
			{errorApple !== "" && (
				<span className={s.errorApple}>{errorApple}</span>
			)}
		</div>
	);
};

export default FastEnter;
