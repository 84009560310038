import { publicOneNotice } from "../../../types/publicNoticeTypes";
import s from "./MobileProductCard.module.css";
import { VacancyType, WorkInfoList } from "../../../pages/OutputBordAD/WorkNotice/WorkInfoList/WorkInfo";
import WorkSkeletonComponentMobile from "../../WorkSkeletonComponent/WorkSkeletonComponentMobile";

const MobileWorkProductCard = ({
	notice,
	topHeart,
	top,
	image
}: {
	notice: publicOneNotice;
	topHeart: JSX.Element | null;
	top: JSX.Element | null;
	image?: JSX.Element | null;
}) => {
	const workType = notice.noticeType?.notice_properties?.find(prop => prop.specialViewOnFront === "work_search_propose")?.propertyValues;


	return (<>
		{top}
		
		<div className={s.topHeart} onClick={(e) => e.stopPropagation()}>
			{topHeart}
		</div>

		<div className={s.cardWorkImage}>
			{image ?? (
				workType && (
					<WorkSkeletonComponentMobile type={workType} category={notice.category_name}/>
				))
			}
		</div>

		<div className={s.infoContainer}>
			<div className={s.cardName}>
				<h4>{notice?.name || ""} </h4>
			</div>

			<div className={s.cardWorkContent}>
				<WorkInfoList notice={notice} card />
				{notice.noticeType?.vacancyType &&
					<VacancyType type={notice.noticeType?.vacancyType} card />
				}
			</div>
		</div>
	</>);
};

export default MobileWorkProductCard;
