import { useTranslation } from "react-i18next";
import s from "./WorkSkeletonComponent.module.css";

type Props = {
	type?: string;
	category: string;
	isRow?: boolean;
	isRound?: boolean;
};

const WorkSkeletonComponent = ({ type, category, isRow, isRound }: Props) => {
	const { t } = useTranslation();

	let workType: { title: string; color: string; borderColor: string } | null;
	switch (type) {
		case "пропоную":
		case "предлагаю":
			workType = {
				title: t("Пропоную роботу"),
				color: "#F9FEFF",
				borderColor: "#E1F0FE"
			};
			break;
		case "шукаю":
		case "ищу":
			workType = {
				title: t("Шукаю роботу"),
				color: "#f8fcf8",
				borderColor: "#CCFFBF"
			};
			break;
		default:
			workType = {
				title: t("Робота"),
				color: "#F9FEFF",
				borderColor: "#E1F0FE"
			};
	}

	return (
		<div className={`${s.container} ${isRow ? s.row : ''} ${isRound ? s.round : ''}`} style={{ backgroundColor: workType.color, borderColor: workType.borderColor }}>
			<div className={s.type}>{workType.title}</div>
			<div className={s.category} title={category}>{category}</div>
		</div>
	);
};

export default WorkSkeletonComponent;
