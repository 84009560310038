import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { StateType } from "../../redux/redux-store";
import s from "./Footer.module.css";
import FooterSvgSelector from "./FooterSvgSelector";
import {
	setPhoneEnterPopup,
} from "../../redux/user_Reducer";
import { setClearFiltersList } from "../../redux/category_Reducer";
import { setImagesList } from "../../redux/notice_Reducer";
import { stateUserType } from "../../types/reduxStoreTypes";
import { useNavigate } from "react-router";
import EnterPhonePopup from "../CommonPopups/AttentionInfoPopup/EnterPhonePopup";
import { FormikContext } from "formik";

/*const useStyles = makeStyles(() => ({
	badge: {
		width: "12px",
		height: "12px",
		right: 0,
		top: 3,
		padding: "3px",
		color: "black",
		backgroundColor: "#FFD74A",
		fontSize: "8px",
	},
}));*/

const MobileFooter: React.FC = () => {
	//const classes = useStyles();
	const dispatch: any = useDispatch();
	const { i18n, t } = useTranslation();
	const userView = useSelector((state: StateType) => state.userData.user);
	const location = useLocation();
	const currentLanguage = i18n.language === "ru" ? "" : "ua/";
	const isCreateAdPage = location.pathname.includes("/create_ad");
	const [currentPath, setCurrentPath] = useState<string>(location.pathname);
	const formik = useContext(FormikContext);
	const phoneEnterPopup = useSelector(
		(state: StateType) => state.userData.phoneEnterPopup
	);

	const auth = useSelector((state: stateUserType) => state.userData.isAuth);
	const navigate = useNavigate();

	const clearInputs = () => {
		dispatch(setImagesList([]));
		dispatch(setClearFiltersList());
	};
	const handleClosePopup = () => {
		dispatch(setPhoneEnterPopup(false));
	};
	const handleClickCreateAd = () => {
		if (!auth) return;

		if (userView.is_approve_phone !== 1) {
			dispatch(setPhoneEnterPopup(true));
			return;
		}
		if (isCreateAdPage) {
			alert(
				t(
					"Оголошення, яке ви розпочали подавати," +
						" ще не було опубліковано. Бажаєте повернутися до цього оголошення?"
				)
			);
			formik.resetForm();
		} else {
			clearInputs();
			navigate(`/${currentLanguage}create_ad`);
		}
	};
	useEffect(() => {
		setCurrentPath(location.pathname);
	}, [location.pathname]);

	const renderMenuItem = (
		id: string,
		path: string,
		label: string,
		extraClass = ""
	) => (
		<li className={`${s.menuNavElement} ${extraClass}`}>
			<Link to={`/${currentLanguage}${path}`}>
				<span className={s.menuNavElementSvg}>
					<FooterSvgSelector id={id} />
				</span>
				<span className={s.menuNavElementName}>{t(label)}</span>
			</Link>
		</li>
	);

	if (isCreateAdPage) {
		return null;
	}
	return (
		<>
			{phoneEnterPopup && (
				<EnterPhonePopup
					closePopup={handleClosePopup}
					title={t("Додайте та підтвердіть номер телефону")}
				/>
			)}
			<div className={`${s.mobileFooterContainer} mui-fixed`}>
				<ul className={s.menuNav}>
					{renderMenuItem(
						"home",
						"",
						"Головна",
						currentPath === "/" || currentPath === "/ua"
							? s.menuNavElementActive
							: ""
					)}
					{renderMenuItem(
						"heart",
						userView?.id ? `personal_cabinet/favorite` : `registration_auth`,
						"Обране"
					)}
					<li className={`${s.menuNavElement}`} onClick={handleClickCreateAd}>
						<Link
							to={
							userView?.id ? userView?.is_approve_phone === 1 ? `/${currentLanguage}create_ad` : "" :
								`/${currentLanguage}registration_auth`}

						>
							<span className={s.menuNavElementSvg}>
								<FooterSvgSelector id={"create"} />
							</span>
							<span className={s.menuNavElementName}>{t("Створити")}</span>
						</Link>
						{/*{renderMenuItem(
							"create",
							userView?.id ? `create_ad` : `registration_auth`,
							"Створити"
						)}*/}
					</li>

					<li className={s.menuNavElement}>
						<div className={s.menuNavElementDisabled}>
							<span className={s.menuNavElementSvg}>
								{/* <Badge
								classes={{ badge: classes.badge }}
								overlap='rectangular'
								badgeContent={12}
							> */}
								<FooterSvgSelector id={"message"} />
								{/* </Badge> */}
							</span>
							<span className={s.menuNavElementName}>{t("Повідомлення")}</span>
						</div>
					</li>
					{userView?.id
						? renderMenuItem(
								"profile",
								userView?.id ? `personal_cabinet` : `registration_auth`,
								"Профіль"
							)
						: renderMenuItem("exitAcc", `registration_auth`, "Вхід")}
					{/*{renderMenuItem(
					"profile",
					userView?.id
						? "/personal_cabinet"
						: `${currentLanguage}/registration_auth`,
					"Профіль"
				)}*/}
				</ul>
			</div>
		</>
	);
};

export default MobileFooter;
