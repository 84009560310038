import React from "react";
import { useTranslation } from "react-i18next";
import style from "../CreateBoardADMobile.module.css";


interface Props {
	setFieldValue: any;
	values: any;
	handleAuth?: () => void;
}

const DontShowOtherAds: React.FC<Props> = ({
	setFieldValue,
	values,
	handleAuth
}) => {
	const { t } = useTranslation();

	const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (handleAuth) {
			handleAuth();
			return;
		}
		const newValue = e.currentTarget.checked ? 1 : 0;
		setFieldValue("not_show_other", newValue);

	};
	const checkboxId = `checkBox_${values.noticeId}`;
	return (
		<div className={style.otherAdsWrap}>
			<input
				id={checkboxId}
				className={style.createAdCheckbox}
				checked={values.not_show_other == "1"}
				type="checkbox"
				onChange={handleCheckboxChange}
				onFocus={handleAuth}
			/>
			<label className={style.otherAds} htmlFor={checkboxId}>
				{t("Не показувати на бізнес-сторінці")}
			</label>
		</div>
	);
};

export default DontShowOtherAds;
