import React from "react";

interface Props {
	id: string;
}

function ControlPanelSvgSelector({ id }: Props) {
	switch (id) {
		case "edit":
			return (
				<svg
					width="15"
					height="16"
					viewBox="0 0 15 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M12.3212 8.4961C12.0254 8.4961 11.7855 8.73595 11.7855 9.03182V14.3889C11.7855 14.6848 11.5457 14.9247 11.2498 14.9247H1.60712C1.31125 14.9247 1.0714 14.6848 1.0714 14.3889V3.67467C1.0714 3.37879 1.31125 3.13894 1.60712 3.13894H8.0356C8.33148 3.13894 8.57132 2.89909 8.57132 2.60321C8.57132 2.30734 8.33148 2.06752 8.0356 2.06752H1.60712C0.719532 2.06752 0 2.78706 0 3.67467V14.3889C0 15.2766 0.719532 15.9961 1.60712 15.9961H11.2498C12.1374 15.9961 12.857 15.2766 12.857 14.3889V9.03179C12.857 8.73595 12.6171 8.4961 12.3212 8.4961Z"
						fill="white"
					/>
					<path
						d="M14.3995 1.59684C14.0148 1.21212 13.4931 0.996039 12.9491 0.996102C12.4048 0.994533 11.8826 1.21099 11.499 1.59718L4.44262 8.65305C4.38408 8.71203 4.33992 8.78372 4.31352 8.86251L3.24212 12.0768C3.14861 12.3575 3.30037 12.6609 3.58109 12.7543C3.63555 12.7725 3.69258 12.7817 3.74996 12.7818C3.80746 12.7817 3.86462 12.7725 3.91924 12.7545L7.13348 11.6831C7.21242 11.6567 7.28415 11.6123 7.34294 11.5534L14.3993 4.49701C15.2002 3.6962 15.2003 2.39774 14.3995 1.59684ZM13.6418 3.74002L6.67759 10.7043L4.5969 11.3991L5.28956 9.32108L12.2564 2.35681C12.6394 1.97455 13.2598 1.97517 13.6421 2.35819C13.8247 2.54116 13.9276 2.78885 13.9284 3.04735C13.929 3.30722 13.8259 3.55658 13.6418 3.74002Z"
						fill="white"
					/>
				</svg>
			);

		case "deactivate":
			return (
				<svg
					width="14"
					height="15"
					viewBox="0 0 14 15"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M8.28261 7.99599L13.7339 13.4476C14.0887 13.8022 14.0887 14.3755 13.7339 14.7301C13.3793 15.0847 12.806 15.0847 12.4514 14.7301L6.99992 9.27854L1.5486 14.7301C1.19384 15.0847 0.620668 15.0847 0.266072 14.7301C-0.0886906 14.3755 -0.0886906 13.8022 0.266072 13.4476L5.71739 7.99599L0.266072 2.54439C-0.0886906 2.18979 -0.0886906 1.61644 0.266072 1.26184C0.442789 1.08495 0.675145 0.996093 0.907335 0.996093C1.13953 0.996093 1.37172 1.08495 1.5486 1.26184L6.99992 6.71344L12.4514 1.26184C12.6283 1.08495 12.8605 0.996093 13.0927 0.996093C13.3249 0.996093 13.557 1.08495 13.7339 1.26184C14.0887 1.61644 14.0887 2.18979 13.7339 2.54439L8.28261 7.99599Z"
						fill="white"
					/>
				</svg>
			);

		case "pitchUp":
			return (
				<svg
					width="17"
					height="15"
					viewBox="0 0 17 15"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M16.7531 4.79601C16.4949 4.63156 16.1432 4.6937 15.9672 4.93482L14.8138 6.5152C14.5141 5.21061 13.8201 4.01185 12.7873 3.04681C11.3721 1.72438 9.49058 0.996094 7.48916 0.996094C5.48782 0.996094 3.60622 1.72438 2.19106 3.04681C-0.730352 5.77677 -0.730352 10.2187 2.19106 12.9486C3.65176 14.3136 5.57046 14.9961 7.48916 14.9961C9.40786 14.9961 11.3266 14.3136 12.7873 12.9486C13.0081 12.7422 13.0081 12.4076 12.7873 12.2012C12.5664 11.9949 12.2083 11.9949 11.9875 12.2012C9.50709 14.5191 5.4713 14.5191 2.99093 12.2012C0.510553 9.88341 0.510553 6.11195 2.99093 3.79412C4.19247 2.67139 5.78996 2.05302 7.48923 2.05302C9.18851 2.05302 10.7861 2.67139 11.9875 3.79419C12.9149 4.66073 13.5194 5.74957 13.7474 6.93163L11.74 5.65219C11.482 5.48773 11.1301 5.54988 10.9541 5.791C10.7782 6.03212 10.8447 6.36089 11.1027 6.52535L14.0972 8.43394L14.0977 8.43429C14.145 8.46445 14.1956 8.48665 14.2475 8.5018C14.2491 8.50229 14.2506 8.50307 14.2522 8.50349C14.261 8.50602 14.27 8.50701 14.2789 8.50912C14.3241 8.51969 14.37 8.52618 14.4158 8.52618C14.5965 8.52618 14.7741 8.44543 14.8835 8.29548L16.9016 5.53043C17.0776 5.28924 17.0111 4.96047 16.7531 4.79601Z"
						fill="white"
					/>
				</svg>
			);

		case "toAdvertise":
			return (
				<svg
					width="32"
					height="15"
					viewBox="0 0 32 15"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M0 0.996094H32L29.7544 7.99609L32 14.9961H0V0.996094Z"
						fill="white"
					/>
					<path
						d="M6.50746 5.80291V5.17791H10.8711V5.80291H9.04155V10.9961H8.337V5.80291H6.50746ZM16.6254 8.087C16.6254 8.70064 16.5146 9.23094 16.293 9.67791C16.0714 10.1249 15.7674 10.4696 15.381 10.712C14.9947 10.9544 14.5534 11.0756 14.0572 11.0756C13.561 11.0756 13.1197 10.9544 12.7333 10.712C12.3469 10.4696 12.043 10.1249 11.8214 9.67791C11.5998 9.23094 11.489 8.70064 11.489 8.087C11.489 7.47337 11.5998 6.94306 11.8214 6.49609C12.043 6.04912 12.3469 5.70443 12.7333 5.462C13.1197 5.21958 13.561 5.09837 14.0572 5.09837C14.5534 5.09837 14.9947 5.21958 15.381 5.462C15.7674 5.70443 16.0714 6.04912 16.293 6.49609C16.5146 6.94306 16.6254 7.47337 16.6254 8.087ZM15.9435 8.087C15.9435 7.58321 15.8593 7.15803 15.6907 6.81143C15.524 6.46484 15.2977 6.20253 15.0117 6.0245C14.7276 5.84647 14.4094 5.75746 14.0572 5.75746C13.7049 5.75746 13.3858 5.84647 13.0998 6.0245C12.8157 6.20253 12.5894 6.46484 12.4208 6.81143C12.2541 7.15803 12.1708 7.58321 12.1708 8.087C12.1708 8.59079 12.2541 9.01598 12.4208 9.36257C12.5894 9.70916 12.8157 9.97147 13.0998 10.1495C13.3858 10.3275 13.7049 10.4165 14.0572 10.4165C14.4094 10.4165 14.7276 10.3275 15.0117 10.1495C15.2977 9.97147 15.524 9.70916 15.6907 9.36257C15.8593 9.01598 15.9435 8.59079 15.9435 8.087ZM22.1623 5.17791V10.9961H21.4577V5.80291H18.5259V10.9961H17.8214V5.17791H22.1623Z"
						fill="black"
					/>
				</svg>
			);

		case "delete":
			return (
				<svg
					width="17"
					height="16"
					viewBox="0 0 17 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M1 3.79609H15.7M6.88 7.29609V10.7961M9.82 7.29609V10.7961M2.47 3.79609H14.23L13.0687 13.7501C13.0289 14.0927 12.8578 14.4092 12.5882 14.6391C12.3186 14.869 11.9694 14.9961 11.6075 14.9961H5.09248C4.73059 14.9961 4.38141 14.869 4.1118 14.6391C3.8422 14.4092 3.67111 14.0927 3.6313 13.7501L2.47 3.79609ZM4.92857 1.79899C5.04746 1.55887 5.23559 1.35589 5.47101 1.21373C5.70643 1.07157 5.97944 0.996091 6.25819 0.996094H10.4418C10.7207 0.995958 10.9939 1.07138 11.2294 1.21354C11.465 1.35571 11.6532 1.55876 11.7722 1.79899L12.76 3.79609H3.94L4.92857 1.79899Z"
						stroke="white"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			);
		case "activate":
			return (
				<svg
					width="15"
					height="16"
					viewBox="0 0 15 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle cx="7.5" cy="8.49609" r="7.5" fill="white" />
					<path
						d="M11.6719 8.49479L5.42187 12.1032L5.42187 4.88635L11.6719 8.49479Z"
						fill="#353535"
					/>
				</svg>
			);

		default:
			return <svg></svg>;
	}
}

export default ControlPanelSvgSelector;
