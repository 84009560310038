import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useCabinetStyles = makeStyles(() =>
	createStyles({
		accElementDiv: {
			width: "100%",
			marginBottom: "20px",
		},
		accContainer: {
			boxShadow: "none !Important",
			border: "none !Important",
			"& .Mui-expanded": {
				display: "flex",
				alignItems: "center",
				minHeight: "58px",
				boxShadow: "none !Important",
			},
			"& .MuiAccordionSummary-expandIcon": {
				marginRight: "0",
			},
		},
		expandIcon: {
			marginRight: "10px",
			backgroundColor: "red",
		},
		accSummary: {
			fontFamily: "var(--myMainFontMedium)",
			fontSize: "18px",
			color: "#3D3D3D",
			"& .MuiTouchRipple-root": {
				display: "none", // Скрывает Ripple эффект
			},
			height: "58px",
		},
		accDetails: {
			minHeight: "58px",
		},
		accDetailsElement: {
			width: "100%",
			paddingTop: "20px",
			borderTop: "0.5px solid #E0E0E0",
			"& p": {
				maxWidth: "795px",
				/*width: "100%",*/
				fontFamily: "var(--myMainFontRegular)",
				fontSize: "16px",
				lineHeight: "20px",
				color: "#727272",
			},
			"& button.touched": {
				border: "none",
				background: "#FFDD66",
				color: "#3D3D3D",
				"&:hover": {
					background: "#FFEFB6",
				},
			},
		},
		"@media (max-width: 1025px)": {
			accElementDiv: {
				marginBottom: "10px",
			},
			accSummary: {
				fontFamily: "var(--myMainFontRegular)",
				fontSize: "14px",
				color: "#3D3D3D",
				height: "58px",
				margin: "0",
			},
			accDetailsElement: {
				paddingTop: "15px",
				"& p": {
					width: "100%",
					fontFamily: "var(--myMainFontRegular)",
					fontSize: "14px",
					lineHeight: "20px",
					color: "#727272",
				},
			},
		},
	})
);

export const useInterLinkStyles = makeStyles(() =>
	createStyles({
		accElementDiv: {
			width: "100%",
			marginBottom: "20px",
		},
		accContainer: {
			boxShadow: "none !Important",
			border: "none !Important",
			backgroundColor: "transparent !Important",
			"& .Mui-expanded": {
				display: "flex",
				alignItems: "center",
				minHeight: "58px",
				boxShadow: "none !Important",
			},
			"& .MuiAccordionSummary-expandIcon": {
				marginRight: "0",
				marginTop: "3px",
			},
		},
		expandIcon: {
			marginRight: "10px",
			backgroundColor: "red",
		},
		accSummary: {
			fontFamily: "var(--myMainFontRegular)",
			fontSize: "22px",
			color: "#3D3D3D",
			"& .MuiTouchRipple-root": {
				display: "none", // Скрывает Ripple эффект
			},
			height: "58px",
		},
		accDetails: {
			minHeight: "58px",
		},
		accDetailsElement: {
			width: "100%",
			paddingTop: "20px",
			borderTop: "1px solid #626262",
			"& p": {
				maxWidth: "795px",
				/*width: "100%",*/
				fontFamily: "var(--myMainFontRegular)",
				fontSize: "16px",
				lineHeight: "20px",
				color: "#727272",
			},
			"& button.touched": {
				border: "none",
				background: "#FFDD66",
				color: "#3D3D3D",
				"&:hover": {
					background: "#FFEFB6",
				},
			},
		},
		"@media (max-width: 1025px)": {
			accElementDiv: {
				marginBottom: "10px",
			},
			accSummary: {
				fontFamily: "var(--myMainFontRegular)",
				fontSize: "14px",
				color: "#3D3D3D",
				height: "58px",
				margin: "0",
			},
			accDetailsElement: {
				paddingTop: "15px",
				"& p": {
					width: "100%",
					fontFamily: "var(--myMainFontRegular)",
					fontSize: "14px",
					lineHeight: "20px",
					color: "#727272",
				},
			},
		},
	})
);