import React, { useCallback, useEffect, useMemo, useState } from "react";
import s from "./MainSearchLocation.module.css";
import MainSearchSvgSelector from "../MainSearchSvgSelector";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "../../../redux/redux-store";
import {
	getClearHistoryPlaces,
	getHistoryPlaces,
} from "../../../redux/search_Reducer";
import {
	getSettlementList,
	setSearchingLocationInfo,
	setSettlementList,
} from "../../../redux/settlement_Reducer";
import { SearchingLocations, SettlementView } from "../../../types/settlementTypes";
import { useClickOutside } from "../../../CustomHooks/useClickOutside";
import i18n from "i18next";

type Props = {
	setOpen: (b: boolean) => void;
	openMenu: boolean;
	setOpenMenu: (b: boolean) => void;
};
const MainSearchLocation: React.FC<Props> = ({
	setOpen,
	openMenu,
	setOpenMenu,
}) => {
	const dispatch: any = useDispatch();
	const { t } = useTranslation();

	const [placeHolder, setPlaceHolder] = useState(t("Вся Україна"));

	const getPlaces = useSelector(() => getHistoryPlaces);
	const clearPlaces = useSelector(() => getClearHistoryPlaces);
	const getSearchLocation = useSelector(() => getSettlementList);

	const searchingLocationInfo = useSelector(
		(state: StateType) => state.settlementData.searchingLocationInfo
	);
	const isSettlementView = (item: SettlementView | SearchingLocations): item is SettlementView => {
		return 'description' in item;
	};
	const valueNameSet = (item: SettlementView | SearchingLocations | null): string => {
		if (!item) {
			return "";
		}
		if (isSettlementView(item)) {
			const description = i18n.language === "ru" ? item.description_ru : item.description;
			const regionDescription = i18n.language === "ru" ? item.regions_description_ru : item.regions_description;
			return `${description}${regionDescription !=="" ? `, ${regionDescription}` : ""}`;
		}

		if (item.district) {
			return item.district === "" ? item.city || "" : `${item.city}, ${item.district}`;
		}

		return "";
	};
	const [value, setValue] = useState("");

	const places = useSelector(
		(state: StateType) => state.searchData.userPlacesHistory
	);
	const searchLocations = useSelector(
		(state: StateType) => state.settlementData.settlementsForSearch
	);

	const handleClosePopups = () => {
		setOpenMenu(false);
	};

	const outside = useClickOutside<HTMLDivElement>(handleClosePopups);

	const handleClickIcons = useCallback(() => {
		setOpen(!open);
		setOpenMenu(!openMenu);
		setPlaceHolder(t("Введіть назву міста"));
		dispatch(getPlaces());
	}, [openMenu, setOpen, t, dispatch, getPlaces]);

	const handleChange = (value: string) => {
		setValue(value);
		if (value.length >= 3) {
			dispatch(getSearchLocation(value, 1));
		}
	};
	const handleClick = () => {
		setOpen(false);
		setOpenMenu(true);
		setPlaceHolder(t("Введіть назву міста"));
		dispatch(getPlaces());
	};
	const handleClearButton = (event: React.MouseEvent) => {
		event.preventDefault();
		dispatch(clearPlaces());
	};
	const handleClickItem = (item: SearchingLocations) => {
		setValue(
			valueNameSet(item)
		);
		//setSearchLocation(item.id)
		setOpenMenu(false);
		dispatch(setSearchingLocationInfo(item));
		dispatch(setSettlementList(item));
	};
	useEffect(() => {
		if (searchingLocationInfo) {
			setValue(
				valueNameSet(searchingLocationInfo)
			);
		}
	}, [searchingLocationInfo,dispatch,i18n.language]);

	useMemo(() => {
		if (placeHolder === "Введіть назву міста"){
			setPlaceHolder(t(placeHolder));
		} else if (placeHolder === "Введите название города"){
			setPlaceHolder(t("Введіть назву міста"));
		} else {
			setPlaceHolder(t("Вся Україна"))
		}

	}, [t,placeHolder]);

	return (
		<div className={s.mainSearchLocation} ref={outside}>
			<div className={s.mainSearchLocationTop}>
				<div className={s.mainSearchLocationLeft}>
					<button type={"button"} onClick={handleClickIcons}>
						<MainSearchSvgSelector id={"location"} />
					</button>
					<input
						className={s.mainSearchLocationInput}
						placeholder={placeHolder}
						value={value}
						onChange={({ currentTarget }) => handleChange(currentTarget.value)}
						onClick={handleClick}
					/>
				</div>
				<div className={s.mainSearchLocationArrow} onClick={handleClickIcons}>
					<span
						className={
							(openMenu && value.length >= 3) || (openMenu && places.length > 0)
								? s.rotate
								: ""
						}
					>
						<MainSearchSvgSelector id={"arrow"} />
					</span>
				</div>
			</div>
			{openMenu && (
				<div className={s.mainSearchLocationMenu}>
					{places?.length > 0 && searchLocations?.length === 0 && (
						<div className={s.locationSelectContainer}>
							<div className={s.locationHistoryContainer}>
								<div className={s.locationHistoryTop}>
									<div className={s.recentlyLoc}>{t("Останні перегляди")}</div>
									<div className={s.recentlyClear}>
										<button
											onClick={(event) => handleClearButton(event)}
											className={s.clearButton}
										>
											{t("Очистити")}
										</button>
									</div>
								</div>
								<div className={s.locationHistoryList}>
									<ul className={s.locationHistoryMenu}>
										<li
											className={s.locationHistoryMenuElement}
											onClick={() =>
												handleClickItem({
													id: "",
													city: t("Вся Україна"),
													district: "",
													slug: "ukraine",
												})
											}
										>
											<div className={s.menuElIcon}>
												<MainSearchSvgSelector id={"locationTransparent"} />
											</div>
											<div className={s.menuElLocationName}>
												{t("Вся Україна")}
											</div>
										</li>
										{places.slice(0, 4).map((place: SearchingLocations) => (
											<li
												className={s.locationHistoryMenuElement}
												key={place.id}
												onClick={() => handleClickItem(place)}
											>
												<div className={s.menuElIcon}>
													<MainSearchSvgSelector id={"locationTransparent"} />
												</div>
												<div className={s.menuElLocationName}>{place.city}</div>
											</li>
										))}
									</ul>
								</div>
							</div>
						</div>
					)}
					{/* Searching now block*/}
					{searchLocations.length > 0 && value.length >= 3 && (
						<div className={s.locationSelectContainer}>
							<div className={s.locationSearchingContainer}>
								<ul className={s.searchingVariantsList}>
									<li
										className={s.searchingVariantsEl}
										onClick={() =>
											handleClickItem({
												id: "",
												city: t("Вся Україна"),
												district: "",
												slug: "ukraine",
											})
										}
									>
										<div className={s.cityCityArea}>{t("Вся Україна")}</div>
										<div className={s.district}></div>
									</li>
									{searchLocations.map((item: SearchingLocations) => (
										<li
											className={s.searchingVariantsEl}
											key={item.id}
											onClick={() => handleClickItem(item)}
										>
											<div className={s.cityCityArea}>{item.city}</div>
											<div className={s.district}>{item.district}</div>
										</li>
									))}
								</ul>
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default MainSearchLocation;
