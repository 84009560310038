import { useLayoutEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { StateType } from "../../redux/redux-store";
import s from "./SEO.module.css";
import Constants from "../../constants";

type Props = {
	searchValue: string;
};

const SearchSEO = ({ searchValue }: Props) => {
	const { i18n, t } = useTranslation();
	const [isExpanded, setExpanded] = useState(false);
	const [showButton, setShowButton] = useState(false);
	const contentRef = useRef<HTMLDivElement>(null);
	const searchingLocation = useSelector(
		(state: StateType) => state.settlementData.searchingLocationInfo
	);
	const maxCollapsedHeight = 140;
	const lang = i18n.language as keyof typeof Constants.linkToCreate;

	useLayoutEffect(() => {
		const contentEl = contentRef.current;
		if (contentEl) {
			const contentHeight = contentEl.scrollHeight;
			setShowButton(contentHeight > maxCollapsedHeight);
		}
	}, [searchValue]);

	return (
		<div className={`${s.homeSeoContainer} __container`}>
			<div className={s.contentWrapper}>
				<div
					ref={contentRef}
					className={`${s.seoContent} ${!isExpanded ? s.collapsed : ""}`}
					style={{
						maxHeight: isExpanded ? "none" : `${maxCollapsedHeight}px`,
					}}
				>
					<div className={`${s.seoContent} ${!isExpanded ? s.collapsed : ""}`}>
						<h2>
							<span>{t("Купити ")}</span>
							<span className={s.searchValueText}>{searchValue}</span>
							{` ${lang === "uk" ? searchingLocation?.description || "" : searchingLocation?.description_ru || ""} ${t("або замовити на BAGO.UA")}`}
						</h2>
						<div>
							<p>
								<span>{t("На безкоштовній дошці оголошень України ")}</span>
								<a href={Constants.mainLink} target="_blank">
									{"BAGO.ua"}
								</a>
								<span>{t(" ви завжди зможете знайти ")}</span>
								<span className={s.searchValueText}>{searchValue}</span>
								<span>
									{` ${lang === "uk" ? searchingLocation?.description || "" : searchingLocation?.description_ru || ""} ${t("за доступною ціною. Також ви можете безкоштовно розмістити необмежену кількість оголошень в будь-який розділ сайту і швидко продати свої товари.")}`}
								</span>
							</p>
							<p>
								<span>{t("Для того, щоб продати ")}</span>
								<span className={s.searchValueText}>{searchValue}</span>
								<span>
									{t(
										", треба розмістити оголошення. Подати оголошення дуже просто, для цього перейдіть на "
									)}
								</span>
								<span className={s.underlineText}>
									<a href={Constants.linkToCreate[lang]} target="_blank">
										{t("Форму подачі оголошень")}
									</a>
								</span>
								<span>
									{t(
										" і заповніть всі необхідні поля для заповнення, після чого натисніть кнопку «Публікувати». Подача оголошення займає всього 2-3 хвилини!"
									)}
								</span>
							</p>
						</div>
					</div>
				</div>
				{!isExpanded && <div className={s.fadeOverlay} />}
			</div>
			{showButton && (
				<div
					onClick={() => setExpanded(!isExpanded)}
					className={s.seoBottomPanel}
				>
					{isExpanded ? `${t("Згорнути")} ᨈ` : `${t("Читати далі")} ᨆ`}
				</div>
			)}
		</div>
	);
};

export default SearchSEO;
