import { Form, Formik, FormikHelpers } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Navigate, useParams } from "react-router-dom";
import FilterComponent from "../../Components/FilterComponent/FilterComponent";
import { setDefaultAutoSelection } from "../../redux/autoSelection_Reducer";
import {
	categoryStateType,
	setClearFiltersList,
	setDependFiltersList,
	setCategoryView,
} from "../../redux/category_Reducer";
import {
	getEditOneNotice,
	getNewNotice,
	getNoticeByIdForEdit,
	setClearTMP,
	setImagesOriginal,
	setTmpNotice,
} from "../../redux/notice_Reducer";
import { StateType } from "../../redux/redux-store";
import { Desktop, Mobile } from "../../responsive";
import { scrollToTop } from "../../common/Logic/supporting";
import {
	type NoticeFilterType,
	NoticeForPerView,
} from "../../types/noticeTypes";
import { valuesCreateAD } from "../../types/userTypes";
import AutoContinueCreate from "./Components/AutoContinueCreate";
import BigRubricsChanger from "./Components/BigRubricsChanger";
import BlockState from "./Components/BlockState";
import ChangeSettlement from "./Components/ChangeSettlement";
import ContactsSellerBlockCreate from "./Components/ContactsSellerBlockCreate";
import CreateBoardADSvg from "./Components/CreateBoardADSvg";
import DescriptionField from "./Components/DescriptionField";
import DontShowOtherAds from "./Components/DontShowOtherAds";
import NameField from "./Components/NameField";
import PriceField from "./Components/PriceField";
import SalaryField from "./Components/SalaryField";
import s from "./CreateBoardAD.module.css";
import style from "./CreateBoardADMobile.module.css";
import ChangeWorkType from "./Components/ChangeWorkType";
import ChangeLayoutZone from "../../Components/DropZone/ChangeLayoutZone";
import { setNavigateAfterSuccess, setOpenRegAuthPopup } from "../../redux/user_Reducer";
import { stateUserType } from "../../types/reduxStoreTypes";

const CreateBoardAd = () => {
	const dispatch: any = useDispatch();
	const navigate = useNavigate();
	const { i18n, t } = useTranslation();
	const auth = useSelector((state: stateUserType) => state.userData.isAuth);
	const [isShowAutomaticSelection, setIsShowAutomaticSelection] =
		useState(false);
	const [
		isShowDefaultWindowDropZoneMobile,
	] = useState(false);
	const [errorRef, setErrorRef] = useState(null);
	const [notShowOtherError, setNotShowOtherError] = useState("");
	const [updatedFilters, setUpdatedFilters] = useState<any[]>([]);
	const { categoryName, noticeId } = useParams();
	const languagePath = i18n.language === "ru" ? "" : "ua/";
	const [workTypeFilter, setWorkTypeFilter] = useState<
		NoticeFilterType | undefined
	>(
		updatedFilters.find(
			(filter: NoticeFilterType) =>
				filter.specialViewOnFront === "work_search_propose"
		) // шукаємо окремий фільтр "Тип роботи"
	);
	const [filtersWithoutWorkType, setFiltersWithoutWorkType] = useState<
		NoticeFilterType[]
	>(
		updatedFilters.filter(
			(filter: NoticeFilterType) =>
				filter.specialViewOnFront !== "work_search_propose"
		) // не показувати фільтр "Тип роботи" в списку фільтрів
	);

	/*Getters*/
	const createNotice = useSelector(() => getNewNotice);
	const getNoticeView = useSelector(() => getNoticeByIdForEdit);
	const editNotShowOther = useSelector(() => getEditOneNotice);
	
	const handleAuth = !auth ? () => {
		dispatch(setNavigateAfterSuccess(false))
		dispatch(setOpenRegAuthPopup(true))
	} : undefined;

	/*variables*/
	const createSuccessfully = useSelector(
		(state: StateType) => state.noticeData.createdSuccess
	);
	const autoSelectionOne = useSelector(
		(state: StateType) => state.autoSelection.autoSelectionOne
	);
	const autoSelectionChoosing = useSelector(
		(state: StateType) => state.autoSelection.autoSelectionOneChoosing
	);
	const isCloseAutoSelectionStore = useSelector(
		(state: StateType) => state.autoSelection.isCloseAutoSelection
	);
	const filters = useSelector(
		(state: categoryStateType) => state.categoryData.filters
	);
	const dependFilters = useSelector(
		(state: categoryStateType) => state.categoryData.dependFilters
	);
	const filtersVariable: Record<string, string | null> = useSelector(
		(state: categoryStateType) => state.categoryData.filtersVariables
	);
	const userView = useSelector((state: StateType) => state.userData.user);
	const tmpNotice = useSelector(
		(state: StateType) => state.noticeData.tmpNoticeForPerView
	);
	const noticeView = useSelector(
		(state: StateType) => state.noticeData.noticeView
	);
	const initialCreateNotice = useSelector(
		(state: StateType) => state.noticeData.initialCreateNotice
	);
	const blockState = useSelector(
		(state: StateType) => state.categoryData.blockState
	);
	const blockAutoContinue = useSelector(
		(state: StateType) => state.categoryData.blockAutoContinue
	);
	const blockAddFilters = useSelector(
		(state: StateType) => state.categoryData.blockAddFilters
	);
	const currentCategory = useSelector(
		(state: StateType) => state.categoryData.view
	);

	// Work category type = 1
	const [isWorkCategory, setIsWorkCategory] = useState<boolean>(
		noticeView?.noticeType?.value === 1 || currentCategory?.type === 1
	);

	/*Scroll error refs*/
	const noticePhotoRef = useRef<HTMLDivElement>(null);
	const noticeDescriptionRef = useRef<HTMLDivElement>(null);
	const noticeStateRef = useRef<HTMLDivElement>(null);
	const noticePriceRef = useRef<HTMLDivElement>(null);
	const noticeSettlementRef = useRef<HTMLDivElement>(null);
	const noticeFiltersRef = useRef<HTMLDivElement>(null);
	const submitButton = document.activeElement?.getAttribute("name");

	type Item = {
		name: string;
		value: string;
		id: string;
		filter: string;
	};
	const filtersReduce = (filters: Item[]) => {
		const properties: any[] = [];
		filters.forEach((item: Item) => {
			if (item.name) {
				const existingIndex = properties.findIndex(
					(entry: any) => entry.propertyName === item.name
				);
				if (existingIndex !== -1) {
					// If propertyName exists, update propertyValues
					properties[existingIndex] = {
						...properties[existingIndex],
						propertyValues: `${
							properties[existingIndex].propertyValues
						},${String(item.value === "" ? item.id : item.value)}`,
					};
				} else {
					// If propertyName does not exist, add a new entry
					properties.push({
						filterPosition: item.filter,
						propertyName: item.name,
						propertyValues: String(item.value === "" ? item.id : item.value),
					});
				}
			}
		});
		return properties;
	};

	const handlePerView = (values: valuesCreateAD) => {
		const infoData: NoticeForPerView = {
			title: values.name,
			price: values.price,
			sellerName: userView?.name || "",
			description: values.description,
			state: values.state,
			settlementId: values?.settlement_value || "",
			settlementName: values?.settlement_label || "",
			categoryId: values?.category_id_value,
			categoryName: values?.category_id_label,
			price_type: values?.price_type || "",
			ownerData: { online: false, time_last_visit: null, photoUrl: null },
			notice_properties: filtersReduce(values?.filters) || [],
			filters: values.filters || [],
		};
		dispatch(setTmpNotice(infoData));
	};

	useEffect(() => {
		const uniqueFiltersMap = new Map();
		filters.forEach((filter: any) => {
			// Добавляем основной фильтр
			uniqueFiltersMap.set(filter.id, filter);

			// Добавляем его зависимые фильтры, если они ещё не добавлены
			dependFilters &&
				dependFilters
					.filter((dependFilter: any) => dependFilter.parent_id === filter.id)
					.forEach((dependFilter: any) => {
						if (!uniqueFiltersMap.has(dependFilter.id)) {
							uniqueFiltersMap.set(dependFilter.id, dependFilter);
						}
					});
		});
		const sortedFilters = Array.from(uniqueFiltersMap.values());
		const updatedFilters = sortedFilters.map((filter: any) => {
			const filterId = `filter_${filter.id}`;
			if (filtersVariable.hasOwnProperty(filterId)) {
				filter.value = filtersVariable[filterId];
			}
			return filter;
		});
		setUpdatedFilters(updatedFilters);
		setWorkTypeFilter(
			updatedFilters.find(
				(filter: NoticeFilterType) =>
					filter.specialViewOnFront === "work_search_propose"
			)
		); // окремий фільтр "Тип роботи"
		setFiltersWithoutWorkType(
			updatedFilters.filter(
				(filter: NoticeFilterType) =>
					filter.specialViewOnFront !== "work_search_propose"
			)
		); // не показувати фільтр "Тип роботи" в списку фільтрів
	}, [filters, dependFilters, i18n.language,currentCategory]);

	useEffect(() => {
		if (noticeId) {
			dispatch(getNoticeView(+noticeId));
		}
	}, [noticeId, i18n.language]);

	useEffect(() => {

		return () => {
			dispatch(setDefaultAutoSelection());
			dispatch(setClearFiltersList());
			dispatch(setDependFiltersList([]));
			dispatch(setCategoryView({}));
			dispatch(setImagesOriginal(null));
		};
	}, [noticeId]);

	useEffect(() => {
		if (errorRef)
			if (Object.keys(errorRef).length > 0) {
				const keys = Object.keys(errorRef);
				if (keys.length > 0) {
					const firstKey = keys[0];
					switch (true) {
						case ["name", "category_id", "description", "image"].includes(
							firstKey
						):
							scrollToTop();
							break;
						case ["state", "price"].includes(firstKey):
							if (noticeDescriptionRef && noticeDescriptionRef.current) {
								noticeDescriptionRef.current.scrollIntoView({
									behavior: "smooth",
									block: "start",
								});
							}
							break;
						case firstKey.startsWith("filter_"):
							if (noticeFiltersRef && noticeFiltersRef.current) {
								noticeFiltersRef.current.scrollIntoView({
									behavior: "smooth",
									block: "start",
								});
							}
							break;
						case firstKey === "settlement_id":
							if (noticeSettlementRef && noticeSettlementRef.current) {
								noticeSettlementRef.current.scrollIntoView({
									behavior: "smooth",
									block: "start",
								});
							}
							break;
					}
				}
			}
	}, [errorRef]);

	const [initialValues, setInitialValues] = useState<valuesCreateAD>(initialCreateNotice);
	const [initialValuesCreate, setInitialValuesCreate] = useState<valuesCreateAD>(initialCreateNotice);
	useEffect(() => {
		setInitialValues({
			name: tmpNotice?.title || noticeView?.name || "",
			description: tmpNotice?.description || noticeView?.description || "",
			category_id_value: currentCategory?.id || noticeView?.category_id || null,
			category_id_label: currentCategory?.name || noticeView?.category_name || "",
			price: tmpNotice?.price || noticeView?.price || "",
			maxSalary: tmpNotice?.maxSalary || noticeView?.maxSalary || "",
			price_type: noticeView?.price_type || "",
			quantityStock: "",
			state: noticeView?.state || "",
			settlement_value: noticeView?.settlement_id?.toString() || "",
			settlement_label: noticeView?.settlement_name || "",
			contactName: userView?.name || "",
			contactPhone: userView?.phone || "",
			not_show_other: +noticeView.not_show_other || 0,
			auto_continue: noticeView.auto_continue || 0,
			auto_lift_days: noticeView?.auto_lift_days?.toString() || null,
			top_days: noticeView.top_days?.toString() || "",
			noticeImagesString: [],
			whatsapp: userView?.whatsapp || null,
			viber: userView?.viber || null,
			telegram: userView?.telegram || null,
			filters: tmpNotice.filters || [],
			notice_properties: {},
			publish: null,
		});
		setInitialValuesCreate({
			name: tmpNotice?.title || "",
			description: tmpNotice?.description || "",
			category_id_value: currentCategory?.id || tmpNotice?.categoryId || null,
			category_id_label: currentCategory?.name || tmpNotice?.categoryName || "",
			price: tmpNotice?.price || "",
			price_type: tmpNotice?.price_type || "",
			quantityStock: "",
			state: tmpNotice?.state || "",
			settlement_value: tmpNotice?.settlementId || "",
			settlement_label: tmpNotice?.settlementName || "",
			contactName: userView?.name || "",
			contactPhone: userView?.phone || "",
			not_show_other: +noticeView.not_show_other || 0,
			auto_continue: noticeView.auto_continue || 0,
			auto_lift_days: noticeView?.auto_lift_days?.toString() || null,
			top_days: noticeView.top_days?.toString() || "",
			noticeImagesString: [],
			whatsapp: userView?.whatsapp || null,
			viber: userView?.viber || null,
			telegram: userView?.telegram || null,
			filters: tmpNotice.filters || [],
			notice_properties: tmpNotice.notice_properties,
			publish: null,
			maxSalary: tmpNotice?.maxSalary || "",
		});

		return () => {
			setInitialValues(initialCreateNotice);
			setInitialValuesCreate(initialCreateNotice);
		}
	}, [tmpNotice, noticeView, currentCategory, userView]);

	const changeNoticeDontShowOtherAds = (fieldName: string, value: number) => {
		dispatch(
			editNotShowOther(
				noticeView.id ?? 0,
				fieldName,
				String(value),
				setNotShowOtherError
			)
		);
	};

	const goBack = () => {
		navigate(-1);
	};

	useEffect(() => {
		setIsWorkCategory(
			noticeView?.noticeType?.value === 1 || currentCategory?.type === 1
		);
	}, [noticeView, currentCategory, noticeId]);

	const submitCreateNotice = (values: any, submitProps: FormikHelpers<any>) => {
		if (createSuccessfully && submitButton !== "preview") {
			submitProps?.resetForm();
			dispatch(setClearFiltersList());
			dispatch(setClearTMP());
		}
		let type = "";
		if (!noticeId && submitButton !== "preview") {
			type = `create`;
		} else if (noticeId && submitButton !== "preview") {
			type = `update?id=${noticeId}`;
		} else if (noticeId && submitButton === "preview") {
			type = `update?onlyValidate=1&id=${noticeId}`;
		} else if (!noticeId && submitButton === "preview") {
			type = "create?onlyValidate=1";
		}
		dispatch(createNotice(values, submitProps.setStatus, type, navigate));
	};

	if (createSuccessfully) {
		return <Navigate to={`/${languagePath}personal_cabinet/ads/active`} />;
	} else {
		return (
			<>
				<Desktop>
					<div className={"wrapper"}>
						<div className={s.wrapper}>
							<div
								className={`${s.createADContainer}`}
								style={{ overflow: "hidden" }}
							>
								<h1>
									{!noticeId
										? t(`Створити оголошення`)
										: t(`Редагувати оголошення`)}
								</h1>
								<div className={s.formContainer}>
									<Formik
										enableReinitialize={!!noticeId}
										initialValues={
											noticeId ? initialValues : initialValuesCreate
										}
										onSubmit={(values, submitProps) => {
											handleAuth?.() ?? submitCreateNotice(values, submitProps);
										}}
									>
										{({
											values,
											status: formikStatus,
											handleSubmit,
											setFieldValue,
											setStatus,
											touched,
										}) => {
											useEffect(() => {
												setStatus(formikStatus);
												setErrorRef(formikStatus);
											}, [formikStatus]);
											useEffect(() => {
												if (
													!categoryName &&
													autoSelectionOne?.categoryId &&
													(values.category_id_value !==
														autoSelectionOne?.categoryId ||
														values.category_id_label !==
															autoSelectionOne?.categoryName)
												) {
													navigate(
														`/create_ad/${autoSelectionOne?.categoryAlias}`,
														{ replace: true }
													);
												}
											}, [autoSelectionOne]);
											useEffect(() => {
												setFieldValue("contactName", userView?.name || "");
												setFieldValue("contactPhone", userView?.phone || "");
												setFieldValue("whatsapp", userView?.whatsapp || null);
												setFieldValue("viber", userView?.viber || null);
												setFieldValue("telegram", userView?.telegram || null);
											}, [userView]);
											/*	useAutoCategorySelection(
													values,
													autoSelectionOne,
													setFieldValue
												);*/

											// no best! В этом месте сетается подобранная автоматом рубрика
											return (
												<Form className={s.form} onSubmit={(e) => {
													e.preventDefault();
													if (handleAuth) {
														handleAuth();
													} else {
														handleSubmit();
													}
												}}>
													<NameField
														formikStatus={formikStatus}
														setStatus={setStatus}
														values={values}
														touched={touched}
													/>
													<BigRubricsChanger
														values={values}
														setStatus={setStatus}
														status={formikStatus}
														setFieldValue={setFieldValue}
														autoSelectionChoosing={autoSelectionChoosing}
														autoSelectionOne={autoSelectionOne}
														isCloseAutoSelectionStore={
															isCloseAutoSelectionStore
														}
														isShowAutomaticSelection={isShowAutomaticSelection}
														setIsShowAutomaticSelection={
															setIsShowAutomaticSelection
														}
														handleAuth={handleAuth}
													/>
													<div className={`${s.formElement} ${s.photo}`}>
														<div className={s.nameSide} ref={noticePhotoRef}>
															{isWorkCategory ? (
																<span className={s.elementName}>
																	{t(`Логотип компанії`)}
																</span>
															) : (
																<>
																	<span className={s.elementName}>
																		{t(`Фото`)}
																	</span>
																	<span className={s.required}> * </span>
																</>
															)}
														</div>
														<ChangeLayoutZone
															noticeId={noticeId}
															setFieldValue={setFieldValue}
															isWorkCategory={isWorkCategory}
															handleAuth={handleAuth}
														/>
														{/*<ChangePhotoDropZone
															setFieldValue={setFieldValue}
															singleImage={isWorkCategory}
														/>*/}
														{/* {status && status.maxPhotosNumber &&
                                            <span className={s.error}>{status.maxPhotosNumber}</span>}*/}
														{isWorkCategory && (
															<span className={s.dropZoneOptions_info}>
																{t(
																	"Рекомендований розмір логотипу – не більше 300x150 пікселів, максимальний розмір файлу – 1 МБ"
																)}
															</span>
														)}
														{formikStatus && formikStatus.image && (
															<span className={s.error}>
																{formikStatus.image}
															</span>
														)}
													</div>
													{workTypeFilter && (
														<ChangeWorkType
															filter={workTypeFilter}
															values={values}
															setFieldValue={setFieldValue}
															handleAuth={handleAuth}
														/>
													)}
													<DescriptionField
														setFieldValue={setFieldValue}
														values={values}
														status={formikStatus}
														noticeDescriptionRef={noticeDescriptionRef}
														setStatus={setStatus}
														workTypeFilter={workTypeFilter}
														handleAuth={handleAuth}
													/>
													{blockState && !isWorkCategory && (
														<BlockState
															status={formikStatus}
															setFieldValue={setFieldValue}
															values={values}
															setStatus={setStatus}
															ref={noticeStateRef}
															handleAuth={handleAuth}
														/>
													)}
													<div className={`${s.formElement} ${s.priceField}`}>
														{isWorkCategory ? (
															<SalaryField
																ref={noticePriceRef}
																status={formikStatus}
																setFieldValue={setFieldValue}
																values={values}
																setStatus={setStatus}
																handleAuth={handleAuth}
															/>
														) : (
															<PriceField
																ref={noticePriceRef}
																status={formikStatus}
																setFieldValue={setFieldValue}
																values={values}
																setStatus={setStatus}
																handleAuth={handleAuth}
															/>
														)}
													</div>

													{filtersWithoutWorkType?.length !== 0 &&
														blockAddFilters && (
															<div
																className={`${s.formElement} ${s.filtersList}`}
																ref={noticeFiltersRef}
															>
																<div className={s.filterListName}>
																	<span className={s.elementName}>
																		<b>{t(`Додаткові характеристики`)}</b>
																	</span>
																</div>
																<div className={s.filtersList_List}>
																	{filtersWithoutWorkType.map((filter: any) => {
																		return (
																			<div key={filter.id}>
																				<FilterComponent
																					values={values}
																					filter={filter}
																					status={formikStatus}
																					setFieldValue={setFieldValue}
																					handleAuth={handleAuth}
																				/>
																			</div>
																		);
																	})}

																	{formikStatus && formikStatus.filters && (
																		<span className={s.error}>
																			{formikStatus.filters}
																		</span>
																	)}
																</div>
															</div>
														)}
													{blockAutoContinue && (
														<AutoContinueCreate
															values={values}
															setFieldValue={setFieldValue}
															handleAuth={handleAuth}
														/>
													)}
													<div className={s.formElement}>
														<ChangeSettlement
															ref={noticeSettlementRef}
															values={values}
															setStatus={setStatus}
															status={formikStatus}
															setFieldValue={setFieldValue}
															handleAuth={handleAuth}
														/>
													</div>
													<ContactsSellerBlockCreate
														values={values}
														setFieldValue={setFieldValue}
														handleAuth={handleAuth}
													/>
													<DontShowOtherAds
														setFieldValue={changeNoticeDontShowOtherAds}
														values={values}
														handleAuth={handleAuth}
													/>
													{notShowOtherError !== "" && (
														<div className={s.errorPrice}>
															{notShowOtherError}
														</div>
													)}
													{/*{blockDontShowOtherAds &&
                                            <DontShowOtherAds setFieldValue={setFieldValue} values={values}/>
                                        }*/}
													<div className={s.buttons}>
														<button
															type={"submit"}
															name={"preview"}
															onClick={() => handlePerView(values)}
														>
															{t(`Переглянути`)}
														</button>
														<button
															type={"submit"}
															name={"public"}
															onClick={() => setFieldValue("publish", 1)}
															className={
																values.name !== "" ||
																values.description !== "" ||
																values.category_id_value !== 0 ||
																values.price !== "0" ||
																+values.settlement_value !== 0
																	? s.touched
																	: ""
															}
														>
															{noticeId ? t("Зберегти") : t(`Публікувати`)}
														</button>
													</div>
												</Form>
											);
										}}
									</Formik>
								</div>
								<div className={s.descriptionFields}>
									<span className={s.required}>* </span>
									{t("поля обов'язкові для заповненя")}
								</div>
							</div>
						</div>
						{/*<Footer />*/}
					</div>
				</Desktop>

				<Mobile>
					<div className={style.wrapper}>
						<div className={`${style.createADContainer}`}>
							<div className={style.titleWrap} onClick={goBack}>
								<CreateBoardADSvg id="arrowBack" />
								<h1>
									{!noticeId
										? t(`Створити оголошення`)
										: t(`Редагувати оголошення`)}
								</h1>
							</div>
							<Formik
								enableReinitialize={!!noticeId}
								initialValues={noticeId ? initialValues : initialValuesCreate}
								onSubmit={(values, submitProps) => {
									submitCreateNotice(values, submitProps);
								}}
							>
								{({
									values,
									status: formikStatus,
									handleSubmit,
									setFieldValue,
									setStatus,
									touched,
								}) => {
									useEffect(() => {
										setErrorRef(formikStatus);
										setStatus(formikStatus);
									}, [formikStatus]);
									// no best! В этом месте сетается подобранная автоматом рубрика
									useEffect(() => {
										if (
											!categoryName &&
											autoSelectionOne?.categoryId &&
											(values.category_id_value !==
												autoSelectionOne?.categoryId ||
												values.category_id_label !==
													autoSelectionOne?.categoryName)
										) {
											navigate(
												`/create_ad/${autoSelectionOne?.categoryAlias}`,
												{ replace: true }
											);
										}
									}, [autoSelectionOne]);
									return (
										<Form className={`${s.form}`} onSubmit={(e) => {
											if (handleAuth) {
												e.preventDefault();
												handleAuth();
											} else {
												handleSubmit();
											}
										}}>
											<NameField
												formikStatus={formikStatus}
												setStatus={setStatus}
												values={values}
												touched={touched}
											/>
											<BigRubricsChanger
												values={values}
												setStatus={setStatus}
												status={formikStatus}
												setFieldValue={setFieldValue}
												autoSelectionChoosing={autoSelectionChoosing}
												autoSelectionOne={autoSelectionOne}
												isCloseAutoSelectionStore={isCloseAutoSelectionStore}
												isShowAutomaticSelection={isShowAutomaticSelection}
												setIsShowAutomaticSelection={
													setIsShowAutomaticSelection
												}
												handleAuth={handleAuth}
											/>
											<div
												className={`${style.formElement} ${s.photo}`}
												ref={noticePhotoRef}
											>
												{!isShowDefaultWindowDropZoneMobile && (
													<div className={s.nameSide}>
														{isWorkCategory ? (
															<span className={s.elementName}>
																{t(`Логотип компанії`)}
															</span>
														) : (
															<>
																<span className={s.elementName}>
																	{t(`Фото`)}
																</span>
																<span className={s.required}> * </span>
															</>
														)}
													</div>
												)}
												<ChangeLayoutZone
													setFieldValue={setFieldValue}
													noticeId={noticeId}
													isWorkCategory={isWorkCategory}
													handleAuth={handleAuth}
												/>
												{/*<ChangePhotoDropZoneMobile
													setFieldValue={setFieldValue}
													isShowDefaultWindow={
														isShowDefaultWindowDropZoneMobile
													}
													setIsShowDefaultWindow={
														setIsShowDefaultWindowDropZoneMobile
													}
													singleImage={isWorkCategory}
												/>
												{formikStatus && formikStatus.maxPhotosNumber && (
													<span className={s.error}>
														{formikStatus.maxPhotosNumber}
													</span>
												)}
												{formikStatus && formikStatus.image && (
													<span className={s.error}>{formikStatus.image}</span>
												)}*/}
											</div>
											{workTypeFilter && (
												<ChangeWorkType
													filter={workTypeFilter}
													values={values}
													setFieldValue={setFieldValue}
													handleAuth={handleAuth}
												/>
											)}
											<DescriptionField
												setFieldValue={setFieldValue}
												values={values}
												status={formikStatus}
												noticeDescriptionRef={noticeDescriptionRef}
												setStatus={setStatus}
												workTypeFilter={workTypeFilter}
												handleAuth={handleAuth}
											/>
											{blockState && !isWorkCategory && (
												<BlockState
													status={formikStatus}
													setFieldValue={setFieldValue}
													values={values}
													setStatus={setStatus}
													ref={noticeStateRef}
													handleAuth={handleAuth}
												/>
											)}
											<div className={`${style.formElement} ${s.priceField}`}>
												{isWorkCategory ? (
													<SalaryField
														ref={noticePriceRef}
														status={formikStatus}
														setFieldValue={setFieldValue}
														values={values}
														setStatus={setStatus}
														handleAuth={handleAuth}
													/>
												) : (
													<PriceField
														ref={noticePriceRef}
														status={formikStatus}
														setFieldValue={setFieldValue}
														values={values}
														setStatus={setStatus}
														handleAuth={handleAuth}
													/>
												)}
											</div>
											{filtersWithoutWorkType?.length !== 0 &&
												blockAddFilters && (
													<div className={`${s.formElement} ${s.filtersList}`}>
														<div className={s.filterListName}>
															<span
																className={s.elementName}
																ref={noticeFiltersRef}
															>
																<b>{t(`Додаткові характеристики`)}</b>
															</span>
														</div>
														<div className={s.filtersList_List}>
															{filtersWithoutWorkType.map((filter: any) => {
																return (
																	<div key={filter.id}>
																		<FilterComponent
																			values={values}
																			filter={filter}
																			status={formikStatus}
																			setFieldValue={setFieldValue}
																			handleAuth={handleAuth}
																		/>
																	</div>
																);
															})}
															{formikStatus && formikStatus.filters && (
																<span className={s.error}>
																	{formikStatus.filters}
																</span>
															)}
														</div>
													</div>
												)}
											<div className={style.formElement}>
												<ChangeSettlement
													ref={noticeSettlementRef}
													values={values}
													setStatus={setStatus}
													status={formikStatus}
													setFieldValue={setFieldValue}
													handleAuth={handleAuth}
												/>
											</div>
											<ContactsSellerBlockCreate
												values={values}
												setFieldValue={setFieldValue}
												handleAuth={handleAuth}
											/>
											{/* {blockDontShowOtherAds && ( */}
											<DontShowOtherAds
												setFieldValue={changeNoticeDontShowOtherAds}
												values={values}
												handleAuth={handleAuth}
											/>
											{notShowOtherError !== "" && (
												<div className={s.errorPrice}>{notShowOtherError}</div>
											)}
											{/* )} */}
											{blockAutoContinue && (
												<AutoContinueCreate
													values={values}
													setFieldValue={setFieldValue}
													handleAuth={handleAuth}
												/>
											)}

											<div className={style.createboardFooter}></div>

											<div className={`${style.bottomContainer} mui-fixed`}>
												<div className={style.buttonsBlock}>
													<button
														className={style.bottomButton}
														type={"submit"}
														id={"prew"}
														onClick={() => handlePerView(values)}
													>
														{t(`Переглянути`)}
													</button>
													<button
														type={"submit"}
														onClick={() => setFieldValue("publish", 1)}
														className={`${style.bottomButton} ${style.buttonActive}`}
													>
														{noticeId ? t("Зберегти") : t(`Публікувати`)}
													</button>
												</div>
											</div>
										</Form>
									);
								}}
							</Formik>
						</div>
					</div>
				</Mobile>
			</>
		);
	}
};

export default CreateBoardAd;
