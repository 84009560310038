import React from "react";
import PopularRubricLinks from "./Components/PopularRubricLinks";
import { useTranslation } from "react-i18next";
import style from "../../pages/SearchResults/SearchResult.module.css";
import { Desktop } from "../../responsive";
import SearchingNow from "./Components/SearchingNow";
import InterLinkLocation from "./Components/InterLinkLocation";
import AccordionElement from "./Components/AccordionElement";
import Neighbours from "./Components/Neighbours";


type Props = {
	popularRubrics?: boolean;
	page: "Home" | "MainCategory" | "SearchResults" | "OutputBordAD";
	inSearch: boolean;
	categoryTree?: { alias: string; name: string; name_ru: string }[];
	categoryId?:number | null;
	categoryName?:string
};

const InterlinkingMain: React.FC<Props> = ({ page, categoryTree,inSearch,categoryId,categoryName }) => {
	const { t } = useTranslation();

	return (
		<Desktop>
			<div className={`${style.interlinkContainer} __container`}>
				<div>
					<SearchingNow />
				</div>
				{page !== "Home" && (
					<>
						<AccordionElement title={t(`Популярні рубрики`)}>
							<PopularRubricLinks />
						</AccordionElement>
					</>
				)}
				<AccordionElement title={t(`Україна`)}>
					<InterLinkLocation page={page} categoryTree={categoryTree || null} />
				</AccordionElement>
				{page !== "Home"  &&  page !== "MainCategory" && !inSearch && (
					<AccordionElement title={categoryName ?? ""}>
						<Neighbours categoryId={categoryId ?? null}  />
					</AccordionElement>
				)}
			</div>
		</Desktop>
	);
};

export default InterlinkingMain;
