import React from "react";
import { useSelector } from "react-redux";
import { StateType } from "../../../redux/redux-store";
import { Link } from "react-router-dom";
import s from "../InterlinkingMain.module.css";
import i18n from "i18next";
import { scrollToTop } from "../../../common/Logic/supporting";

const PopularRubricLinks = () => {
	const categoriesList = useSelector(
		(state: StateType) => state.categoryData.categoryFirstLevel
	);
	const languagePath = i18n.language === "ru" ? "" : "ua/";
	const handleScroll = () => {
		scrollToTop();
	};

	return (
		<ul className={s.linksContainer}>
			{categoriesList.length > 0 &&
				categoriesList.map((category) => (
					<li className={s.linkItem} key={category.id}>
						<Link
							onClick={handleScroll}
							to={`/${languagePath}c/${category.alias}`}
						>
							{category.name}
						</Link>
					</li>
				))}
		</ul>
	);
};

export default PopularRubricLinks;
