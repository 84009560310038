import React, { useEffect, useState } from "react";
import s from "../../pages/SearchResults/SearchResult.module.css";
import AsyncSelect from "react-select/async";
import { components, SingleValue } from "react-select";
import ArrowsAndCross from "../../common/ArrowsAndCross/ArrowsAndCross";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "../../redux/redux-store";
import { mainSearch, settlementAPI } from "../../api/api";
import {
	getSettlementView,
	setSearchingLocationInfo,
} from "../../redux/settlement_Reducer";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";


type Props = {
	setSettlement: any;
	category: boolean;
};

interface OptionType {
	value: string;
	label: string;
	slug: string;
}

const SearchLocationSelect = ({ setSettlement, category }: Props) => {
	const [params, setParams] = useSearchParams();
	const { i18n, t } = useTranslation();
	const searchLocationQuery = params.get("search-location");

	const searchingLocationInfo = useSelector(
		(state: StateType) => state.settlementData.searchingLocationInfo
	);

	const defaultLocation = {
		label: t("Вся Україна"),
		value: "",
		slug: "ukraine",
	}

	const [value, setValue] = useState<any | null>(null);
	const dispatch: any = useDispatch();
	const isAuth = useSelector((state: StateType) => state.userData.user.id);
	const getView = useSelector(() => getSettlementView);

	useEffect(() => {
		if(i18n.language){
			const value = [defaultLocation];
			setValue(value);
		}

	}, [i18n.language]);


	const addSearchLocation = (slug: string) => {
		params.set("search-location", slug);
		setParams(params);
	};

	const removeQuery = () => {
		params.delete("search-location");
		setParams(params);
		setSearchingLocationInfo(null)
		setSettlement("")
	};

	const loadOptions = async (inputValue: string): Promise<OptionType[]> => {
		try {
			// Если пользователь не авторизован, пропускаем запрос к mainSearch.getSearchPlace
			let newOptions: OptionType[];
			if (isAuth) {
				const placesHistory: any = await mainSearch.getSearchPlace();
				if (inputValue.length > 1 && inputValue.length < 10) {
					const response: any =
						await settlementAPI.getSettlementsSearch(inputValue);
					newOptions = [
						...placesHistory.data.searchPlaceQueries,
						...response.data.settlements,
					];
				} else {
					newOptions = [...placesHistory.data.searchPlaceQueries];
				}
			} else {
				// Если не авторизован, делаем только запрос settlements
				if (inputValue.length > 1 && inputValue.length < 10) {
					const response: any =
						await settlementAPI.getSettlementsSearch(inputValue);
					newOptions = [...response.data.settlements];
				} else {
					newOptions = [];
				}
			}
			const options: OptionType[] = newOptions.map((item: any) => ({
				value: item.id,
				label: item.name ? item.name : item.text,
				slug: item.slug,
			}));
			return [defaultLocation, ...options];
		} catch (error: any) {
			console.error("Error loading options:", error);
			return [];
		}
	};
	const handleChange = (newValue: SingleValue<OptionType>) => {
		setSettlement(newValue?.value);
		if (newValue?.value === "") {
			dispatch(
				setSearchingLocationInfo(null)
			);
			removeQuery();
			setValue([defaultLocation]);
			!category && addSearchLocation(defaultLocation.slug);
			return;
		}

		setValue([
			{
				value: newValue?.value || "",
				label: newValue?.label || "",
				slug: newValue?.slug || "",
			},
		]);
		const [city = "", district = ""] = newValue?.label.split(",").map((part) => part.trim()) || [];
		dispatch(
			setSearchingLocationInfo({
				id: newValue?.value || "",
				city,
				district,
				slug: newValue?.slug || "",
			})
		);
		addSearchLocation(newValue?.slug ?? "");
	};

	useEffect(() => {
		if (searchLocationQuery && searchLocationQuery !== "" && searchLocationQuery !== "ukraine") {
			dispatch(getView(searchLocationQuery,removeQuery));
			return;
		}

		!category && addSearchLocation(defaultLocation.slug);
	}, [searchLocationQuery, dispatch,getView]);

	useEffect(() => {
		if (searchingLocationInfo) {
			setSettlement(searchingLocationInfo.id);
			const labelDescription =
				i18n.language === "ru"
					? searchingLocationInfo?.description_ru ?? ""
					: searchingLocationInfo?.description ?? "";
			const labelAreaDescription =
				i18n.language === "ru"
					? searchingLocationInfo?.area_description_ru ?? ""
					: searchingLocationInfo?.area_description ?? "";

			const label = `${labelDescription}${labelAreaDescription && ", "}${labelAreaDescription}`;
			setValue([
				{
					label,
					value: searchingLocationInfo.id.toString() ?? "",
					slug: searchingLocationInfo?.slug ?? "",
				},
			]);
		} else {
			setValue([defaultLocation]);
		}
	}, [isAuth, searchLocationQuery, searchingLocationInfo, i18n.language]);

	const SingleValue = ({ children, ...props }: any) => (
		<components.SingleValue {...props}>
			<div className={"selectText"} >{children}</div>
		</components.SingleValue>
	);

	const Control = ({ children, ...props }: any) => (
		<components.Control {...props}>
			<div>
				<svg
					width="18"
					height="23"
					viewBox="0 0 18 23"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M8.07506 22.536C1.26422 13.0737 0 12.1025 0 8.625C0 3.86153 4.02942 0 9 0C13.9706 0 18 3.86153 18 8.625C18 12.1025 16.7358 13.0737 9.92494 22.536C9.47798 23.1547 8.52197 23.1547 8.07506 22.536ZM9 12.2187C11.0711 12.2187 12.75 10.6098 12.75 8.625C12.75 6.64022 11.0711 5.03125 9 5.03125C6.92892 5.03125 5.25 6.64022 5.25 8.625C5.25 10.6098 6.92892 12.2187 9 12.2187Z"
						fill="#D2D2D2"
					/>
				</svg>
			</div>
			{children}
		</components.Control>
	);
	return (
		<div >
			<AsyncSelect key={i18n.language}
				components={{
					Control,
					SingleValue,
					IndicatorSeparator: () => null,
					DropdownIndicator: ({ selectProps }) => (
						<span
							style={{
								transform: selectProps?.menuIsOpen ? "rotate(180deg)" : "",
								transition: "400ms",
							}}
							className={s.dropdownArrow}
						>
							<ArrowsAndCross id={"arrowForSelect"} />
						</span>
					),
				}}
				loadOptions={loadOptions}
				cacheOptions
				defaultOptions={true}
				onChange={(newValue) => {
					handleChange(newValue);
				}}
				value={value}
				styles={{
					control: (baseStyles, state) => ({
						...baseStyles,
						width: "100%",
						padding: "0 15px 0 10px ",
						borderColor: state.isDisabled ? "" : "",
						borderRadius: "8px",
						boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.09)",
						backgroundColor: "#FFFFFF",
						color: "#FFFFFF",
						cursor: "pointer",

						"&:hover": {
							backgroundColor: "",
							borderColor: "",
						},
					}),
					singleValue: (styles) => {
						return {
							...styles,
							display: "flex",
							alignItems: "center",
							fontFamily: "var(--myMainFontRegular)",
							color: "#606669",
							"& .selectText": {
								maxWidth: "450px",
								overflow: "hidden",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
							},
							"& svg": {
								margin: "0 13px 0 0 ",
							},
						};
					},
					menu: (base) => ({
						...base,
						boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.06)",
						zIndex: "100",
					}),
					menuList: (base) => ({
						...base,
						padding: 0,
						maxHeight: "350px",
					}),
					dropdownIndicator: (base) => ({
						...base,
					}),
					option: (base, state) => ({
						...base,
						boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.06)",
						borderRadius: "5px",
						border: state.isDisabled ? "1px solid red" : "red",
						color: state.isFocused ? "black" : "#939393",
						background: state.isFocused ? "none" : "none",
						fontFamily: "var(--myMainFontRegular)",
						fontSize: "14px",
					}),
					input: (base) => ({
						...base,
						overflow: "hidden",
					}),
				}}
			/>
		</div>
	);
};

export default SearchLocationSelect;
