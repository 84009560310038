import s from "./ProductCardHorizontalMobile.module.css";
import { publicOneNotice } from "../../../types/publicNoticeTypes";
import { useSelector } from "react-redux";
import { StateType } from "../../../redux/redux-store";
import { VacancyType, WorkInfoList } from "../../../pages/OutputBordAD/WorkNotice/WorkInfoList/WorkInfo";
import WorkSkeletonComponentMobile from "../../WorkSkeletonComponent/WorkSkeletonComponentMobile";
import { noticeImageAlt } from "../../../common/Logic/supporting";

const WorkProductCardHorizontalForSearchMobile = ({
	notice,
	isRow,
	favoriteHeart
}: {
	notice: publicOneNotice;
	isRow?: boolean; 
	favoriteHeart: JSX.Element;
}) => {
	const userView = useSelector((state: StateType) => state.userData.user);
	const workType = notice?.noticeType?.notice_properties?.find(prop => prop.specialViewOnFront === "work_search_propose")?.propertyValues;
	const imageUrl = notice?.noticeImages[0]?.imageUrl;

	return (<>
		<div className={s.workImage}>
			{imageUrl ? (
				<img
					loading={"lazy"}
					src={imageUrl}
					alt={noticeImageAlt(notice)}
					title={noticeImageAlt(notice)}
				/>
			) : workType && (
				<WorkSkeletonComponentMobile
					type={workType}
					category={notice.category_name}
				/>
			)}
		</div>
		<div className={s.horizontalWorkCardTitleWrap}>
			<p>{notice?.name || ""}</p>
			{notice?.users_id && +notice?.users_id !== userView.id 
				? favoriteHeart
				: null}
		</div>
		<div className={s.horizontalWorkCardContent}>
			<WorkInfoList notice={notice} card />
			{notice.noticeType?.vacancyType &&
				<VacancyType type={notice.noticeType?.vacancyType} card />
			}
		</div>
	</>);
};

export default WorkProductCardHorizontalForSearchMobile;
