import React, { useEffect, useState } from "react";
import s from "../../pages/SearchResults/SearchResult.module.css";
import FilterFrontCheckbox from "../SearchFilters/FilterFrontCheckbox";
import FilterFrontButton from "../SearchFilters/FilterFrontButton";
import { OneCategoryType } from "../../types/reduxStoreTypes";
import { useNavigate } from "react-router";
import { CounterType, Filter, FiltersValue } from "../../types/publicNoticeTypes";
import FilterFrontDropdown from "../SearchFilters/FilterFrontDropdown";
import FilterFrontColor from "../SearchFilters/FilterFrontColor";
import FilterFrontRange from "../SearchFilters/FilterFrontRange";
import FilterFrontMatch from "../SearchFilters/FilterFrontMatch";
import { useTranslation } from "react-i18next";
import { Mobile } from "../../responsive";

type Props = {
	filtersList: Filter[];
	currentCategory: OneCategoryType;
	setFiltersValues: any;
	filtersValues: FiltersValue[];
	countersList: CounterType[];
	handleClickFilterButton?: () => void;
};

const SearchResultFiltersMain = ({
	filtersList,
	currentCategory,
	filtersValues,
	setFiltersValues,
	handleClickFilterButton,
	countersList,

}: Props) => {
	const [lastChangedFilter, setLastChangedFilter] = useState<number | null>(
		null
	);

	const navigate = useNavigate();
	const { t } = useTranslation();

	const idsFromChangedElements = filtersValues.map((item) => +item.value);
	const updatePropertyValues = (filterItem: Filter) => {

		filterItem.propertyValues = filterItem.propertyValues.map(
			(propertyValue: any) => {
				const correspondingCountItem = countersList.find(
					(countItem) =>
						+countItem.filterId === +filterItem.id &&
						+countItem.propertyValueId === +propertyValue.id
				);
				return {
					...propertyValue,
					countItem: correspondingCountItem || 0,

				};
			}
		);
		return filterItem;
	};
	const updatedFiltersList = filtersList.map(updatePropertyValues);
	const filtersFromChangedElements = filtersValues.map((item) => item.filter);

	const changedElements = updatedFiltersList.filter((item: any) => {
		if (
			item.view_on_front === 1 &&
			filtersFromChangedElements.includes(item.inputName) &&
			item.propertyValues &&
			item.propertyValues.length > 0
		) {
			// Проверяем, есть ли совпадение хотя бы для одного элемента в propertyValues
			return item.propertyValues.some((obj: any) =>
				idsFromChangedElements.includes(+obj.id)
			);
		}
		return false; // Если propertyValues пуст или нет совпадений, исключаем элемент
	});
	const changedElementsPropertyValues = changedElements.map((item: any) => {
		return {
			id: item.inputName,
			value: idsFromChangedElements,
			assign: [...item.propertyValues],
		};
	});


	useEffect(() => {
		const newQueryParams = new URLSearchParams();
		// Группировка значений по именам фильтров
		const filtersMap = new Map();
		filtersValues.forEach(({ filter, value,parent_id }) => {
			if (!filtersMap.has(filter)) {

				//filtersMap.set(filter, [value],);
				filtersMap.set(filter, [`${value}${parent_id ? `:${parent_id}` : ""}`]);
			} else {
				//filtersMap.get(filter).push(value);
				filtersMap.get(filter).push(`${value}${parent_id ? `:${parent_id}` : ""}`);
			}
		});
		// Добавление значений в новые параметры запроса
		filtersMap.forEach((values, filter) => {
			values.forEach((value: any) => {
				newQueryParams.append(filter, value);
			});
		});

		const newUrl = `${window.location.pathname}?${newQueryParams.toString()}`;
		navigate(newUrl, { replace: true });
	}, [filtersValues, currentCategory.id]);

	return (
		<div className={s.filtersMenuContainer} >
					{updatedFiltersList?.length !== 0 && updatedFiltersList?.map((f:Filter) => {
						if (
							f?.view_on_front === 3 &&
							f?.propertyType !== 3 && f?.propertyType !== 4

						) {
							return (
								<div key={f.id}>
									<div className={s.filtersElement}>
										<FilterFrontDropdown
											filter={f}
											filtersValues={filtersValues}
											setFiltersValues={setFiltersValues}
											lastChangedFilter={lastChangedFilter || 0}
											setLastChangedFilter={setLastChangedFilter}
											showOpened={f.showOpened}
										/>
									</div>
								</div>
							);
						} else if (
							f?.view_on_front === 1 &&
							f.propertyCrossList.length > 0 &&
							f?.propertyType !== 3 && f?.propertyType !== 4

						) {
							return (
								<div key={f.id}>
									<div className={s.filtersElement}>
										<FilterFrontMatch
											filter={f}
											filtersValues={filtersValues}
											changedElements={changedElementsPropertyValues}
											setFiltersValues={setFiltersValues}
											showOpened={f.showOpened}
											lastChangedFilter={lastChangedFilter || 0}
											setLastChangedFilter={setLastChangedFilter}
										/>
									</div>
								</div>
							);
						} else if (
							f?.view_on_front === 1 &&
							f?.propertyType !== 3 && f?.propertyType !== 4


						) {
							return (
								<div key={f.id}>
									<div className={s.filtersElement}>
										<FilterFrontCheckbox
											filter={f}
											filtersValues={filtersValues}
											setLastChangedFilter={setLastChangedFilter}
											setFiltersValues={setFiltersValues}
											showOpened={f.showOpened}
											lastChangedFilter={lastChangedFilter || 0}
										/>
									</div>
								</div>
							);
						} else if (
							f?.view_on_front === 2 &&
							f?.propertyType !== 3 && f?.propertyType !== 4

						) {
							return (
								<div key={f.id}>
									<div className={s.filtersElement}>
										<FilterFrontButton
											filter={f}
											filtersValues={filtersValues}
											setFiltersValues={setFiltersValues}
											showOpened={f.showOpened}
											lastChangedFilter={lastChangedFilter || 0}
											setLastChangedFilter={setLastChangedFilter}
										/>
									</div>
								</div>
							);
						} else if (
							f?.view_on_front === 4 &&
							f?.propertyType !== 3 && f?.propertyType !== 4

						) {
							return (
								<div key={f.id}>
									<div className={s.filtersElement}>
										<FilterFrontRange
											filter={f}
											filtersValues={filtersValues}
											setFiltersValues={setFiltersValues}
											showOpened={f.showOpened}
										/>
									</div>
								</div>
							);
						} else if (
							f.propertyType === 5 /* &&
							f?.propertyType !== 3 &&
							f?.propertyType !== 4*/
						) {
							return (
								<div key={f.id}>
									<div className={s.filtersElement}>
										<FilterFrontColor
											filter={f}
											filtersValues={filtersValues}
											setFiltersValues={setFiltersValues}
											lastChangedFilter={lastChangedFilter || 0}
											setLastChangedFilter={setLastChangedFilter}
											showOpened={f.showOpened}
										/>
									</div>
								</div>
							);
						} else {
							return <></>;
						}
					})}
			<Mobile>
				<div className={s.seeButtonContainer}>
					<button className={s.seeButton} onClick={handleClickFilterButton}>
						{t("Показати") /*{totalSum}*/}
					</button>
				</div>
			</Mobile>
			{/*<div className={s.filtersElement}>
                <SearchOptions/>
            </div>*/}
		</div>
	);
};

export default SearchResultFiltersMain;
