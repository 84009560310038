import React, { useState, useEffect } from "react";
import style from "./FilterComponent.module.css";
import s from "./FilterTypeWInputWithSearch.module.css";
import { NoticeFilterType } from "../../types/noticeTypes";
import useFilterHandle from "../../CustomHooks/useFilterHandle";
import { useTranslation } from "react-i18next";
import ArrowsAndCross from "../../common/ArrowsAndCross/ArrowsAndCross";

type Props = {
	filter: NoticeFilterType | any;
	handleAuth?: () => void;
};

const FilterTypeInputWithSearch = ({ filter, handleAuth }: Props) => {
	const { t } = useTranslation();
	/*const defaultValue:any = filtersOneIs.find((e: {id:string,name:string,filter:string})=> e.filter === filter.inputName)
    const defOption = filter.propertyValues.find((propertyValue:any)=>propertyValue.id === defaultValue?.id ? defaultValue.id : "")*/
	const [searchMenu, setSearchMenu] = useState(true);
	const [options, setOptions] = useState(filter.propertyValues);
	const [value, setValue] = useState(
		"" /*i18n.language==="ru"?defOption.name_ru:defOption.name*/
	);
	const [collapsed, setCollapsed] = useState(true);
	const { handleFilter } = useFilterHandle();

	useEffect(() => {
		if (filter?.showOpened) {
			setCollapsed(false);
		}
	}, [filter]);
	return (
		<div className={`${s.container}`}>
			<div className={`${style.name} ${style.nameAndCross}`}>
				{!filter?.needs ? (
					filter?.propertyName
				) : (
					<p>
						{filter?.propertyName} <span style={{ color: "red" }}>*</span>
					</p>
				)}
				{filter && (
					<span
						style={{
							transform: collapsed ? "" : "rotate(180deg)",
							transition: "0.5s",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
						className={style.colorFilterArrow}
						onClick={() => setCollapsed(!collapsed)}
					>
						<ArrowsAndCross id={"arrowForSelect"} />
					</span>
				)}
			</div>
			{!collapsed && (
				<input
					value={value}
					className={`${collapsed ? s.inputBorder : ""}`}
					style={{
						borderBottom: searchMenu && options.length > 0 ? "none" : "",
						borderRadius: searchMenu && options.length > 0 ? "5px 5px 0 0" : "",
					}}
					placeholder={t("Шукати, або залишити власне значення")}
					onFocus={handleAuth}
					onChange={(e) => {
						if (handleAuth) {
							handleAuth();
							return;
						}
						const newValue = e.currentTarget.value;
						setOptions(
							filter.propertyValues.filter((o: any) =>
								o.name.toLowerCase().startsWith(newValue.toLowerCase())
							)
						);
						setValue(newValue);

						/*setSearchMenu(newValue !== "")*/
						handleFilter({
							element: {
								id: `up_${newValue}`,
								name: filter.propertyName,
								name_ru: newValue,
								apiUrlDependedFilters:null
							},
							filter,
						});
					}}
				/>
			)}
			{!collapsed && searchMenu && options.length > 0 && (
				<>
					{options.length > 0 && (
						<div className={s.greyLineContainer}>
							<div className={s.greyLine} />
						</div>
					)}
					<div className={s.dropOptionsContainer}>
						<ul className={s.dropOptionsList}>
							{options.map((o: any) => (
								<li
									className={s.dropOptionsElement}
									key={o.id}
									onClick={() => {
										setValue(o.name);
										handleFilter({
											element: o,
											filter,
										});
										/*setSearchMenu(false)*/
									}}
								>
									{o.name}
								</li>
							))}
						</ul>
					</div>
				</>
			)}
		</div>
	);
};

export default FilterTypeInputWithSearch;
