import { Field } from "formik";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import AutomaticSelectionOfCategoriesModal from "../../../Components/AutomaticSelectionOfCategories/AutomaticSelectionOfCategoriesModal";
import AutomaticSelectionOfCategoriesTemplate from "../../../Components/AutomaticSelectionOfCategories/AutomaticSelectionOfCategoriesTemplate";
import s from "../CreateBoardAD.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
	getAllRubricsList,
	getCategoryBlocks,
	getCategoryView,
	getFiltersList,
} from "../../../redux/category_Reducer";
import { useParams } from "react-router-dom";
import { StateType } from "../../../redux/redux-store";
import i18n from "i18next";
import { OneRubricType, RubricTypeOnBoard } from "../../../types/categoriesTypes";
import { useNavigate } from "react-router";
import { setBooleanAutoChoosing } from "../../../redux/autoSelection_Reducer";

interface Props {
	autoSelectionOne: any;
	isCloseAutoSelectionStore: any;
	autoSelectionChoosing: any;
	setIsShowAutomaticSelection: any;
	status: any;
	setStatus: any;
	values: any;
	setFieldValue: any;
	isShowAutomaticSelection: any;
	handleAuth?: () => void;
}

const BigRubricsChanger: React.FC<Props> = ({
	autoSelectionOne,
	status,
	setStatus,
	values,
	setFieldValue,
	isShowAutomaticSelection,
	isCloseAutoSelectionStore,
	autoSelectionChoosing,
	setIsShowAutomaticSelection,
	handleAuth
}) => {
	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const { categoryName, noticeId } = useParams();
	const navigate = useNavigate();

	const getArrayRubrics = useSelector(() => getAllRubricsList);
	const getCategory = useSelector(() => getCategoryView);
	const getFilters = useSelector(() => getFiltersList);
	const getBlocks = useSelector(() => getCategoryBlocks);

	const currentCategory = useSelector(
		(state: StateType) => state.categoryData.view
	);
	const setValueForCategory = (value: number | null, label: string) => {
		setFieldValue("category_id_value", value);
		setFieldValue("category_id_label", label);
	};
	const handleChoseCategory = (rubric: RubricTypeOnBoard |OneRubricType | null) => {
		const alias =
			rubric?.isForNotice === "1" || rubric?.isForNotice === true
				? rubric.alias
				: (autoSelectionChoosing?.alias ?? "");

		if (alias) {
			const languagePath = i18n.language === "ru" ? "" : "ua/";
			navigate(`/${languagePath}create_ad/${alias}`, { replace: true });
			setIsShowAutomaticSelection(false)
		}

		dispatch(setBooleanAutoChoosing(false));
	};

	const rubricsFetched = useRef(false);
	const prevLanguage = useRef(i18n.language);

	useEffect(() => {
		if (!rubricsFetched.current && !noticeId) {
			dispatch(getArrayRubrics("0"));
			rubricsFetched.current = true;
		} else if (prevLanguage.current !== i18n.language) {
			// Если язык изменился, обновляем рубрики
			dispatch(getArrayRubrics("0"));
			prevLanguage.current = i18n.language;
		}
	}, [dispatch, i18n.language, noticeId]);

	useEffect(() => {
		if (categoryName) {
			dispatch(getCategory("", categoryName, "tree"));
		} else {
			setValueForCategory(null, "");
		}
	}, [dispatch, categoryName]);

	useEffect(() => {
		if (currentCategory?.id && !noticeId) {
			dispatch(getFilters(currentCategory.id));
			dispatch(getBlocks(currentCategory.id));
			setValueForCategory(currentCategory.id, currentCategory.name);
		}
	}, [currentCategory?.id, i18n.language]);


	return (
		<>
			{!!autoSelectionOne?.categoryId && isCloseAutoSelectionStore ? (
				<div className={s.formElementAutomaticSelection}>
					<div className={s.formElement} style={{ marginBottom: "25px" }}>
						<div className={s.nameSide}>
							<span className={s.elementName}>{t(`Автопідбір рубрики`)}:</span>
						</div>
					</div>
					<>
						{autoSelectionChoosing?.categoryId && !noticeId ? (
							<AutomaticSelectionOfCategoriesTemplate
								handleChoseCategory={handleChoseCategory}
								change={true}
								chose={false}
								handleAction={setIsShowAutomaticSelection}
								categoryName={autoSelectionChoosing?.categoryName}
								names={autoSelectionChoosing?.categoryInfo?.names.slice(0, 3)}
								image={autoSelectionChoosing?.categoryInfo?.urlIconLevel2}
							/>
						) : (
							<AutomaticSelectionOfCategoriesTemplate
								handleChoseCategory={handleChoseCategory}
								change={true}
								chose={false}
								handleAction={setIsShowAutomaticSelection}
								categoryName={autoSelectionOne?.categoryName}
								names={autoSelectionOne?.categoryInfo?.names.slice(0, 3)}
								image={autoSelectionOne?.categoryInfo?.urlIconLevel2}
							/>
						)}
					</>
					{status && status.category_id && (
						<span className={s.error}>{status.category_id}</span>
					)}
				</div>
			) : (
				<div className={`${s.formElement} ${s.rubricField}`}>
					<div className={s.nameSide}>
						<span className={s.elementName}>
							{t(`Оберіть рубрику`)}
							<span className={s.required}> * </span>
						</span>
					</div>
					<div
						className={`${s.fieldSide} ${status?.category_id ? s.error : ""}`}
					>
						{noticeId ? (
							<Field
								onFocus={() => handleAuth?.() ?? setStatus({})}
								name={"category_id_value"}
								disabled={true}
								placeholder={t("Оберіть рубрику")}
								autoComplete={"off"}
								value={values.category_id_label}
								readOnly={!!handleAuth}
							/>
						) : (
							<Field
								onFocus={() => handleAuth?.() ?? setStatus({})}
								name={"category_id_value"}
								onClick={() => setIsShowAutomaticSelection(true)}
								placeholder={t("Оберіть рубрику")}
								autoComplete={"off"}
								value={values.category_id_label}
								readOnly={!!handleAuth}
							/>
						)}
						{isShowAutomaticSelection && !noticeId && (
							<AutomaticSelectionOfCategoriesModal
								setFieldValue={setFieldValue}
								setIsOpen={setIsShowAutomaticSelection}
								handleChoseCategory={handleChoseCategory}
							/>
						)}
						{status && status.category_id && (
							<span className={s.error}>{status.category_id}</span>
						)}
					</div>
				</div>
			)}
			{isShowAutomaticSelection && !noticeId && (
				<AutomaticSelectionOfCategoriesModal
					handleChoseCategory={handleChoseCategory}
					setFieldValue={setFieldValue}
					setIsOpen={setIsShowAutomaticSelection}
				/>
			)}
		</>
	);
};

export default BigRubricsChanger;
