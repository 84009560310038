import React, { ReactNode } from "react";
import { useInterLinkStyles } from "../../../common/CommonStyles";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
} from "@mui/material";
import InterlinkSVGSelector from "../InterlinkingSVGselector";
import s from "../../PersonalCabinet/PersonalCabinetAccordion/PersonalCabinetAccordion.module.css";

type Props = {
	title: string;
	children: ReactNode;
};
const AccordionElement: React.FC<Props> = ({ title, children }) => {
	const classes = useInterLinkStyles();
	return (
		<div className={classes.accElementDiv}>
			<Accordion className={classes.accContainer}>
				<AccordionSummary
					className={classes.accSummary}
					expandIcon={<InterlinkSVGSelector id={"arrow"} />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<span>{title}</span>
				</AccordionSummary>
				<AccordionDetails className={classes.accDetails}>
					<div className={classes.accDetailsElement}>
						<div className={s.detailsElement}>{children}</div>
					</div>
				</AccordionDetails>
			</Accordion>
		</div>
	);
};

export default AccordionElement;
