import React from "react";
import s from "./ProductCardHorizontalMobile.module.css";
import productImage from "../../../images/cardItemHorizontal.jpg";
import { formattingPrice, noticeImageAlt } from "../../../common/Logic/supporting";
import { RecentlyWatchedNotice } from "../../../types/userTypes";
import { useTranslation } from "react-i18next";
import FavoriteHeartSelector from "../FavoriteHeartSelector/FavoriteHeartSelector";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { StateType } from "../../../redux/redux-store";

const ProductCardHorizontalMobile = ({
	notice,
}: {
	notice?: RecentlyWatchedNotice;
}) => {
	const userView = useSelector((state: StateType) => state.userData.user);
	const noticeTree = notice?.tree?.map((s: string) => s);
	const noticeUrl = noticeTree?.join("/");
	const { i18n, t } = useTranslation();
	const currentLanguage = i18n.language === "ru" ? "" : "/ua";
	const navigate = useNavigate();
	const navigateOnClick = () => {
		navigate(
			`${currentLanguage}/o/${noticeUrl ? noticeUrl + "/" : ""}${notice?.slug}`
		);
	};
	return (
		<div className={s.horizontalCardBlock} key={notice?.id}>
			<div className={s.horizontalCardImageBlock} onClick={navigateOnClick}>
				{notice?.imagePath ? (
					<img
						loading={"lazy"}
						src={notice?.imagePath}
						alt={noticeImageAlt(notice)}
						title={noticeImageAlt(notice)}
					/>
				) : (
					<img
						loading={"lazy"}
						src={productImage}
						alt={noticeImageAlt(notice)}
						title={noticeImageAlt(notice)}
					/>
				)}
			</div>
			<div className={s.horizontalCardContentBlock}>
				<div className={s.horizontalCardContenTitle} onClick={navigateOnClick}>
					<p>{notice?.name || ""}</p>
				</div>
				<div className={s.horizontalCardContenPrice} onClick={navigateOnClick}>
					<span>
						{notice?.price && notice.price !== "0.00"
							? formattingPrice(notice?.price)
							: t("Безкоштовно")}
						<span>
							{notice?.price && notice.price !== "0.00" ? " грн" : ""}
						</span>
					</span>
				</div>
				<div className={s.horizontalCardContenPlace}>
					{/* <p onClick={navigateOnClick}>{notice?.settlement_name || ""}</p> */}
					{notice?.ownerId && +notice?.ownerId !== userView.id ? (
						<FavoriteHeartSelector
							id={notice?.notice_id || 0}
							isFavorite={notice?.isFavorite || 0}
						/>
					) : (
						<></>
					)}
				</div>
			</div>
		</div>
	);
};

export default ProductCardHorizontalMobile;
