import ImageGallery from "react-image-gallery";
import { useNavigate } from "react-router";
import s from "../../pages/OutputBordAD/OutputBoardAD.module.css";
import OutputADSvgSelector from "../OutputBoardAdComponents/OutputADSvgSelector";
const FixedImageGallery = ImageGallery as any;
const MobileAdGallery = ({
	items,
	alt,
	itemSlug,
}: {
	items?: any;
	alt?: string;
	itemSlug?: string;
}) => {
	const navigate = useNavigate();

	const images = items.map((i: any, index: number) => {
		const imgAlt = (alt || "icon") + `, фото ${index + 1}`;
		return {
		original: i.originalImageUrl || "",
		thumbnail: i.imageUrl || "",
		originalAlt: imgAlt,
		thumbnailAlt: imgAlt,
		originalTitle: imgAlt,
		thumbnailTitle: imgAlt,
	}});

	return (
		<>
			<FixedImageGallery
				items={images}
				onClick={(event:any) => {
					const slideIndex = event.currentTarget.ariaLabel?.replace(/\D/g, "");
					navigate(`/o/${itemSlug}/gallery_chat/${slideIndex}`);
				}}
				additionalClass={"meMobileGallery"}
				useBrowserFullscreen={true}
				showThumbnails={false}
				showIndex
				indexSeparator={" з "}
				showFullscreenButton={false}
				showPlayButton={false}
			/>
			<button className={s.backArrow} onClick={() => navigate(-1)}>
				<span>
					<OutputADSvgSelector id={"backMobileArrow"} />
				</span>
			</button>
		</>
	);
};

export default MobileAdGallery;
