import { Field } from "formik";
import { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { numberAddSpace } from "../../../common/Logic/supporting";
import s from "../CreateBoardAD.module.css";

interface Props {
	status: any;
	setStatus: any;
	setFieldValue: any;
	values: any;
	handleAuth?: () => void;
}
const PriceField = forwardRef<HTMLDivElement, Props>(({ status, setStatus, setFieldValue, values, handleAuth }, ref) => {
	const [diapason, setDiapason] = useState(Boolean(values?.maxSalary));
	const { t } = useTranslation();

	useEffect(() => {
		if (Boolean(values?.maxSalary)) {
			setDiapason(true);
		}
	}, [values?.maxSalary])

	return (
		<div ref={ref}>
			<span className={s.elementName}>
				<b>{t(`Заробітна плата`)}</b>
				<span className={s.required}> * </span>
			</span>
			<div className={s.fieldSide}>
				<div className={s.salaryField}>
					<div className={`${s.inputWrap} ${status?.price ? s.error : ""}`}>
						{t("від")}
						<Field
							onFocus={() => handleAuth?.() ?? setStatus({})}
							value={values.price}
							name={"price"}
							onChange={(e: any) => {
								const formattedEvent = numberAddSpace(e.target.value);
								setFieldValue("price_type", "0");
								setFieldValue("price", formattedEvent);
							}}
							readOnly={!!handleAuth}
						/>
						{t("грн")}
					</div>
					{diapason && (
						<div className={`${s.inputWrap} ${status?.maxSalary ? s.error : ""}`}>
							{t("до")}
							<Field
								onFocus={() => handleAuth?.() ?? setStatus({})}
								value={values.maxSalary}
								name={"maxSalary"}
								onChange={(e: any) => {
									const formattedEvent = numberAddSpace(e.target.value);
									setFieldValue("maxSalary", formattedEvent);
								}}
								readOnly={!!handleAuth}
							/>
							{t("грн")}
						</div>
					)}
				</div>
				
				{status && status.price && (
					<span className={`${s.error} ${s.priceError}`}>{status.price}</span>
				)}
				{status && status.maxSalary && (
					<span className={`${s.error} ${s.priceError}`}>{status.maxSalary}</span>
				)}
				
				<div className={`${s.stateButtons} ${s.priceButtons}`}>
					<button
						type={"button"}
						onClick={() => {
							if (handleAuth) {
								handleAuth();
								return;
							}
							setDiapason(false)
							setFieldValue("maxSalary", null);
						}}
						className={!diapason ? s.active : undefined}
					>
						{t(`Одне значення`)}
					</button>
					<button
						type={"button"}
						onClick={() => { handleAuth?.() ?? setDiapason(true) }}
						className={diapason ? s.active : undefined}
					>
						{t(`Діапазон`)}
					</button>
				</div>
			</div>
		</div>
	);
});


export default PriceField;
