import React from "react";

interface Props {
	id: string;
}

function InterlinkSVGSelector({ id }: Props) {
	switch (id) {
		case "arrow":
			return (
				<svg width="24" height="12" viewBox="0 0 24 12" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M2.00977 2L11.993 9.99998L22.0098 2" stroke="#626262" strokeWidth="3" strokeLinecap="round"/>
				</svg>

			);

		default:
			return <svg></svg>;
	}
}

export default InterlinkSVGSelector;
