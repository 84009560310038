import React, { useContext, useState } from "react";
import s from "../../App.module.css";
import NavBarSvgSelector from "../NavBar/NavBarSvgSelector";
import Logo from "../Logo/Logo";
import { Link } from "react-router-dom";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import { styled } from "@mui/system";
import { Desktop } from "../../responsive";
import HeaderAuthBlock from "./HeaderAuthBlock";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { stateUserType } from "../../types/reduxStoreTypes";
import { StateType } from "../../redux/redux-store";
import { setClearFiltersList } from "../../redux/category_Reducer";
import { setImagesList } from "../../redux/notice_Reducer";
import { useLocation, useNavigate } from "react-router";
import {
	 setOpenRegAuthPopup,
	setPhoneEnterPopup,
} from "../../redux/user_Reducer";
import i18n from "i18next";
import { FormikContext } from "formik";
import { Slide, Badge } from "@mui/material";

function Header({
	headerSee,
}: {
	headerSee: boolean;
}) {
	const [openLanguage, setOpenLanguage] = useState(false);
	const auth = useSelector((state: stateUserType) => state.userData.isAuth);
	const userView = useSelector((state: StateType) => state.userData.user);

	const favoriteCount = useSelector(
		(state: StateType) => state.userData.userStatic.userCountFavorites
	);
	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const location = useLocation();
	const currentPath = location.pathname;
	const navigate = useNavigate();
	const formik = useContext(FormikContext);
	const localisationPath = i18n.language === "ru" ? "" : "ua/"
	const clearInputs = () => {
		dispatch(setImagesList([]));
		dispatch(setClearFiltersList());
		dispatch()
	};
	const isCreateAdPage = location.pathname.includes("/create_ad")

	const handleDialog = (b:boolean) => {
		dispatch(setOpenRegAuthPopup(b))
	}
	const handleClickCreateAd = () => {
		if (!auth) return;

		if (userView.is_approve_phone !== 1) {
			dispatch(setPhoneEnterPopup(true));
			return;
		}
		if (isCreateAdPage) {
			alert(
				t(
					"Оголошення, яке ви розпочали подавати," +
					" ще не було опубліковано. Бажаєте повернутися до цього оголошення?"
				)
			);
			formik.resetForm();
		} else {
			clearInputs();
			navigate(`/${localisationPath}create_ad`);
		}
	};

	/*const StyledOrderBadge = withStyles(() =>
        createStyles({
            badge: {
                width: "8px",
                height: "18px",
                right: 10,
                top: 0,
                /!*padding: '3px',*!/
                color: "black",
                backgroundColor: "#98F000",
                fontSize:"10px",
                lineHeight:"normal"
            },
        }),
    )(Badge);*/
	const StyledFavoriteBadge = styled(Badge)(() => ({
		'.MuiBadge-badge': {
			width: '8px',
			height: '18px',
			right: 5,
			top: 0,
			color: 'white',
			backgroundColor: '#787777',
			fontSize: '10px',
			lineHeight: 'normal',
			cursor: 'pointer',
		}}));
	/*const StyledMessageBadge = withStyles(() =>
        createStyles({
            badge: {
                width: "8px",
                height: "18px",
                right: 10,
                top: 0,
                /!*padding: '3px',*!/
                color: "black",
                backgroundColor: "#FFD74A",
                fontSize:"10px",
                lineHeight:"normal"
            },
        }),
    )(Badge);*/
	/*const StyledCartBadge = withStyles(() =>
        createStyles({
            badge: {
                width: "8px",
                height: "18px",
                right: 5,
                top: 0,
                color: "black",
                backgroundColor: "#53E0FF",
                fontSize:"10px",
                lineHeight:"normal"
            },
        }),
    )(Badge);*/

	const handleNavigation = () => {
		if (currentPath == "/") {
			window.location.reload();
		} else if (currentPath == "/ua") {
			window.location.reload();
		} else {
			// navigate(i18n.language === "uk" ? "/ua":"")
		}
	};




	return (
		<>
			<Slide direction="down" in={headerSee} timeout={640}>
				<header className={`${s.header} mui-fixed`}>
					<div className={s.headerContainer}>
						<div className={s.headerContainer_leftSide}>
							<div className={s.menuLogoBlock}>
								{/*<div className={s.menuButton} >
                                <Desktop>
                                    <NavBarSvgSelector id="menu"/>
                                </Desktop>
                                <Mobile>
                                    <NavBarSvgSelector id="mobileMenu"/>
                                </Mobile>
                            </div>*/}
								<div className={s.logo}>
									<Desktop>
										<div onClick={handleNavigation}>
											<Logo />
										</div>
									</Desktop>
								</div>
							</div>
							{/*<div className={s.customCatalogBoard}>
                            <CatalogBoard/>
                        </div>*/}
						</div>
						<div className={s.headerContainer_central}>
							{/*Не показывать поиск на страницах 20.05.2024 мит - сообщил Антон*/}
							{/*{segmentsArray?.length >= 1 && segmentsArray[0] !== "c" && segmentsArray[1] !== "c" &&
                        <HeaderSearchWindow />}*/}
						</div>
						<div className={s.headerContainer_RightSide}>
							<div className={s.iconGroups}>
								<div className={s.languageGroupsElement}>
									<LanguageSelector
										open={openLanguage}
										setOpenLanguage={setOpenLanguage}
									/>
								</div>
								<div
									className={s.iconsGroupsElement}
									onClick={() => {
										!auth && handleDialog(true);
									}}
								>
									{auth && +favoriteCount > 0 ? (
										<StyledFavoriteBadge
											overlap="rectangular"
											badgeContent={favoriteCount}
										>
											<Link to={auth ? `/${localisationPath}personal_cabinet/favorite` : ""}>
												<div>
													<NavBarSvgSelector id="favorite" />
												</div>
												<p className={s.favoriteP}>{t(`обране`)}</p>
											</Link>
										</StyledFavoriteBadge>
									) : (
										<Link to={auth ? `/${localisationPath}personal_cabinet/favorite` : ""}>
											<div>
												<NavBarSvgSelector id="favorite" />
											</div>
											<p className={s.favoriteP}>{t(`обране`)}</p>
										</Link>
									)}
								</div>
								{/*<div className={s.iconsGroupsElement}>
                                <StyledOrderBadge overlap="rectangular" badgeContent={6} color="primary">
                                    <Link to={"/orders"}>
                                        <div><NavBarSvgSelector id="orders"/></div>
                                        <p className={s.ordersText}>{t(`замовлення`)}</p>
                                    </Link>
                                </StyledOrderBadge>
                            </div>*/}

								{/*<div className={s.iconsGroupsElement}>
                                <StyledMessageBadge overlap="rectangular" badgeContent={12} color="secondary">
                                    <Link to={"/messages"}>
                                        <div><NavBarSvgSelector id="messages"/></div>
                                        <p className={s.messagesText}>{t(`повiдомлення`)}</p>
                                    </Link>
                                </StyledMessageBadge>
                            </div>*/}
								{/*<div className={s.iconsGroupsElement}>
                                <StyledCartBadge overlap="rectangular" badgeContent={2}>
                                    <Link to={"shopping_cart"}>
                                        <div><NavBarSvgSelector id="basket"/></div>
                                        <p className={s.cartText}>{t(`кошик`)}</p>
                                    </Link>
                                </StyledCartBadge>
                            </div>*/}
							</div>
							<div className={s.headerRightBlock}>
								<HeaderAuthBlock openEnterDialog={handleDialog} />
								<div className={s.addAD}>
									<Link
										to={`/${localisationPath}create_ad`}
										onClick={handleClickCreateAd}
									>
										<h3>+ {t(`додати оголошення`)}</h3>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</header>
			</Slide>
		</>
	);
}

export default Header;
