import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useParams, useSearchParams } from "react-router-dom";
import {
	getCategoryView,
	getDependSearchFilters,
	getFiltersList,
	setCategoryView,
	setErrorShow,
} from "../../redux/category_Reducer";
import {
	getFilteredNoticeList,
	setClearFilteredNotices,
	setFilteredNoticesAllList,
} from "../../redux/publicNotice_Reducer";
import {
	getSettlementView,
	setSearchingLocationInfo,
} from "../../redux/settlement_Reducer";
import { StateType } from "../../redux/redux-store";
import { setCategoriesList } from "../../redux/search_Reducer";
import { Desktop, Mobile } from "../../responsive";
import {
	CounterType,
	Filter,
	FiltersValue,
} from "../../types/publicNoticeTypes";
import { SearchCategory } from "../../types/searchTypes";
import NewPage404 from "../Page404/NewPage404";
import SearchResultDesktop from "./SearchResultDesktop";
import SearchResultMobile from "./SearchResultMobile";
import HomeSeo from "../../Components/SEO/HomeSEO";
import SearchSEO from "../../Components/SEO/SearchSEO";
import s from "./SearchResult.module.css";
import { SuperSEO } from "react-super-seo";
import InterlinkingMain from "../../Components/Interlinking/InterlinkingMain";
import { scrollToTop } from "../../common/Logic/supporting";

interface Props {
	category: boolean;
}

const SearchResult: React.FC<Props> = ({ category }) => {
	const error = useSelector((state: StateType) => state.categoryData.showError);
	const { i18n, t } = useTranslation();
	const filtersList = useSelector(
		(state: StateType) => state.categoryData.filters
	);
	const dependFiltersList = useSelector(
		(state: StateType) => state.categoryData.dependFilters
	);
	const countersList: CounterType[] = useSelector(
		(state: StateType) => state.publicNotice.countersForNotices
	);
	const dispatch: any = useDispatch();
	const navigate = useNavigate();
	const { categorySlag } = useParams();
	const location = useLocation();
	const getNoticeList = useSelector(() => getFilteredNoticeList);
	const getFilters = useSelector(() => getFiltersList);
	const getCategory = useSelector(() => getCategoryView);
	const getDepend = useSelector(() => getDependSearchFilters);
	const getView = useSelector(() => getSettlementView);

	const filteredNoticeList = useSelector(
		(state: StateType) => state.publicNotice.filteredNotices
	);
	const filteredNoticeListAllList = useSelector(
		(state: StateType) => state.publicNotice.filteredNoticesAllList
	);
	const currentCategory = useSelector(
		(state: StateType) => state.categoryData.view
	);
	/*const pagination = useSelector(
		(state: StateType) => state.publicNotice.filteredNoticesPagination
	);*/
	const categoryRange = useSelector(
		(state: StateType) => state.publicNotice.notice_price_diapason
	);
	const [sort, setSort] = useState("1");
	const [settlement, setSettlement] = useState("");
	const searchingLocation = useSelector(
		(state: StateType) => state.settlementData.searchingLocationInfo
	);
	const segmentsArray = location.pathname?.split("/").filter(Boolean);
	const { search } = useLocation();
	const [params, setParams] = useSearchParams();
	const searchQuery = params.get("search");
	const searchLocationParam = params.get("search-location");

	const removeQuery = () => {
		params.delete("search-location");
		setParams(params);
		setSearchingLocationInfo(null);
		setSettlement("");
	};

	const queryParams = new URLSearchParams(search);

	const filtersFromUrl = Array.from(queryParams.entries()).map(
		([filter, value]) => {
			const [filterValue, parent_id] = value.split(":");
			return {
				filter,
				value: filterValue,
				parent_id: +parent_id || null,
				id: parseInt(filter.replace("filter_", ""), 10),
				name: null,
			};
		}
	);
	const [filtersValues, setFiltersValues] =
		useState<FiltersValue[]>(filtersFromUrl);

	const sliderPriceFilter = useSelector(
		(state: StateType) => state.publicNotice.priceRangeFilter
	);
	const [sliderPriceMin, sliderPriceMax] = sliderPriceFilter;
	const [showSearchCategory, setShowSearchCategory] = useState(true);
	const [currentPage, setCurrentPage] = useState(0);
	const [currentAction, setCurrentAction] = useState("");
	const [firstEntry] = Array.from(queryParams.entries());
	const [sortByState, setSortByState] = useState("0");
	const [searchData, setSearchData] = useState({
		searchKey: "",
		searchValue: "",
	});
	const currentActions = {
		search: "Search",
		category: "Category",
	};
	const price_diapason = useSelector(
		(state: StateType) => state.categoryData.view?.notice_price_diapason
	);
	const sliderPrice = [
		price_diapason?.minPrice ? +price_diapason.minPrice : 0,
		price_diapason?.maxPrice ? +price_diapason.maxPrice : 0,
	];
	const categoriesSearch = useSelector(
		(state: StateType) => state.searchData.categories
	);
	useEffect(() => {
		scrollToTop();
	}, [searchQuery]);

	useEffect(() => {
		if (!currentCategory) {
			dispatch(setCategoryView({}));
		}
	}, [currentCategory]);

	useEffect(() => {
		if (
			!searchingLocation &&
			searchLocationParam &&
			searchLocationParam !== "ukraine"
		) {
			dispatch(getView(searchLocationParam, removeQuery));
		}
	}, [searchingLocation, searchLocationParam, dispatch, getView]);

	useEffect(() => {
		if (searchingLocation && !settlement) {
			setSettlement(searchingLocation.id.toString());
		}
	}, [searchingLocation, settlement]);

	useEffect(() => {
		if (firstEntry) {
			const [key, value] = firstEntry;
			setSearchData({ searchValue: value, searchKey: key });
		} else {
			setSearchData({ searchValue: "", searchKey: "" });
		}
	}, [location.search]);

	useEffect(() => {
		if (currentPage == 0 || currentPage == 1) {
			dispatch(setFilteredNoticesAllList(filteredNoticeList));
		} else {
			const newArr = [...filteredNoticeListAllList, ...filteredNoticeList];
			dispatch(setFilteredNoticesAllList(newArr));
		}
	}, [filteredNoticeList]);

	useEffect(() => {
		const filtersRangeQuery = filtersValues
			.filter((item) => item.filter === "filtersRange[]")
			.map((item) => `filtersRange%5B%5D=${item.value}`)
			.join("&");
		const filtersAllQuery = filtersValues
			.filter((item) => item.filter.startsWith("filter_"))
			.map((f: FiltersValue) => +f.value);
		const commonParams = {
			sortType: sort,
			sortByState: sortByState,
			settlement_id: settlement,
			page: `${currentPage !== 0 ? currentPage : 1}`,
			filters: filtersAllQuery,
			filtersRange: filtersRangeQuery,
			name: searchQuery ? searchQuery : "",
			saveQuery: "1",
			minPrice: sliderPriceMin,
			maxPrice: sliderPriceMax,
			isSearch: !category,
			perPage: "48",
			// perPage: currentPage == 0 ? '24' : '96',
		};
		if (category) {
			dispatch(
				getCategory("", segmentsArray[segmentsArray.length - 1], [
					"notice_price_diapason",
					"tree",
				])
			);
			const categoryParams = {
				category_id: currentCategory?.id ?? undefined,
			};
			if (currentCategory?.id) {
				dispatch(
					getNoticeList({
						...categoryParams,
						...commonParams,
					})
				);
				dispatch(getFilters(currentCategory?.id));
				if (currentActions.category != currentAction) {
					setCurrentAction(currentActions.category);
					setCurrentPage(0);
				}
			}
		} else {
			dispatch(getNoticeList(commonParams));
			if (currentActions.search !== currentAction) {
				setCurrentAction(currentActions.search);
				setCurrentPage(0);
			}
			setCurrentAction(currentActions.search);
		}
		return () => {
			dispatch(setCategoriesList([]));
			dispatch(setErrorShow(false));
			dispatch(setClearFilteredNotices());
		};
	}, [
		category,
		i18n.resolvedLanguage,
		// searchData.searchKey,
		categorySlag,
		currentCategory?.id,
		sort,
		settlement,
		//pagination.totalCount, //page,
		filtersValues,
		sliderPriceFilter,
		sortByState,
		currentPage,
		params,
	]);
	const [updatedFilter, setUpdatedFilter] = useState<Filter[] | []>([]);

	const updateFilters = (filtersList: any[], dependFiltersList: any[]) => {
		// uniq id filters
		const seenFilterIds = new Set<number>();

		const newUpdatedFilter = filtersList.reduce(
			(acc: Filter[], filter: any) => {
				// check main filter for uniq by `filter.id`
				if (!seenFilterIds.has(filter.id)) {
					acc.push(filter);
					seenFilterIds.add(filter.id);
				}

				// search && add depends filters
				const matchingDependFilters = dependFiltersList?.filter(
					(dependFilter: any) => dependFilter.parent_id === filter.id
				);

				// check depends filters for uniq by  `dependFilter.id`
				matchingDependFilters?.forEach((dependFilter: any) => {
					if (!seenFilterIds.has(dependFilter.id)) {
						acc.push(dependFilter);
						seenFilterIds.add(dependFilter.id);
					}
				});

				return acc;
			},
			[]
		);

		setUpdatedFilter(newUpdatedFilter);
	};
	useEffect(() => {
		if (filtersValues?.length > 0 && currentCategory?.id) {
			for (const value of filtersValues) {
				if (value.parent_id === null && value.id && value.value) {
					dispatch(getDepend(currentCategory.id, +value.value, value.name));
				}
			}
		}
	}, [filtersValues, currentCategory?.id]);
	useEffect(() => {
		updateFilters(filtersList, dependFiltersList ?? []);
	}, [filtersList, dependFiltersList]);

	if (error) {
		return <NewPage404 />;
	}
	const categoryBreadcrumbsLinks = currentCategory?.tree?.map(
		(
			l: {
				alias: string;
				name: string;
				name_ru: string;
			},
			index: number
		) => ({
			name: l.name ? l.name : l.name_ru || "",
			alias: l?.alias || "",
			linksLength: currentCategory.tree.length,
			active: index === currentCategory?.tree?.length - 1,
		})
	);

	const breadcrumbsSearchingName = {
		name:
			searchData.searchKey === "search"
				? `${t("Пошук")} "${searchData.searchValue}"`
				: "",
		alias: "",
		linksLength: null,
		active: true,
	};

	const handleClickBreadcrumbs = () => {
		//так как истиной по работе с фильтрами, которые считываются с query является массив filtersValues
		//Эта функция нужна на клик по категории в breadcrumbs, чтобы не меняя логику работы фильтров убрать параметры поиска и перейти в категорию
		const filtered = filtersValues.filter((i: any) => i.filter !== "search");
		setFiltersValues(filtered);
	};
	const finalBreadcrumbs = Boolean(categoryBreadcrumbsLinks) && [
		...(categoryBreadcrumbsLinks || []),
		breadcrumbsSearchingName,
	];

	const handleClickChoseCategory = (c: SearchCategory) => {
		const noticeUrl = c.categoryTree
			.map((s, index) => {
				if (index === 0) {
					if (c.categoryTree.length === 2) {
						return `${s}/c2/${s}`; // Добавляем '/c2' ко второму элементу + link
					} else {
						return `${s}/c2`; // Добавляем '/c2' ко второму элементу
					}
				} else {
					return s; // Возвращаем остальные элементы без изменений
				}
			})
			.join("/");
		navigate(
			`${i18n.language === "uk" ? "/ua" : ""}/c/${noticeUrl}?search=${
				searchData.searchValue
			}`
		);
	};
	const searchLocationName = searchingLocation
		? ` в ${searchingLocation[i18n.language === "uk" ? "description" : "description_ru"]}`
		: "";

	const seoTitle = category
		? currentCategory?.seo_title
		: `${searchData.searchValue}${searchLocationName} на ✔️ BAGO.UA`;

	const seoDescription = category
		? currentCategory?.seo_description
		: `${searchData.searchValue}${searchLocationName} ${t("на безкоштовній дошці оголошень📍 БАГО.UA. Тут можна подати оголошення безкоштовно і знайти товар")} "${searchData.searchValue}" ${t("або послугу!")}`;

	return (
		<>
			<SuperSEO
				title={seoTitle}
				description={seoDescription}
				lang={`${i18n.language}-${i18n.language === "uk" ? "UA" : "RU"}`}
				openGraph={{
					ogImage: {
						ogImage: currentCategory?.icon ? currentCategory.icon : "",
						ogImageAlt: "bago",
						ogImageWidth: 100,
						ogImageHeight: 100,
						ogImageType: "image/webp",
					},
					ogTitle: seoTitle,
					ogDescription: seoDescription,
					ogSiteName: "Bago",
					ogUrl: `${i18n.language === "ru" ? "https://bago.ua" : "https://bago.ua/ua"}${location.pathname}`,
					ogLocale: i18n.language === "ru" ? "ru_Ru" : "uk_UA",
				}}
				robots={"index,follow"}
			>
				<meta name="twitter:site" content="bago.ua" />
				<meta name="twitter:creator" content="bago.ua" />
			</SuperSEO>
			<Desktop>
				{currentCategory && (
					<SearchResultDesktop
						category={category}
						setSort={setSort}
						filtersList={updatedFilter}
						currentCategory={currentCategory}
						countersList={countersList}
						filteredNoticeList={filteredNoticeList}
						breadcrumbsSearchingName={breadcrumbsSearchingName}
						setFiltersValues={setFiltersValues}
						finalBreadcrumbs={finalBreadcrumbs}
						handleClickBreadcrumbs={handleClickBreadcrumbs}
						filtersValues={filtersValues}
						setSettlement={setSettlement}
						setSortByState={setSortByState}
						sliderPrice={sliderPrice}
						categoryRange={categoryRange}
						categoriesSearch={categoriesSearch}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
						handleClickChoseCategory={handleClickChoseCategory}
						searchValue={searchData.searchValue}
					/>
				)}
			</Desktop>
			<Mobile>
				<SearchResultMobile
					category={category}
					setShowSearchCategory={setShowSearchCategory}
					showSearchCategory={showSearchCategory}
					setFiltersValues={setFiltersValues}
					filtersValues={filtersValues}
					setSort={setSort}
					categoryRange={categoryRange}
					handleClickChoseCategory={handleClickChoseCategory}
				/>
			</Mobile>
			{category && (
				<InterlinkingMain
					page={"SearchResults"}
					categoryTree={currentCategory?.tree}
					categoryName={currentCategory?.name || ""}
					categoryId={currentCategory?.id}
					inSearch={!category}
				/>
			)}
			{currentCategory && currentCategory?.seo_text?.length > 0 ? (
				<div className={s.seoContainer}>
					{/*<div className={s.seoTitle}>{currentCategory?.seo_title}</div>*/}
					<HomeSeo seoTextHtml={currentCategory?.seo_text || ""} />
				</div>
			) : (
				<div className={s.seoContainer}>
					<SearchSEO searchValue={searchData.searchValue} />
				</div>
			)}
		</>
	);
};

export default SearchResult;
