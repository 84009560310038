import React, { useEffect } from "react";
import s from "./FilterComponent.module.css";
import FilterTypeCheckbox from "./FilterTypeCheckbox";
import { valuesCreateAD } from "../../types/userTypes";
import FilterColor from "../SearchFilters/FilterColor";
import FilterTypeButton from "./FilterTypeButton";
import { useSelector } from "react-redux";
import { StateType } from "../../redux/redux-store";
import FilterTypeDropdownSelect from "./FilterTypeDropdownSelect";
import { useTranslation } from "react-i18next";
import FilterTypeInput from "./FilterTypeInput";
import FilterTypeDropdown from "./FilterTypeDropdown";
import FilterTypeInputWithSearch from "./FilterTypeInputWithSearch";
import { categoryStateType } from "../../redux/category_Reducer";

type Props = {
	filter: any;
	values: valuesCreateAD;
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined
	) => void;
	status: any;
	handleAuth?: () => void;
};

/*
const FILTER_TYPE_ONE_FROM_LIST = 1;
const FILTER_TYPE_MANY_FROM_LIST = 2;
const FILTER_TYPE_INPUT_NUMBER = 3;
const FILTER_TYPE_INPUT_TEXT = 4;

const FILTER_SEE_AS_CHECK = 1;
const FILTER_SEE_AS_BUTTON = 2;
const FILTER_SEE_AS_DROPDOWN = 3;
const FILTER_SEE_AS_DIAPASON = 4; при подаче нет
const FILTER_SEE_AS_INPUT = 5;*/

const FilterComponent = ({ filter, setFieldValue, values, status, handleAuth }: Props) => {
	const { i18n } = useTranslation();
	const filtersMany = useSelector(
		(state: StateType) => state.noticeData.filtersMany
	);
	const filtersOneIs = useSelector(
		(state: StateType) => state.noticeData.filtersOneIs
	);
	useEffect(() => {
		if (values && setFieldValue) {
			setFieldValue("filters", [...filtersMany, ...filtersOneIs]);
			setFieldValue("notice_properties", [...filtersMany, ...filtersOneIs]);
		}
	}, [filtersMany, filtersOneIs, i18n.language]);

	const filterName = filter.inputName.replace(/\[|\]/g, "");

	if (filter.view_as === 1) {
		if (filter.id === 2759) return null // не показуємо, якщо це фільтр "Тип роботи"
		//checkbox
		return (
			<>
				<FilterTypeCheckbox
					filter={filter}
					values={values}
					handleAuth={handleAuth}
				/>
				{status && status[filterName] && (
					<span className={s.error}>{status[filterName]}</span>
				)}
			</>
		);
	} else if (filter.view_as === 2) {
		//buttons
		/*const filterName = filter.inputName.replace(/\[|\]/g, "");*/
		return (
			<>
				<FilterTypeButton
					filter={filter}
					filtersMany={filtersMany}
					filtersOneIs={filtersOneIs}
					handleAuth={handleAuth}
				/>
				{status && status[filterName] && (
					<span className={s.error}>{status[filterName]}</span>
				)}
			</>
		);
	} else if (filter.view_as === 3) {
		//dropdowns
		/*const filterName = filter.inputName.replace(/\[|\]/g, "");*/

		return (
			<>
				{filter.type !== 1 && ( //manyIs

					<FilterTypeDropdown
						filter={filter}
						filtersMany={filtersMany}
						filtersOneIs={filtersOneIs}
						handleAuth={handleAuth}
					/>
				)}
				{filter.type === 1 && filter.canUserAddValue !== "1" && (
					//oneIs
					<>
						<FilterTypeDropdownSelect
						filter={filter}
						filtersOneIs={filtersOneIs}
						handleAuth={handleAuth}
					/>
						{/*{dependFilters?.map((filter: any) => {
							return (
								<div key={filter.id}>
									<FilterComponent
										values={values}
										filter={filter}
										status={status}
										setFieldValue={setFieldValue}
									/>
								</div>
							);
						})}*/}
					</>

				)}

				{filter.view_as === 3 &&
					filter.type === 1 &&
					filter.canUserAddValue === "1" && (
						<FilterTypeInputWithSearch
							filter={filter}
							handleAuth={handleAuth}
						/>
					)}
				{status && status[filterName] && (
					<span className={s.error}>{status[filterName]}</span>
				)}
			</>
		);
	} else if (filter.propertyType === 5) {
		//color

		/*const filterName = filter.inputName.replace(/\[|\]/g, "");*/

		return (
			<>
				<FilterColor
					filter={filter}
					values={values}
					filtersMany={filtersMany}
					filtersOneIs={filtersOneIs}
					handleAuth={handleAuth}
				/>

				{status && status[filterName] && (
					<span className={s.error}>{status[filterName]}</span>
				)}
			</>
		);
	} else if (filter.view_as === 5) {
		//input
		/*const filterName = filter.inputName.replace(/\[|\]/g, "");*/
		return (
			<>
				<FilterTypeInput
					filter={filter}
					filtersOneIs={filtersOneIs}
					handleAuth={handleAuth}
				/>
				{status && status[filterName] && (
					<span className={s.error}>{status[filterName]}</span>
				)}
			</>
		);
	} else {
		return <div></div>;
	}
};

export default FilterComponent;
