import PersonalCabinetSvgSelector from "../PersonalCabinetSvgSelector";
import s from "./MessengersIcons.module.css";

type PropsType = {
	placement: "cabinet" | "create" | "contacts";
	setFieldValue?: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined
	) => void;
	values: {
		viber: null | number;
		telegram: null | number;
		whatsapp: null | number;
	};
	handleAuth?: () => void;
};

const MessengersIcons = ({ setFieldValue, values, placement, handleAuth }: PropsType) => {
	return (
		<div
			className={`${s.messengerIcons} ${placement === "cabinet" ? s.cabinetIconsContainer : ""}`}
		>
			<div
				className={s.messengerContainer}
				onClick={() => handleAuth?.() ?? setFieldValue?.("viber", values.viber === 1 ? 2 : 1)}
			>
				<PersonalCabinetSvgSelector
					id={placement !== "contacts" ? "viberBig" : "viber"}
					fill={
						values.viber === 1 ? `#7D3DAF` : values.viber === 0 ? "#BDBDBD" : ""
					}
				/>
				{placement === "cabinet" && (
					<span
						className={`${s.messengerName} ${values.viber === 1 ? s.active : ""}`}
					>
						Viber
					</span>
				)}
			</div>
			<div
				className={s.messengerContainer}
				onClick={() => handleAuth?.() ?? setFieldValue?.("telegram", values.telegram === 1 ? 2 : 1)}
			>
				<PersonalCabinetSvgSelector
					id={placement !== "contacts" ? "telegramBig" : "telegram"}
					fill={values.telegram === 1 ? `#2da0d1` : ""}
				/>
				{placement === "cabinet" && (
					<span
						className={`${s.messengerName} ${values.telegram === 1 ? s.active : ""}`}
					>
						Telegram
					</span>
				)}
			</div>
			<div
				className={s.messengerContainer}
				onClick={() => handleAuth?.() ?? setFieldValue?.("whatsapp", values.whatsapp === 1 ? 2 : 1)}
			>
				<PersonalCabinetSvgSelector
					id={placement !== "contacts" ? "whatsAppBig" : "whatsApp"}
					fill={values.whatsapp === 1 ? `#17970f` : ""}
				/>
				{placement === "cabinet" && (
					<span
						className={`${s.messengerName} ${values.whatsapp === 1 ? s.active : ""}`}
					>
						WhatsApp
					</span>
				)}
			</div>
		</div>
	);
};

export default MessengersIcons;
