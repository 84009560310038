//import FacebookLogin from "@greatsumini/react-facebook-login";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
} from "@material-ui/core";
import { useGoogleLogin } from "@react-oauth/google";
import { ErrorMessage, Field, Formik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { ActionCreator } from "redux";
import ApproveEmailForChangedOrSet from "../../../Auth&RegNewPopups/ApproveEmailForChangedOrSet";
import ApprovePhonePopup from "../../../Auth&RegNewPopups/ApprovePhonePopup";
import BedResultChangePhonePopup from "../../../Auth&RegNewPopups/BedResultChangePhonePopup";
import ArrowsAndCross from "../../../common/ArrowsAndCross/ArrowsAndCross";
import LogoSvgSelector from "../../../common/LogoSVGSelectors/LogoSvgSelector";
import { setNotification } from "../../../redux/notificationReducer";
import { getStaticPageForFooter } from "../../../redux/pages_Reducer";
import { StateType } from "../../../redux/redux-store";
import {
	changeUserInfoEmail,
	changeUserInfoName,
	changeUserInfoPassword,
	changeUserInfoPhone,
	changeUserPhoto,
	getDeleteBagoClient,
	getNetworkAuthUserLink,
	setErrorApprovePhonePopup,
	setHandleApproveEmailPopup,
} from "../../../redux/user_Reducer";
import { Desktop, Mobile } from "../../../responsive";
import { IsStaticPagesColumns } from "../../../types/pagesTypes";
import { valuesForChangeUserInfo } from "../../../types/userTypes";
import MyImageInput from "../../MyImageInput/MyImageInput";
import AppleAuth from "../../Registration&Auth/AppleAuth";
import AuthSvgSelector from "../../Registration&Auth/AuthSvgSelector";
import PasswordFormInput from "../../Registration&Auth/PasswordFormInput";
import MessengersIcons from "../MessengersIcons/MessengersIcons";
import s from "./PersonalCabinetAccordion.module.css";
import { DeliveryServices } from '../DeliveryServices/DeliveryServices'
import { useCabinetStyles } from "../../../common/CommonStyles";




const PersonalCabinetAccordion = () => {
	const { t, i18n } = useTranslation();

	const approveEmailPopup = useSelector(
		(state: StateType) => state.userData.approveEmailPopup
	);
	const setApproveEmailPopup = useSelector(() => setHandleApproveEmailPopup);
	const handleErrorPhonePopup = useSelector(() => setErrorApprovePhonePopup);
	//const handlePhonePopup = useSelector(() => setApprovePhonePopupCabinet)
	const userView = useSelector((state: StateType) => state.userData.user);
	const [changedPhoto, setChangedPhoto] = useState(true);
	const [errorNetworkAuth, setErrorNetworkAuth] = useState("");
	const approvePhonePopup = useSelector(
		(state: StateType) => state.userData.openApprovePhonePopup
	);
	const errorApprovePhonePopup = useSelector(
		(state: StateType) => state.userData.errorApprovePhonePopup
	);
	const dispatch: any = useDispatch();
	const changeUserName = useSelector(() => changeUserInfoName);
	const changeUserPass = useSelector(() => changeUserInfoPassword);
	const changeUserPhone = useSelector(() => changeUserInfoPhone);
	const changeUserEmail = useSelector(() => changeUserInfoEmail);
	const bindAcc = useSelector(() => getNetworkAuthUserLink);
	const changePhoto = useSelector(() => changeUserPhoto);
	const safePass = useSelector((state: StateType) => state.userData.safePass);
	const classes = useCabinetStyles();
	const pageColumns: IsStaticPagesColumns = useSelector(
		(state: StateType) => state.pagesData.staticPagesColumns
	);
	const getPages: ActionCreator<any> = useSelector(
		() => getStaticPageForFooter
	);
	const navigate = useNavigate();
	useEffect(() => {
		dispatch(getPages());
	}, [i18n.language]);

	const googleLogin = useGoogleLogin({
		redirect_uri: "https://bago.ua",
		onSuccess: async ({ code }) => {
			const params = {
				code: code,
				clientName: "google",
			};
			dispatch(bindAcc(params, setErrorNetworkAuth));
		},
		flow: "auth-code",
	});

	const initialValues: valuesForChangeUserInfo = {
		name: userView.name || "",
		last_name: userView.last_name || "",
		parent_name: userView.parent_name || "",
		phoneForMessenger: userView?.phone || "",
		viber: userView.viber || 0,
		telegram: userView.telegram || 0,
		whatsapp: userView.whatsapp || 0,
		new_phone: userView.phone || "",
		password: "",
		emailNew: "",
		is_restricted: userView.is_restricted || 0,
		subscribeMessages: false,
		bunkingCardNumber: "",
		bunkingCardDate: "",
		checkRating: "",
		checkName: "",
		checkLastName: "",
		checkParentName: "",
		checkPhotoDocument: null,
		photo_file: null,
		smsCode: null,
		deleteAccPassword: "",
	};
	const validate = (values: any) => {
		const errors: any = { name: "" };

		if (values.name.trim() === "") {
			errors.name = t("Ім'я не може бути пусте або містити тільки пробіли");
		}
		// Другие правила валидации по необходимости
		return errors.name;
	};

	return (
		<div>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				//контактные данные
				onSubmit={(values, submitProps) => {
					if (
						values.name !== userView.name ||
						values.last_name !== userView.last_name ||
						values.parent_name !== userView.parent_name ||
						values.viber !== userView.viber ||
						values.telegram !== userView.telegram ||
						values.whatsapp !== userView.whatsapp
					) {
						const contactsDate = {
							name: values.name,
							last_name: values.last_name,
							parent_name: values.parent_name,
							phoneForMessenger: values?.new_phone,
							viber: values.viber,
							telegram: values.telegram,
							whatsapp: values.whatsapp,
						};
						dispatch(changeUserName(contactsDate, submitProps.setStatus));
					}
				}}
			>
				{({ values, status, handleSubmit, setFieldValue }) => (
					<form onSubmit={handleSubmit}>
						<div className={classes.accElementDiv}>
							<Accordion className={classes.accContainer}>
								<AccordionSummary
									className={classes.accSummary}
									expandIcon={<ArrowsAndCross id={"arrowForSelect"} />}
									aria-controls="panel1a-content"
									id="panel1a-header"
								>
									<span>{t(`Контактні дані`)}</span>
								</AccordionSummary>
								<AccordionDetails className={classes.accDetails}>
									<div className={classes.accDetailsElement}>
										<div className={s.detailsElement}>
											<div className={s.nameInputsContainer}>
												<div className={s.nameInputsInputs}>
													<p className={s.descriptionInput}>{t("Ім'я")}</p>
													<Field
														className={s.nameField}
														name={"name"}
														placeholder={t("Ваше ім'я")}
														value={values.name}
														validate={() => validate(values)}
													/>
													{status && status.name && (
														<div className={s.error}>{status.name}</div>
													)}
													<ErrorMessage
														name="name"
														component="div"
														className={s.error}
													/>
													<div className={s.lastNameContainer}>
														<p className={s.descriptionInput}>{t("Прізвище")}</p>
													<Field
														className={s.nameField}
														name={"last_name"}
														placeholder={t("Ваше прізвище")}
														value={values.last_name}
														validate={() => validate(values)}
													/>
													</div>
													<div className={s.phoneAndMessenger}>
														<PhoneInput
															inputClass={s.phoneInputStyle}
															country={"ua"}
															value={values.phoneForMessenger}
															disabled={true}
														/>
													</div>
													<div className={s.detailsElementDescription}>
														<p>
															{t(
																"Оберіть месенджер, по якому з Вами можуть зв'язатись інші користувачі"
															)}
														</p>
													</div>
													<MessengersIcons
														placement={"cabinet"}
														values={values}
														setFieldValue={setFieldValue}
													/>
												</div>
												<div className={s.nameInputsButtonContainer}>
													<button
														className={
															values.name !== userView.name ||
															values.last_name !== userView.last_name ||
															values.viber !== userView.viber ||
															values.telegram !== userView.telegram ||
															values.whatsapp !== userView.whatsapp
																? "touched"
																: ""
														}
													>
														{t(`Зберегти`)}
													</button>
												</div>
											</div>
										</div>
									</div>
								</AccordionDetails>
							</Accordion>
						</div>
					</form>
				)}
			</Formik>
			<Formik
				initialValues={initialValues}
				onSubmit={() => {
					dispatch(setNotification(t("Ваші зміни збережені."), "success"));
				}}
			>
				{({ values, status, handleSubmit, setFieldValue }) => (
					<form onSubmit={handleSubmit}>
						<div className={classes.accElementDiv}>
							<Accordion className={classes.accContainer}>
								<AccordionSummary
									className={classes.accSummary}
									expandIcon={<ArrowsAndCross id={"arrowForSelect"} />}
									aria-controls="panel1a-content"
									id="panel1a-header"
								>
									<span>{t("Способи доставки")}</span>
								</AccordionSummary>
								<AccordionDetails className={classes.accDetails}>
									<DeliveryServices />
								</AccordionDetails>
							</Accordion>
						</div>
					</form>
				)}
			</Formik>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				onSubmit={(values) => {
					//обавить фотку
					setChangedPhoto(!changedPhoto);
					if (values.photo_file) {
						dispatch(changePhoto({ photo_file: values.photo_file }));
					}
				}}
			>
				{({ values, status, handleSubmit, setFieldValue }) => (
					<form onSubmit={handleSubmit}>
						<div className={classes.accElementDiv}>
							<Accordion className={classes.accContainer}>
								<AccordionSummary
									className={classes.accSummary}
									expandIcon={<ArrowsAndCross id={"arrowForSelect"} />}
									disableTouchRipple={true}
									id="panel33a-header33"
								>
									<span>{t(`Додати аватар`)}</span>
								</AccordionSummary>
								<AccordionDetails className={classes.accDetails}>
									<div className={classes.accDetailsElement}>
										<div className={s.phoneInputContainer}>
											<MyImageInput
												blueContainer={false}
												id={"photoProfile"}
												value={"photo_file"}
												photo={userView?.photo?.photoUrl || null}
												setFieldValue={setFieldValue}
												changedPhoto={changedPhoto}
												setChangedPhoto={setChangedPhoto}
											/>
											{status && status.photo_file && (
												<span className={s.error}>{status.photo_file}</span>
											)}
											<button
												type={"submit"}
												className={
													values.photo_file && changedPhoto ? "touched" : ""
												}
											>
												{t(`Зберегти`)}
											</button>
										</div>
									</div>
								</AccordionDetails>
							</Accordion>
						</div>
					</form>
				)}
			</Formik>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				//смена телефона
				onSubmit={(values, submitProps) => {
					dispatch(
						changeUserPhone(
							{ new_phone: values.new_phone },
							submitProps.setStatus
						)
					);
				}}
			>
				{({ values, status, handleSubmit, setFieldValue }) => (
					<form onSubmit={handleSubmit}>
						<div className={classes.accElementDiv}>
							<Accordion className={classes.accContainer}>
								<AccordionSummary
									className={classes.accSummary}
									expandIcon={<ArrowsAndCross id={"arrowForSelect"} />}
									aria-controls="panel2a-content"
									id="panel2a-header2"
								>
									<span>{t(`Змінити телефон для входу`)}</span>
								</AccordionSummary>
								<AccordionDetails className={classes.accDetails}>
									<div className={classes.accDetailsElement}>
										<div className={s.phoneInputContainer}>
											<div className={s.phoneInput}>
												<PhoneInput
													inputClass={`${s.phoneInputStyle}
                                                   ${status?.new_phone ? s.error : ""}`}
													country={"ua"}
													onlyCountries={["ua"]}
													countryCodeEditable={false}
													value={values.new_phone}
													onChange={(phone, data, event, formattedValue) => {
														setFieldValue("new_phone", formattedValue);
													}}
												/>
												{status && status.new_phone && (
													<span
														style={{ position: "absolute" }}
														className={s.error}
													>
														{status.new_phone}
													</span>
												)}
											</div>

											<button
												type={"submit"}
												className={
													values.new_phone !== userView.phone ||
													userView.is_approve_phone === null
														? "touched"
														: ""
												}
											>
												{values.new_phone !== userView.phone
													? t(`Зберегти`)
													: t(`Підтвердити`)}
											</button>
										</div>
									</div>
								</AccordionDetails>
							</Accordion>
						</div>
					</form>
				)}
			</Formik>
			{userView.network === null && (
				<div className={classes.accElementDiv}>
					<Accordion className={classes.accContainer}>
						<AccordionSummary
							className={classes.accSummary}
							expandIcon={<ArrowsAndCross id={"arrowForSelect"} />}
							aria-controls="panel2a-content"
							id="panel2a-header2"
						>
							<span>{t(`Зв'язати акаунти`)}</span>
						</AccordionSummary>
						<AccordionDetails className={classes.accDetails}>
							<div className={classes.accDetailsElement}>
								<div className={s.bindAccContainer}>
									<div className={s.bindAccTextContainer}>
										<span>
											{t(
												"Налаштувати єдиний вхід через акаунти соціальних мереж"
											)}
										</span>
									</div>
									<div className={s.buttonsContainer}>
										{/*<FacebookLogin
											dialogParams={{
												state: "",
												redirect_uri: "https://facebook.com",
												response_type: "",
											}}
											children={
												<>
													<span className={s.icon}>
														<AuthSvgSelector id={"facebookIcon"} />
													</span>
													<Desktop>
														<span className={s.buttonsContainerButName}>
															Facebook
														</span>
													</Desktop>
												</>
											}
											appId="6a5ba48afc67a608f1c696eedc0ddc67"
											onSuccess={(response) => {
												console.log("Login Success!", response);
											}}
											onFail={(error) => {
												console.log("Login Failed!", error);
											}}
											onProfileSuccess={(response) => {
												console.log("Get Profile Success!", response);
											}}
										/>*/}
										<button onClick={() => googleLogin()}>
											<span className={s.icon}>
												<AuthSvgSelector id={"googleIcon"} />
											</span>
											<Desktop>
												<span className={s.buttonsContainerButName}>
													Google
												</span>
											</Desktop>
										</button>
										<AppleAuth
											setErrorApple={setErrorNetworkAuth}
											closeModal={() => {
												console.log("");
											}}
											type={"bind"}
										/>
										{/*<button onClick={() => {
                                         appleAuthHelpers.signIn({
                                             authOptions: {
                                                 // same as above
                                             },
                                             onSuccess: (response:any) => console.log(response),
                                             onError: (error:any) => console.error(error),
                                         });
                                    }}>
                                                    <span className={`${s.icon} ${s.appleIcon}`}>
                                                        <AuthSvgSelector id={'appleLogo'}/>
                                                    </span>
                                        <Desktop>
                                            <span className={s.buttonsContainerButName}>Apple ID</span>
                                        </Desktop>
                                    </button>*/}
									</div>
									{errorNetworkAuth !== "" && (
										<div className={s.error}>{errorNetworkAuth}</div>
									)}
								</div>
							</div>
						</AccordionDetails>
					</Accordion>
				</div>
			)}
			{approvePhonePopup && <ApprovePhonePopup approveNewPhone={"yes"} />}
			{errorApprovePhonePopup && (
				<BedResultChangePhonePopup close={handleErrorPhonePopup} />
			)}
			<Formik
				enableReinitialize
				initialValues={initialValues}
				//смена пароля
				onSubmit={(values, submitProps) => {
					if (values.password.length !== 0) {
						dispatch(
							changeUserPass(
								{ password: values.password },
								submitProps.setStatus
							)
						);
					}
				}}
			>
				{({ values, status, handleSubmit, setFieldValue }) => (
					<form onSubmit={handleSubmit}>
						<div className={classes.accElementDiv}>
							<Accordion className={classes.accContainer}>
								<AccordionSummary
									className={classes.accSummary}
									expandIcon={<ArrowsAndCross id={"arrowForSelect"} />}
									aria-controls="panel3a-content"
									id="panel3a-header3"
								>
									<span>{t(`Змінити пароль`)}</span>
								</AccordionSummary>
								<AccordionDetails className={classes.accDetails}>
									<div className={classes.accDetailsElement}>
										<div className={s.changedPassContainer}>
											<div className={s.changedPassInputs}>
												<PasswordFormInput
													values={values}
													setFieldValue={setFieldValue}
													status={
														status && status.new_password
															? status.new_password
															: ""
													}
												/>
											</div>
											<button
												type={"submit"}
												className={safePass ? "touched" : ""}
											>
												{t(`Зберегти`)}
											</button>
										</div>
									</div>
									<span></span>
								</AccordionDetails>
							</Accordion>
						</div>
					</form>
				)}
			</Formik>
			<Mobile>
				<div className={classes.accElementDiv}>
					<Accordion className={classes.accContainer}>
						<AccordionSummary
							className={classes.accSummary}
							expandIcon={<ArrowsAndCross id={"arrowForSelect"} />}
							aria-controls="panel5a-content"
							id="panel5a-header5"
						>
							<span>{t(`Умови використання сайту`)}</span>
						</AccordionSummary>
						<AccordionDetails className={classes.accDetails}>
							<div className={s.dynamicPagesWrap}>
								{!!pageColumns.column1.length &&
									pageColumns.column1.map((page) => (
										<Link
											key={page.id}
											to={`${i18n.language === "uk" ? "/ua" : ""}/static-page/${page.slug}`}
											className={s.dynamicPages}
										>
											{page.title}
										</Link>
									))}
							</div>
						</AccordionDetails>
					</Accordion>
				</div>

				<div className={classes.accElementDiv}>
					<Accordion className={classes.accContainer}>
						<AccordionSummary
							className={classes.accSummary}
							expandIcon={<ArrowsAndCross id={"arrowForSelect"} />}
							aria-controls="panel6a-content"
							id="panel6a-header6"
						>
							<span>{t(`Інформація для Продавців`)}</span>
						</AccordionSummary>
						<AccordionDetails className={classes.accDetails}>
							<div className={s.dynamicPagesWrap}>
								{!!pageColumns.column2.length &&
									pageColumns.column2.map((page) => (
										<Link
											key={page.id}
											to={`${i18n.language === "uk" ? "/ua" : ""}/static-page/${page.slug}`}
											className={s.dynamicPages}
										>
											{page.title}
										</Link>
									))}
							</div>
						</AccordionDetails>
					</Accordion>
				</div>
			</Mobile>

			<Formik
				enableReinitialize
				initialValues={initialValues}
				onSubmit={(values, submitProps) => {
					//cменить почту
					/*console.log(values.emailNew)*/
					dispatch(
						changeUserEmail(
							{ new_email: values.emailNew },
							submitProps.setStatus
						)
					);
				}}
			>
				{({ values, status, handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<div className={classes.accElementDiv}>
							<Accordion className={classes.accContainer}>
								<AccordionSummary
									className={classes.accSummary}
									expandIcon={<ArrowsAndCross id={"arrowForSelect"} />}
									aria-controls="panel4a-content"
									id="panel4a-header4"
								>
									<span>{t(`Змінити`)} email</span>
								</AccordionSummary>
								<AccordionDetails className={classes.accDetails}>
									<div className={classes.accDetailsElement}>
										<div className={s.emailInputContainer}>
											<div
												className={`${s.emailInputInput} ${status?.new_email ? s.error : ""}`}
											>
												<p>{t(`Використовується для відновлення пароля`)}</p>
												<div className={s.emailInputWrapper}>
													<Field
														type={"email"}
														name={"emailNew"}
														placeholder={t(`Введіть новий email`)}
													/>
												</div>
												{status && status.new_email && (
													<span className={s.error}>{status.new_email}</span>
												)}
												<div className={s.checkBoxContainer}>
													<input
														className={s.checkboxInput}
														id={"2"}
														type="checkbox"
													/>
													<label htmlFor={"2"}></label>
													<p>
														{t(
															`Отримувати повідомлення, якщо у мене замовили товар`
														)}
													</p>
												</div>
											</div>
											<button
												type={"submit"}
												className={
													values.emailNew.length !== 0 ? "touched" : ""
												}
											>
												{t(`Зберегти`)}
											</button>
										</div>
									</div>
								</AccordionDetails>
							</Accordion>
							{approveEmailPopup && (
								<ApproveEmailForChangedOrSet
									closePopup={() => dispatch(setApproveEmailPopup(false))}
									title={"Підтвердіть пошту"}
								/>
							)}
						</div>
					</form>
				)}
			</Formik>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				onSubmit={(values, submitProps) => {
					//удалить аккаунт
					dispatch(
						getDeleteBagoClient(
							navigate,
							submitProps.setStatus,
							i18n.language === "uk" ? "ua/" : "",
							values.deleteAccPassword
						)
					);
				}}
			>
				{({ values, handleSubmit, status }) => (
					<form onSubmit={handleSubmit}>
						<div className={classes.accElementDiv}>
							<Accordion className={classes.accContainer}>
								<AccordionSummary
									className={classes.accSummary}
									expandIcon={<ArrowsAndCross id={"arrowForSelect"} />}
									aria-controls="panel4a-content"
									id="panel4a-header4"
								>
									{/*<span>{t(`Видалити акаунт`)}</span>*/}
									<span>{t(`Видалити профіль користувача`)}</span>
								</AccordionSummary>
								<AccordionDetails className={classes.accDetails}>
									<div className={classes.accDetailsElement}>
										<div className={s.emailInputContainer}>
											<div className={`${s.deleteAccContainer}`}>
												<p className={s.dearText}>
													-{" "}
													{t(
														`Дорогий Користувач! Дякуємо за спільну мандрівку`
													)}
													!
												</p>
												<ol className={`${s.deleteAccList}`}>
													<li>
														{t(
															"Нам доведеться безповоротно обнулити ваш Баланс"
														)}
														{/*,*/}
													</li>
													{/*<li>{t("Всі Ваші оголошення та")}</li>*/}
													<li>
														{/*{t(
															"Вашу особисту інформацію буде видалено безповоротно"
														)}*/}
														{t(
															"Всі Ваші оголошення та Ваша особиста інформація будуть видалені безповоротно"
														)}
														{/*!*/}
													</li>
												</ol>
												<div className={s.deleteAccLogo}>
													<LogoSvgSelector id={"logoWithHeart"} />
												</div>
												<p className={s.seeYouAgain}>
													-{" "}
													{t(
														`Але спогади про Вас назавжди залишаться у нашому серці.`
													)}
													{/*!*/}
												</p>
												{/*<p>- {t(`Дякуємо за спільну мандрівку`)}!</p>*/}
												<p className={s.seeYouAgain}>
													- {t(`До нових зустрічей`)}!
												</p>
												<div className={s.emailInputWrapper}>
													<Field
														type={"deleteAccPassword"}
														name={"deleteAccPassword"}
														placeholder={t(`Введіть пароль`)}
													/>
												</div>
												{status && status.password && (
													<span className={s.error}>{status.password}</span>
												)}
											</div>
											<button
												type={"submit"}
												className={
													values.deleteAccPassword.length !== 0 ? "touched" : ""
												}
											>
												{t(`Видалити`)}
											</button>
										</div>
									</div>
								</AccordionDetails>
							</Accordion>
							{approveEmailPopup && (
								<ApproveEmailForChangedOrSet
									closePopup={() => dispatch(setApproveEmailPopup(false))}
									title={t("Підтвердіть пошту")}
								/>
							)}
						</div>
					</form>
				)}
			</Formik>
			{/*

                        <div className={classes.accElementDiv}>
                            <Accordion className={classes.accContainer}>
                                <AccordionSummary className={classes.accSummary}
                                                  expandIcon={<ArrowsAndCross   id={'arrowForSelect'}/>}
                                                  aria-controls="panel5a-content"
                                                  id="panel5a-header5">
                                    <span>{t(`Банківська карта`)}</span>
                                </AccordionSummary>
                                <AccordionDetails className={classes.accDetails}>
                                    <div className={classes.accDetailsElement}>
                                        <div className={s.banksCardContainer}>
                                            <div className={s.banksCardInputs}>
                                                <p>{t(`Введіть номер картки, на яку відбуватиметься оплата за куплений у вас товар іншими користувачами`)}</p>
                                                <Field name={"bunkingCardNumber"}
                                                       placeholder={"5156 **** **** 1100"}/>
                                                <Desktop>
                                                <p>{t(`Дані картки Не передаються Покупцю та Не відображаються в оголошеннях, а використовуються сервісом Privat24 або Monobank або LiqPay для проведення онлайн платежів у Безпечній угоді`)}</p>
                                                </Desktop>
                                            </div>
                                            <button>{t(`Зберегти`)}</button>
                                        </div>

                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>*/}

			{/*<Formik enableReinitialize initialValues={initialValues}

                    onSubmit={(values, submitProps) => {
                        //способы доставки
                        if (values.password.length !== 0) {
                            dispatch(changeUserPass({password: values.password}, submitProps.setStatus))
                        }
                    }}>
                {({   values,
                      status,
                      handleSubmit,
                      setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <div className={classes.accElementDiv}>
                            <Accordion className={classes.accContainer}>
                                <AccordionSummary className={classes.accSummary}
                                                  expandIcon={<ArrowsAndCross   id={'arrowForSelect'}/>}
                                                  aria-controls="panel6a-content"
                                                  id="panel6a-header6">
                                    <span>{t(`Способи доставки`)}</span>
                                </AccordionSummary>
                                <AccordionDetails className={classes.accDetails}>
                                    <div className={classes.accDetailsElement}>
                                        <div className={s.deliveryContainer}>
                                            <div className={s.deliveryInputs}>
                                                <p>{t(`Виберіть зручні для вас способи доставки. Ці налаштування будуть застосовані до всіх ваших об'яв.`)}</p>

                                                <ul className={s.deliveryTable}>
                                                    <li className={s.deliveryTableElement}>
                                                        <button className={s.deliveryButton}>
                                                            <div className={s.deliveryLogo}>
                                                                <OutputADSvgSelector id={"ukrPost"}/>
                                                            </div>
                                                            <div className={s.deliveryInfo}>
                                                                <span className={s.deliveryName}>Укрпошта</span>
                                                                <span className={s.deliveryTime}>{t(`Увімкнено`)}</span>
                                                            </div>
                                                        </button>
                                                    </li>
                                                    <li className={s.deliveryTableElement}>
                                                        <button className={s.deliveryButton}>
                                                            <div className={s.deliveryLogo}>
                                                                <OutputADSvgSelector id={"novaPost"}/>
                                                            </div>
                                                            <div className={s.deliveryInfo}>
                                                                <span className={s.deliveryName}>Нова пошта</span>
                                                                <span className={s.deliveryTime}>{t(`Не обрано`)}</span>
                                                            </div>
                                                        </button>
                                                    </li>
                                                    <li className={s.deliveryTableElement}>
                                                        <button className={s.deliveryButton}>
                                                            <div className={s.deliveryLogo}>
                                                                <OutputADSvgSelector id={"meest"}/>
                                                            </div>
                                                            <div className={s.deliveryInfo}>
                                                                <span className={s.deliveryName}>Meest</span>
                                                                <span className={s.deliveryTime}>{t(`Увімкнено`)}</span>
                                                            </div>
                                                        </button>
                                                    </li>
                                                    <li className={s.deliveryTableElement}>
                                                        <button className={s.deliveryButton}>
                                                            <div className={s.deliveryLogo}>
                                                                <OutputADSvgSelector id={"aloneDelivery"}/>
                                                            </div>
                                                            <div className={s.deliveryInfo}>
                                                                <span className={s.deliveryName}>{t(`Cамовивіз`)}</span>
                                                                <span className={s.deliveryTime}>{t(`Увімкнено`)}</span>
                                                            </div>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                            <button>{t(`Зберегти`)}</button>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </form>)}
            </Formik>*/}
			{/*
                        <div className={classes.accElementDiv}>
                            <Accordion className={classes.accContainer}>
                                <AccordionSummary className={classes.accSummary}
                                                  expandIcon={<ArrowsAndCross   id={'arrowForSelect'}/>}
                                                  aria-controls="panel7a-content"
                                                  id="panel7a-header7">
                                    <span>{t(`Перевірити репутацію`)}</span>
                                </AccordionSummary>
                                <AccordionDetails className={classes.accDetails}>
                                    <div className={classes.accDetailsElement}>
                                        <div className={s.checkRatingContainer}>
                                            <div className={s.checkRatingInputs}>
                                                <p>{t(`Введіть посилання на Вашу сторінку на іншому маркетплейсі, де буде видно ваш номер телефону та ваша репутація (кількість угод, дата реєстрації, позитивні відгуки)`)}</p>
                                                <Field name={"checkRating"}
                                                       placeholder={"htps:// другой маркетплейс /"}/>
                                                <p>{t(`Даний запит модерується позачергово`)}</p>
                                            </div>
                                            <button>{t(`Відправити`)}</button>
                                        </div>

                                    </div>
                                </AccordionDetails>
                            </Accordion></div>
                    </form>)}
            </Formik>*/}
			{/*<Formik enableReinitialize initialValues={initialValues}

                    onSubmit={(values, submitProps) => {
                        //огр возм
                        if (values.is_restricted !== userView.is_restricted) {
                            dispatch(changeUserRestricted({is_restricted: +values.is_restricted}, submitProps.setStatus))
                        }
                    }}>
                {({
                      values,
                      status,
                      handleSubmit,
                      setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <div className={classes.accElementDiv}>
                            <Accordion className={classes.accContainer}>
                                <AccordionSummary className={classes.accSummary}
                                                  expandIcon={<ArrowsAndCross id={'arrowForSelect'}/>}
                                                  aria-controls="panel8a-content"
                                                  id="panel8a-header8">
                                    <span>{t(`Обмежені можливості`)}</span>
                                </AccordionSummary>
                                <AccordionDetails className={classes.accDetails}>
                                    <div className={classes.accDetailsElement}>
                                        <div className={s.limitedPossibilityContainer}>
                                            <div className={s.limitedPossibilityInputs}>
                                                <div className={s.checkBoxContainer}>
                                                    <Field className={s.checkboxInput} id={"3"}
                                                           onChange={() => {
                                                               const booleanIsRestricted = values.is_restricted === 1 ? 0 : 1
                                                               setFieldValue('is_restricted', booleanIsRestricted)
                                                               console.log(values.is_restricted)
                                                           }}
                                                           type="checkbox"
                                                           checked={values.is_restricted === 1 && true}/>
                                                    <label
                                                        htmlFor={"3"}>{t(`Показувати повідомлення`)}</label>
                                                </div>
                                                <p>
                                                    <span>{t(`У контактах про Продавця буде виводитись повідомлення:`)}</span>
                                                    <br/>
                                                    <br/>
                                                    {t(`“Вітаю, Я Людина з обмеженими можливостями - `)}
                                                    {t(`зв'язатися зі мною ви можете тільки через чат або смс”`)}
                                                </p>
                                            </div>
                                            <button
                                                className={values.is_restricted !== userView.is_restricted ? "touched" : ""}>{t(`Зберегти`)}
                                            </button>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion></div>
                    </form>)}
            </Formik>*/}

			{/*<Formik enableReinitialize initialValues={initialValues}
                    onSubmit={(values, submitProps) => {
                        //роверка доков
                        if (values.checkName.length !== 0) {
                            const dataVerification = {
                                name: values.checkName,
                                last_name: values.checkLastName,
                                parent_name: values.checkParentName,
                                photo_document: values.checkPhotoDocument
                            }
                            dispatch(changeUserValidation(dataVerification, submitProps.setStatus))
                        }
                    }}>
                {({   values,
                      status,
                      handleSubmit,
                      setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <div className={classes.accElementDiv}>
                            <Accordion className={classes.accContainer}>
                                <AccordionSummary className={classes.accSummary}
                                                  expandIcon={<ArrowsAndCross   id={'arrowForSelect'}/>}
                                                  aria-controls="panel9a-content"
                                                  id="panel9a-header9">
                                    <span>{t(`Пройти верифікацію`)}</span>
                                </AccordionSummary>
                                <AccordionDetails className={classes.accDetails}>
                                    <div className={classes.accDetailsElement}>
                                        <div className={s.verifyContainer}>
                                            <div className={s.verifyInputs}>
                                                <div className={s.checkedSeller}>
                                                    {t(`Станьте`)} <CabinetAccordionSvgSelector id={"checkedSeller"}/>
                                                    <span>{t(`Перевірений продавець`)}</span>
                                                </div>
                                                <div className={s.upYourRating}>
                                                    {t(`Підніміть свій рейтинг довіри для своїх Покупців`)}
                                                </div>
                                                <div className={s.verifyInputsInputs}>
                                                    <Field name={"checkLastName"} placeholder={t(`Прізвище`)}/>
                                                    <Field name={"checkName"} placeholder={t(`Ім'я`)}/>
                                                    <Field name={"checkParentName"} placeholder={t(`Побатькові`)}/>
                                                </div>
                                                <div className={s.checkDocContainer}>
                                                    <div className={s.addDocText}>
                                                        {t(`Додати документ на перевірку`)}
                                                    </div>
                                                    <div className={s.checkDocImgContainer}>
                                                        <MyImageInput id={"checkPhotoDocument"}
                                                                      value={'checkPhotoDocument'}
                                                                      setFieldValue={setFieldValue}
                                                                        blueContainer={true}/>
                                                    </div>

                                                    <div className={s.addNoteText}>
                                                        {t(`Ваше фото з документом в руці, де чітко видно вашу фотографію Прізвище Ім'я Побатькові (Можливо закрити номер документа, роспись і всі інші дані)`)}
                                                    </div>
                                                    <div className={s.verificationText}>
                                                        {t(`Ми не зберігаємо та не публікуємо фото документа. Надіслане фото після модерації видаляється автоматично.`)}
                                                    </div>
                                                    <div>

                                                    </div>
                                                </div>
                                            </div>
                                            <button type={"submit"} className={values.checkName.length !== 0 &&
                                            values.checkLastName.length !== 0 &&
                                            values.checkParentName.length !== 0 &&
                                            values.checkPhotoDocument ? "touched" : ""}>{t(`Відправити`)}
                                            </button>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </form>)}
            </Formik>*/}
		</div>
	);
};

export default PersonalCabinetAccordion;
