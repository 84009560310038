const linkToCreate = {
	uk: `${process.env.REACT_APP_WEB_BASE_URL}/ua/create_ad`,
	ru: `${process.env.REACT_APP_WEB_BASE_URL}/create_ad`,
};

const mainLink = process.env.REACT_APP_WEB_BASE_URL;

const Constants = {
	linkToCreate,
	mainLink,
};

export default Constants;
