import React from "react";
import s from "./ChangeLayotZone.module.css";
import CabinetAccordionSvgSelector from "../PersonalCabinet/PersonalCabinetAccordion/CabinetAccordionSvgSelector";
import CreateBoardADSvg from "../../pages/CreateBoardAD/Components/CreateBoardADSvg";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { Image } from "./ChangeLayoutZone";
import { useMutation } from "@tanstack/react-query";
import { uploadImage } from "../../api/api";
import { responsiveForLayotZone } from "../../common/Logic/responsiveForLayotZone";

interface Props {
	approveCount: number;
	QUNTITI_ERROR: string;
	images: Image[];
	setImages: any;
	uploadedFiles: File[];
	setUploadedFiles: any;
	isWorkCategory: boolean;
	setFieldValue: any;
	MAX_FILE_SIZE: number
	handleAuth?: () => void;
}

const GridLoader: React.FC<Props> = ({
	approveCount,
	QUNTITI_ERROR,
	images,
	setImages,
	uploadedFiles,
	setUploadedFiles,
	isWorkCategory,
	setFieldValue,
	MAX_FILE_SIZE,
	handleAuth
}) => {
	const { t } = useTranslation();
	const isMobile = useMediaQuery({ maxWidth: 1024 });
	const INVALID_TYPE__ERROR = t("Невірний тип файлу");
	const UNKNOWN_ERROR = t("Невідома помилка");
	const DUPLICATE_ERROR = t("Виявлено дублікат");
	const cols = responsiveForLayotZone("cols");

	const errorMessages: { [key: string]: string } = {
		"too-many-files": QUNTITI_ERROR,
		"file-invalid-type": INVALID_TYPE__ERROR,
		// add error codes
	};
	const mutation = useMutation<
		{ tmpFile: { tmpFileUrl: string; tmpFileName: string } },
		Error,
		{ file: File; index: number; id: string } // add id
	>({
		mutationFn: async ({ file }) => {
			return await uploadImage(file, isWorkCategory ? 1 : 0);
		},
		onMutate: ({ id }) => {
			setImages((prev: Image[]) => {
				const totalImages = prev.length;
				const x = totalImages % cols;
				const y = Math.floor(totalImages / cols);

				return  [
					...prev,
					{
						i: id,
						x: x,
						y: y,
						w: 1,
						h: 1,
						content: null,
						name: "loading",
						tmpName: "loading",
						rotate: null,
					},
				];
			});
		},
		onSuccess: (data, { id, file }) => {
			// update with new image
			setImages((prev: Image[]) => {
				const updatedImages = prev.map((img) =>
					img.i === id
						? {
							...img,
							content: data.tmpFile.tmpFileUrl,
							name: file.name,
							tmpName: data.tmpFile.tmpFileName,
							rotate: null,
						}
						: img
				);

				// sort by coordinates x & y
				return  [...updatedImages].sort((a, b) => {
					if (a.y === b.y) {
						return a.x - b.x;
					}
					return a.y - b.y;
				});
			});

			// sort
			const updatedImageData = images
				.sort((a, b) => (a.y === b.y ? a.x - b.x : a.y - b.y))
				.map((image) => ({
					name: image.name,
					tmpName: image.tmpName,
					rotate: image.rotate,
				}));

			// update
			setFieldValue("noticeImagesString", updatedImageData);
		},
		onError: (_, { id }) => {
			setImages((prev: Image[]) => prev.filter((img) => img.i !== id));
			toast.error(UNKNOWN_ERROR);
		},
	});
	const handleFileUpload = (files: File[]) => {
		if (handleAuth) {
			handleAuth();
			return;
		}
		files.forEach((file, index) => {
			const id = uuidv4(); // gen unique id
			// check size (7 MB)

			if (file.size > MAX_FILE_SIZE) {
				// set error object
				const errorImage = {
					i: id,
					x: (images.length + index) % cols,
					y: Math.floor((images.length + index) / cols),
					w: 1,
					h: 1,
					content: "",
					name: "error",
					tmpName: "error",
					index,
				};
				// push error object to images
				setImages((prev: Image[]) => [...prev, errorImage]);
				return;
			}
			//check dublicates
			const isDuplicate = uploadedFiles.some(
				(uploadedFile) =>
					uploadedFile.name === file.name && uploadedFile.size === file.size
			);
			if (isDuplicate) {
				toast.error(DUPLICATE_ERROR);
			}
			const isApproveByQuantity = images.length < approveCount;
			if (!isApproveByQuantity) {
				toast.error(QUNTITI_ERROR);
				return;
			}
			if (!isDuplicate && isApproveByQuantity) {
				setUploadedFiles((prev: any) => [...prev, file]);
				mutation.mutate({ file, index, id });
			}
		});
	};
	const handleDropRejected = (fileRejections: any) => {
		const errors = fileRejections.flatMap(
			(rejection: any) =>
				rejection.errors.map((error: { code: string }) => error.code) // change type -  error.code
		);
		// get uniq errors
		const uniqueErrors = Array.from(new Set(errors));

		// convert errors to string
		const uniqueTranslatedErrors = uniqueErrors.map(
			(error: any) => errorMessages[error] || UNKNOWN_ERROR
		);
		toast.error(uniqueTranslatedErrors.join(", ") || null);

	};

	const { getRootProps, getInputProps } = useDropzone({
		onDrop: (acceptedFiles) => handleFileUpload(acceptedFiles),
		onDropRejected: handleDropRejected,
		accept: {
			"image/png": [".png"],
			"image/jpg": [".jpg"],
			"image/jpeg": [".jpeg"],
			"image/webp": [".webp"],
		},
		multiple: true,
		maxFiles: approveCount ?? 12,
	});
	return (
		<div>
			{!isMobile && (
				<div className={s.inputContainer} {...getRootProps()}>
					<div>
						<input {...getInputProps()} />
						<div className={s.checkPhotoSvgContainer}>
							<CabinetAccordionSvgSelector id={"checkPhoto"} />
							<span className={s.svgDescription}>
								{isWorkCategory ?
									t("Додати логотип") :
									t("Додати фото")
								}
							</span>
						</div>
					</div>
					<div className={s.inputText}>
						{isWorkCategory ?
							t("Перетягніть логотип сюди.") :
							t("Перетягніть фотографії сюди.")
						}
					</div>
				</div>
			)}
			{isMobile && !images?.length && (
				<div className={s.dropZoneContainer} {...getRootProps()}>
					<input {...getInputProps()} accept="image/*" />
					<div className={s.dropZoneBlock}>
						<div className={s.checkPhotoSvgContainer}>
							<div className={s.photoSvgBlock}>
								<CreateBoardADSvg id={"mobileCameraPicture"} />
								<span className={s.svgPlus}>+</span>
							</div>
						</div>
						<div className={s.checkPhotoLogoContainer}>
							<p>
								{t("Цікаво, а")} <span>ТУТ ФОТО</span>чки {t("будуть")}?
							</p>
							<CreateBoardADSvg id={"bagoLogo"} />
						</div>
					</div>
				</div>
			)}
			{isMobile && images?.length >0 &&  !isWorkCategory &&  (
				<div className={s.addNewPhotoBlock} {...getRootProps()}>
					<p>+ {t("Додати фото")}</p>
					<input {...getInputProps()} accept="image/*" />
				</div>
			)}
		</div>
	);
};

export default GridLoader;
