import React, { useEffect } from "react";
import s from "./Footer.module.css";
import Logo from "../Logo/Logo";
import { Link } from "react-router-dom";
import { getStaticPageForFooter } from "../../redux/pages_Reducer";
import { useDispatch, useSelector } from "react-redux";
import { ActionCreator } from "redux";
import { StateType } from "../../redux/redux-store";
import FooterSvgSelector from "./FooterSvgSelector";
import { IsStaticPagesColumns } from "../../types/pagesTypes";
import { useTranslation } from "react-i18next";

function Footer() {

	const dispatch = useDispatch();
	const pageColumns: IsStaticPagesColumns = useSelector(
		(state: StateType) => state.pagesData.staticPagesColumns
	);
	const date = new Date();

	const getPages: ActionCreator<any> = useSelector(
		() => getStaticPageForFooter
	);
	const { i18n } = useTranslation();

	useEffect(() => {
		dispatch(getPages());
	}, [i18n.language]);

	const localisationPath = i18n.language === "ru" ? "" : "ua/";

	return (
		<footer className={s.footer}>
			<div className={s.footerWrapper}>
				<div className="__container">
					<div className={s.footerContent}>
						<div className={s.LogoSocial}>
							<Logo />
							<FooterSvgSelector id="text" />

							<div className={s.footerIconsBlock}>
								<a
									href={
										"https://www.facebook.com/profile.php?id=61569036763909"
									}
									className={s.socialLink}
									target="_blank"
									rel="noopener noreferrer"
								>
									<FooterSvgSelector id="facebook" />
								</a>
								<a
									href={"http://t.me/bagoua"}
									className={s.socialLink}
									target="_blank"
									rel="noopener noreferrer"
								>
									<FooterSvgSelector id="telegram"  />
								</a>
								<a
									href={"https://x.com/bago_ua"}
									className={s.socialLink}
									target="_blank"
									rel="noopener noreferrer"
								>
									<FooterSvgSelector id="twitterX" />
								</a>
								{/*<SocialLogoAnimated id={"facebook"} />
									<SocialLogoAnimated id={"twitterX"} />
									<SocialLogoAnimated id={"telegram"} />*/}
							</div>
						</div>
						{(pageColumns.column1.length ||
							pageColumns.column2.length ||
							pageColumns.column3.length) && (
							<div className={s.footerContentBlock}>
								<div className={s.footerContentColumn}>
									{!!pageColumns.column1.length &&
										pageColumns.column1.map((page) => (
											<Link
												key={page.id}
												to={`/${localisationPath}static-page/${page.slug}`}
												className={s.footerPage}
											>
												{page.title}
											</Link>
										))}
								</div>
								<div className={s.footerContentColumn}>
									{!!pageColumns.column2.length &&
										pageColumns.column2.map((page) => (
											<Link
												key={page.id}
												to={`/${localisationPath}static-page/${page.slug}`}
												className={s.footerPage}
											>
												{page.title}
											</Link>
										))}
								</div>
								<div className={`${s.footerContentColumn} ${s.columnThird}`}>
									{!!pageColumns.column3.length &&
										pageColumns.column3.map((page) => (
											<Link
												key={page.id}
												to={`/${localisationPath}static-page/${page.slug}`}
												className={s.footerPage}
											>
												{page.title}
											</Link>
										))}
									{/* <a href="/" className={s.rule}>
                            <h6>{t("Правила безпеки")}</h6>
                            </a> */}
								</div>
								{/* <div className={`${s.footerContentColumn} ${s.columnThird}`}>
                            <p className={s.footerPage}>{t("Про нас")}</p>
                            <p className={s.footerPage}>Карта сайта</p>
                            </div> */}
								<div className={s.rule}>
									<h6>© {date.getFullYear()} bago.ua</h6>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className={s.border} />
			<div className={`${s.navBottomContainer} __container`}>
				<div className={s.navBottomRightElements}></div>
			</div>
		</footer>
	);
}

export default Footer;
