import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import Sticky from "react-stickynode";
import {
	AboutSeller,
	Contacts,
	DeliveryService,
	GuaranteeConditions,
	ManageActivityBloc,
	Payment,
	PhoneObj,
	ReturnConditions,
	UsersCategories,
} from "../../../../api/business-page/types";
import Advertising from "../../../../Components/Сommercial/Advertising";
import {
	useSetPublishPage,
	useSetPublishPageSlug,
} from "../../../../CustomHooks/api/business-page/useBusinessPage";
import useCatIdsHandler from "../../../../CustomHooks/useCatIdsHandler";
import { setNotification } from "../../../../redux/notificationReducer";
import { StateType } from "../../../../redux/redux-store";
import s from "../../BusinessPage.module.css";
import { SellerAds } from "./SellerAds/SellerAds";
import { SellerInfo } from "./SellerInfo/SellerInfo";
import { SellerRubrics } from "./SellerRubrics/SellerRubrics";
const FixedSticky = Sticky as any;
type Props = {
	aboutSeller: AboutSeller;
	contacts: Contacts;
	payment: Payment;
	guaranteeConditions: GuaranteeConditions;
	returnConditions: ReturnConditions;
	phones: Record<string, PhoneObj | null>;
	manageActivityBlock: ManageActivityBloc;
	statusPage: string | null;
	slugPage: string | null;
	deliveryServices: Record<string, DeliveryService | null>;
	mainPhone: string | null;
	userId: number;
	grid3: boolean;
	row: boolean;
	setGrid3: (val: boolean) => void;
	setRow: (val: boolean) => void;
	userRubrics: UsersCategories;
	sort: string;
	setSort: (value: string) => void;
	catIds: number[];
	setCategoryId: (value: number) => void;
	categoryId: number;
};

export const SellerContent = (props: Props) => {
	const { t } = useTranslation();
	const {
		aboutSeller,
		contacts,
		payment,
		guaranteeConditions,
		returnConditions,
		phones,
		manageActivityBlock,
		statusPage,
		slugPage,
		deliveryServices,
		mainPhone,
		userId,
		grid3,
		row,
		setGrid3,
		setRow,
		userRubrics,
		sort,
		setSort,
		setCategoryId,
		categoryId,
	} = props;
	const isMobile = useMediaQuery({ maxWidth: 1024 });
	const [slug, setSlug] = useState(slugPage !== null ? slugPage : "");
	const userView = useSelector((state: StateType) => state.userData.user);
	const [isDisabled, setIsDisabled] = useState(slugPage !== null);
	const { mutate: setPublishPage } = useSetPublishPage(slug);
	const { mutate: setPublishPageSlug } = useSetPublishPageSlug(slug);
	const dispatch: any = useDispatch();

	const catIdsForHook = useSelector(
		(state: StateType) => state.publicNotice.favoriteStatByCategoryIds
	);
	const { catIds, handleCatIds } = useCatIdsHandler(catIdsForHook);

	const [origin, setOrigin] = useState("");

	useEffect(() => {
		if (typeof window !== "undefined") {
			setOrigin(window.location.origin);
		}
	}, []);

	const isUserPage = userView.id === userId || userId === null;

	const onSendToPublishPage = async () => {
    if (!slugPage){
			dispatch(setNotification(t("Додайте назву сторінки"), "error"));
			return
		}
		if (aboutSeller?.about_seller?.value === ""){
			dispatch(setNotification(t("Додайте будь ласка опис"), "error"));
			return
		}
		if (aboutSeller?.about_seller?.value === ""){
			dispatch(setNotification(t("Додайте будь ласка опис"), "error"));
			return
		}
		if (statusPage === "Очікує на модерацію") {
			dispatch(
				setNotification(t("Ваша сторінка знаходиться на модерації"), "success")
			);
			return;
		}

		try {
			setPublishPage(undefined);
			dispatch(
				setNotification(
					t("Ваша сторінка відправилась на модерацію."),
					"success"
				)
			);
		} catch (e) {
			dispatch(setNotification(t("Щось пішло не так"), "error"));
		}
	};

	const onSetPublishPageSlug = async () => {

		const slugRegex = /^[a-zA-Z\-]+$/;
		if (!slugRegex.test(slug)) {
			dispatch(
				setNotification(
					t("Допускаються лише латинські символи та дефіс."),
					"error"
				)
			);
			return;
		}
		if (slug.length < 3 || slug.length > 255) {
			dispatch(
				setNotification(t("Слаг має бути від 3 до 255 символів."), "error")
			);
			return;
		}

		setPublishPageSlug(slug, {
			onSuccess: ({ data }) => {
				if (data.errors && data.errors.slug) {
					dispatch(setNotification(data.errors.slug, "error"));
					return;
				}
				dispatch(
					setNotification(t("Ваш шлях до сторінки встановлено."), "success")
				);
				setIsDisabled(true);
			},
			onError: (error: any) => {
				if (
					error.response &&
					error.response.data &&
					error.response.data.errors
				) {
					const errors = error.response.data.errors;
					if (errors.slug) {
						dispatch(setNotification(errors.slug, "error"));
						return;
					}
				}
				dispatch(setNotification(t("Щось пішло не так"), "error"));
			},
		});
	};

	return (
		<Grid container spacing={2} pb={15}>
			<Grid item xs={12} md={3}>
				<FixedSticky
					top={isMobile ? 110 : 50}
					innerClass={s.sticky__block}
					innerActiveClass={s.isSticky}
				>
					<SellerRubrics
						userRubrics={userRubrics}
						setGrid3={setGrid3}
						setRow={setRow}
						row={row}
						setSort={setSort}
						setCategoryId={setCategoryId}
						categoryId={categoryId ?? 0}
						catIds={catIds}
						handleCatIds={handleCatIds}
					/>
				</FixedSticky>
			</Grid>
			<Grid item xs={12} md={9}>
				<SellerAds
					sort={sort}
					businessPageUserId={userId}
					grid3={grid3}
					row={row}
					categoryId={categoryId ?? 0}
					catIds={catIds}
				/>

				<div className={s.adverts}>
					<Advertising idAD={1} />
				</div>

				{isUserPage ? (
					<div className={s.actionsContainer}>
						<div className={s.inputContainer}>
							<div className={s.titleContainer}>
								<h1>{t("Оберіть назву вашої сторінки")}</h1>
								<span>{t("Назву змінити не можна")}</span>
							</div>
							<input
								disabled={isDisabled}
								value={slug}
								onChange={(e) => setSlug(e.target.value)}
								type={"text"}
								name={"slug"}
								placeholder={t("наприклад men-clothes-store")}
							/>
							{!isDisabled ? (
								<button onClick={onSetPublishPageSlug} className={s.button}>
									{t("Застосувати")}
								</button>
							) : null}
						</div>
						<div className={s.urlContainer}>
							<p>{t("Повний URL вашої сторінки")}:</p>
							<p>{`${origin}/business-page/${slug}`}</p>
						</div>

						<h1 className={s.statusPageText}>
							{statusPage === "Очікує на модерацію"
								? t("Ваша сторінка знаходиться на модерації")
								: statusPage === "Активно"
									? t("Ваша сторінка активована")
									: statusPage === "Відхилено"
										? t("Ваша сторінка відхилена")
										: ""}
						</h1>
					</div>
				) : null}
				<SellerInfo
					aboutSeller={aboutSeller}
					contacts={contacts}
					payment={payment}
					guaranteeConditions={guaranteeConditions}
					returnConditions={returnConditions}
					phones={phones}
					manageActivityBlock={manageActivityBlock}
					deliveryServices={deliveryServices}
					slugPage={slugPage}
					mainPhone={mainPhone}
					isUserPage={isUserPage}
				/>
				{statusPage !== "Активно" ? (
					<button className={s.sendButton} onClick={onSendToPublishPage}>
						{t("Відправити на модерацію")}
					</button>
				) : null}
			</Grid>
		</Grid>
	);
};
