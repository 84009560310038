import s from "./FavoriteSearchCard.module.css";
import {
	publicOneNotice,
	TreeLinksType,
} from "../../../types/publicNoticeTypes";
import { useNavigate } from "react-router";
import FavoriteHeartSelector from "../FavoriteHeartSelector/FavoriteHeartSelector";
import { formattingPrice, noticeImageAlt } from "../../../common/Logic/supporting";
import { useTranslation } from "react-i18next";
import { VacancyType, WorkInfoList } from "../../../pages/OutputBordAD/WorkNotice/WorkInfoList/WorkInfo";
import WorkSkeletonComponent from "../../WorkSkeletonComponent/WorkSkeletonComponent";

const WorkFavoriteSearchCardRow = ({ notice }: { notice: publicOneNotice }) => {
	const noticeTree = notice?.tree?.map((s: TreeLinksType) => s.alias);
	const noticeUrl = noticeTree?.join("/");
	const navigate = useNavigate();
	const { i18n, t } = useTranslation();
	const currentLanguage = i18n.language === "ru" ? "" : "/ua";
	const salary = notice.maxSalary
		? <div className={s.longPrice}>
				<span className={s.priceNoWrap}>{t('від')} {formattingPrice(notice.price)} {t('грн')}</span> <span className={s.priceNoWrap}>{t('до')} {formattingPrice(notice.maxSalary)} {t('грн')}</span>
			</div>
		: `${formattingPrice(notice.price)} ${t('грн')}`

		
	const workType = notice.noticeType?.notice_properties?.find(prop => prop.specialViewOnFront === "work_search_propose")?.propertyValues;

	const imageUrl = notice?.noticeImages[0]?.imageUrl;

	return (<>
		<div
			className={s.workImage}
			onClick={() =>
				//navigate(`/o/${noticeUrl ? noticeUrl +"/" : ""}${notice?.slug}`)
				navigate(
					`${currentLanguage}/o/${noticeUrl ? noticeUrl + "/" : ""}${notice?.slug}`
				)
			}
		>
			{imageUrl ? (
				<img src={imageUrl} alt={noticeImageAlt(notice)} title={noticeImageAlt(notice)} />
			) : workType && (
				<WorkSkeletonComponent
					type={workType}
					category={notice.category_name}
					isRow
				/>
			)}
		</div>
		<div className={`${s.info} ${s.work}`}>
			<div
				className={s.infoTitle}
				onClick={() =>
					//navigate(`/o/${noticeUrl ? noticeUrl +"/" : ""}${notice?.slug}`)
					navigate(
						`${currentLanguage}/o/${noticeUrl ? noticeUrl + "/" : ""}${notice?.slug}`
					)
				}
			>
				<h4>{notice?.name || ""}</h4>
			</div>
			<WorkInfoList notice={notice} card={true} row={true} />
		</div>
		<div className={s.panel}>
			<div className={s.panelTop}>
				<span>
					<FavoriteHeartSelector
						id={notice?.id || 0}
						isFavorite={notice?.isFavorite || 0}
						userId={notice?.users_id || 0}
					/>
				</span>
				<span>
					{salary}
				</span>
			</div>

			<div className={s.panelBottom}>
				{notice?.noticeType?.vacancyType ? (
					<VacancyType type={notice.noticeType.vacancyType} card />
				): null}
			</div>
		</div>
	</>);
};

export default WorkFavoriteSearchCardRow;
