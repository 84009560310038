import i18n from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import img from "../../../../images/imageForCabinetItem.jpg";
import DontShowOtherAds from "../../../../pages/CreateBoardAD/Components/DontShowOtherAds";
import {
	getChangeNoticeStatus,
	getDeleteNotice,
	getEditNoticeAutoContinue,
	getEditOneNotice,
} from "../../../../redux/notice_Reducer";
import { formattingPrice, noticeImageAlt, testActualityService } from "../../../../common/Logic/supporting";
import { NoticeDataForModal, NoticeType } from "../../../../types/noticeTypes";
import AskApprovePopup from "../../../CommonPopups/AskApprovePopup/AskApprovePopup";
import SecondsToDate from "../../../TimeAndDate/SecondsToDate";
import PersonalCabinetSvgSelector from "../../PersonalCabinetSvgSelector";
import PlayPauseSwitch from "../../PlayPauseSwitch";
import s from "../MyAdsActive.module.css";
import MyAdsCardRightFuncPanel from "./MyAdsCardRightFuncPanel";
import useAutoContinue from "../../../../CustomHooks/useAutoContinue";
import WorkSkeletonComponent from "../../../WorkSkeletonComponent/WorkSkeletonComponent";

type CardProps = {
	type: number;
	notice: NoticeType;
	setOpenAutoRenewalSettings: (data: NoticeDataForModal) => void;
	setPitchToTopSettings: (b: boolean) => void;
};
const MyAdsCard = ({
	type,
	setOpenAutoRenewalSettings,
	setPitchToTopSettings,
	notice,
}: CardProps) => {
	const dispatch: any = useDispatch();
	const editPrice = useSelector(() => getEditOneNotice);
	const editAutoContinue = useSelector(() => getEditNoticeAutoContinue);
	const changeNoticeStatus = useSelector(() => getChangeNoticeStatus);
	const deleteAD = useSelector(() => getDeleteNotice);
	const [visibleAdsOption, setVisibleAdsOption] = useState(false);
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [currentItemId, setCurrentItemId] = useState<string | null>(null);
	const [visionPopup, setVisionPopup] = useState(0);
	const [priceValue, setPriceValue] = useState(
		formattingPrice(notice?.price || "")
	);
	const [priceError, setPriceError] = useState("");
	const [notShowOtherError, setNotShowOtherError] = useState("");


	const createDate = new Date(
		notice?.created_at ? notice?.created_at * 1000 : 0
	);

	const { t } = useTranslation();
	const noticeTree = notice?.tree?.map((s: any) => s.alias);
	const noticeUrl = noticeTree?.join("/");
	const languagePath = i18n.language === "ru" ? "" : "ua/"
	const visibleAutoContinue = useAutoContinue(notice);


	const handleAutoRenewal = (id: number, state: number) => {
		dispatch(editAutoContinue(id, state));
	};
	const handleStatus = (status: number) => {
		dispatch(changeNoticeStatus(notice?.id || 0, status));
	};
	const handleDeleteAD = (id: string | null) => {
		setCurrentItemId(id);
		setIsDialogOpen(true);
	};
	const handleCancel = () => {
		setIsDialogOpen(false);
		setCurrentItemId(null);
	};
	const handleApprove = () => {
		setIsDialogOpen(false);
		if (currentItemId) {
			dispatch(deleteAD(currentItemId));
		}
	};

	const changeNoticeDontShowOtherAds = (fieldName: string, value: number) => {
		dispatch(
			editPrice(notice.id ?? 0, fieldName, String(value), setNotShowOtherError)
		);
	};

	const imageUrl = notice?.noticeImages[0]?.imageUrl;
	const workType = notice.noticeType?.notice_properties?.find(prop => prop.specialViewOnFront === "work_search_propose")?.propertyValues;

	return (
		<>
			{isDialogOpen && (
				<div>
					<AskApprovePopup
						title={t("Ця дія видалить оголошення назавжди")}
						handleClickNo={handleCancel}
						handleClickYes={handleApprove}
					/>
				</div>
			)}
			<div className={s.adsCardContainer}>
				<div className={s.adsCardContainer_left}>
					<div className={s.left_image}>
						<Link
							className={s.left_image_Image}
							to={
								type === 30
									? `/${languagePath}o/${
											noticeUrl ? noticeUrl + "/" : ""
										}${notice?.slug}`
									: ""
							}
						>
							{imageUrl ? (
								<img
									className={s.image}
									src={imageUrl}
									alt={noticeImageAlt(notice)}
									title={noticeImageAlt(notice)}
								/>
							) : workType ? (
								<WorkSkeletonComponent
									type={workType}
									category={notice.category_name}
									isRow
								/>
							) : (
								<img
									className={s.image}
									src={img}
									alt={noticeImageAlt(notice)}
									title={noticeImageAlt(notice)}
								/>
							)}
							
							{notice.top_at && testActualityService(+notice.top_at) && (
								<PersonalCabinetSvgSelector id={"top100"} />
							)}
						</Link>
						<div className={s.left_image_Date}>
							<span>
								{t("З")}:{" "}
								<SecondsToDate
									full={false}
									seconds={notice?.created_at || null}
								/>{" "}
								по:{" "}
								<SecondsToDate
									full={false}
									seconds={notice?.finished_at || null}
								/>
							</span>
						</div>
					</div>
					<div className={s.left_main}>
						<div className={s.left_main_Top}>
							<div className={s.main_TopTitle}>
								<Link
									to={
										type === 30
											? `/${languagePath}o/${
													noticeUrl ? noticeUrl + "/" : ""
												}${notice?.slug}`
											: ""
									}
								>
									<h4>
										{notice?.name || ""}
									</h4>
								</Link>
								<div className={s.left_main_Middle}>
									{type === 30 ? (
										<>
											{visibleAutoContinue && (
												<div className={s.autoRenewal}>
													<div className={s.autoRenewal_icon}>
														{notice?.auto_continue ? (
															<PersonalCabinetSvgSelector
																id={"autoRenewalGreen"}
															/>
														) : (
															<PersonalCabinetSvgSelector id={"autoRenewal"} />
														)}
													</div>
													<div className={s.autoRenewal_main}>
														{t("Автоподовження")}{" "}
														<span>({t("безплатно")})</span>
													</div>
													<div className={s.autoRenewal_switch}>
														<PlayPauseSwitch
															onSwitchChange={() =>
																handleAutoRenewal(
																	notice?.id || 0,
																	+notice.auto_continue === 0 ? 1 : 0
																)
															}
															vipChecked={+notice?.auto_continue === 1}
														/>
													</div>
												</div>
											)}
										</>
									) : (
										<div className={s.category}>
											{notice?.category_name || ""}
										</div>
									)}
									{notShowOtherError !== "" && (
										<div className={s.errorPrice}>{notShowOtherError}</div>
									)}
								</div>
							</div>

							<div className={s.main_TopTPrice}>
								<form
									className={s.priceSum}
									onSubmit={(event) => {
										event.preventDefault();
										dispatch(
											editPrice(
												notice?.id || 0,
												"price",
												priceValue,
												setPriceError
											)
										);
									}}
								>
									<div className={s.priceSumInput}>
										{+notice?.price_type !== 2 && (
											<input
												style={{
													border: type === 30 ? "" : "none",
													cursor: type === 30 ? "pointer" : "inherit",
												}}
												disabled={type !== 30 || +notice?.price_type === 2}
												type="text"
												value={priceValue}
												onChange={(event) => {
													setPriceValue(
														formattingPrice(event.currentTarget.value)
													);
												}}
											/>
										)}

										<div className={s.currencyPrice}>
											{+notice?.price_type === 2 ? t("Безкоштовно") : "грн"}
										</div>
									</div>
									{priceError !== "" && (
										<div className={s.errorPrice}>{priceError}</div>
									)}
									{+notice?.price_type !== 2 &&
										type === 30 &&
										+(priceValue.replace(/\s/g, "").replace(",", ".")) !==
										+(formattingPrice(notice.price).replace(/\s/g, "").replace(",", "."))
										&& (
											<button type={"submit"}>{t("Зберегти")}</button>
										)}
								</form>
								{/*<div className={s.priceCurrency}></div>*/}
							</div>
						</div>
						{type === 30 ? (
							<DontShowOtherAds
								setFieldValue={changeNoticeDontShowOtherAds}
								values={{
									noticeId: notice.id,
									not_show_other: notice.not_show_other,
								}}

							/>
						) : null}

						<div className={s.left_main_Bottom}>
							{type === 30 && (
								<div className={s.statistics}>
									<div className={s.statistics_statistics}>
										{/*<PersonalCabinetSvgSelector id={"statistics"}/>
                                    <span>Статистика</span>*/}
									</div>
									<div className={s.statistics_info}>
										{/*<div className={s.statistics_info_position}>
                                        <PersonalCabinetSvgSelector id={'position'}/>
                                        <span>562 из 9287</span>
                                    </div>*/}
										<div className={s.statistics_info_views}>
											<PersonalCabinetSvgSelector id={"viewsSee"} />
											<span>{notice?.views || ""}</span>
										</div>
										<div className={s.statistics_info_phoneViews}>
											<PersonalCabinetSvgSelector id={"phoneViews"} />
											<span>{notice?.phone_shows || "0"}</span>
										</div>
										{/*<div className={s.statistics_info_addFavorite}>
                                        <PersonalCabinetSvgSelector id={'statisticHeart'}/>
                                        <span>243</span>
                                    </div>*/}
									</div>
								</div>
							)}
							<div className={s.left_main_Bottom_Bottom}>
								<div className={s.main_Bottom_Position}>
									<span>
										{notice.short_settlement_name !== ""
											? notice.short_settlement_name
											: notice.settlement_name}
									</span>
								</div>
								<div className={s.main_Bottom_Options}>
									{type === 30 && (
										<div className={s.bottom_Options_Look}>
											<Link
												to={`/${languagePath}o/${
													noticeUrl ? noticeUrl + "/" : ""
												}${notice?.slug}`}
											>
												<button>{t("Подивитися")}</button>
											</Link>
										</div>
									)}
									<div className={s.bottom_Options_Redaction}>
										{type !== 10 && (
											<Link
												to={`/${languagePath}create_ad/${notice.tree[notice.tree.length -1].alias}/${notice.id}`}
											>
												<button>{t("Редагувати")}</button>
											</Link>
										)}
									</div>
									<div className={s.bottom_Options_DeActivate}>
										{type === 40 || type === 20 ? (
											<button
												onClick={() =>
													handleDeleteAD(
														notice?.id ? notice.id.toString() : null
													)
												}
											>
												{t("Видалити")}
											</button>
										) : (
											<button onClick={() => handleStatus(2)}>
												{t("Деактивувати")}
											</button>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={s.adsCardContainer_Right}>
					{type === 40 && (
						<div className={s.adsCardContainer_Right_noActive}>
							<span onClick={() => handleStatus(1)}>{t("Активувати")}</span>
						</div>
					)}
					{type === 10 && (
						<div className={s.adsCardContainer_Right_underReview}>
							<span>{t("На перевірці")}</span>
							<span>
								Дата:{" "}
								<SecondsToDate
									full={false}
									seconds={notice?.created_at || null}
								/>{" "}
								{createDate.getFullYear()}
							</span>
						</div>
					)}
					{type === 20 && (
						<div className={s.adsCardContainer_Right_underReview}>
							<div className={s.underReviewTop}>
								<span>{t("Причина відхилення")}:</span>
								<span>{notice?.moderation_description || ""}</span>
							</div>
							<span>
								Дата:
								<SecondsToDate
									full={false}
									seconds={notice?.updated_at || null}
								/>{" "}
								{createDate.getFullYear()}
							</span>
						</div>
					)}
					{type === 30 && (
						<div className={s.adsCardContainer_Right_Active}>
							{visibleAdsOption && (
								<MyAdsCardRightFuncPanel
									notice={notice}
									type={"desc"}
									setOpenAutoRenewalSettings={setOpenAutoRenewalSettings}
									setPitchToTopSettings={setPitchToTopSettings}
									setVisibleAdsOption={setVisibleAdsOption}
								/>
							)}
							{!visibleAdsOption && (
								<div className={s.right_ActiveRight}>
									<div
										className={s.advertiseButton}
										onMouseOver={() =>
											setVisionPopup(
												visionPopup === 0 ? visionPopup + 1 : visionPopup
											)
										}
										onClick={() => setVisibleAdsOption(true)}
									>
										{t("Рекламувати")}
									</div>
									{/*{(visionPopup === 1) &&
                                        <div className={s.hiddenInfo}>
                                            <div className={s.hiddenInfoText}>Для зручності рекламний кабінет поєднаний
                                                з оголошенням, Ви можете його згорнути або розгорнути
                                            </div>
                                            <div onClick={() => setVisionPopup(2)} className={s.hiddenInfoButton}>
                                                ok, зрозуміло
                                            </div>
                                        </div>}*/}
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default MyAdsCard;
