import {
	ActionType, Block,
	categoryDataType, CategoryWithTree,
	OneCategoryType,
} from "../types/reduxStoreTypes";
import { categoryAPI } from "../api/api";
import { Dispatch } from "redux";
import { OneRubricType, SomeCategoriesApi } from "../types/categoriesTypes";
import { setFiltersManyAction, setFiltersOneIsAction } from "./notice_Reducer";
import { setSliderPrice } from "./publicNotice_Reducer";
import { StateType } from "./redux-store";

const SET_ALL_CATEGORY_LIST = "bago/categoryReducer/SET_ALL_CATEGORY_LIST";
const SET_ALL_RUBRICS_LIST = "bago/categoryReducer/SET_ALL_RUBRICS_LIST";
const SET_IS_REDACTION_FILTER_IN_AD = "bago/categoryReducer/SET_IS_REDACTION_FILTER_IN_AD";
const SET_SOME_CATEGORY_LIST = "bago/categoryReducer/SET_SOME_CATEGORY_LIST";
const SET_FIRST_CATEGORY_LIST = "bago/categoryReducer/SET_FIRST_CATEGORY_LIST";
const SET_SECOND_CATEGORY_LIST =
	"bago/categoryReducer/SET_SECOND_CATEGORY_LIST";
const SET_THIRD_LEVEL_CATEGORIES =
	"bago/categoryReducer/SET_THIRD_LEVEL_CATEGORIES";
const SET_THOR_LEVEL_CATEGORIES =
	"bago/categoryReducer/SET_THOR_LEVEL_CATEGORIES";
const SET_CLEAR_THIRD_LEVEL_CATEGORIES =
	"bago/categoryReducer/SET_CLEAR_THIRD_LEVEL_CATEGORIES";
const SET_FILTERS_LIST = "bago/categoryReducer/SET_FILTERS_LIST";
const SET_DEPEND_FILTERS_LIST = "bago/categoryReducer/SET_DEPEND_FILTERS_LIST";
const SET_CLEAR_FILTERS_LIST = "bago/categoryReducer/SET_CLEAR_FILTERS_LIST";
const SET_FILTERS_VARIABLES = "bago/categoryReducer/SET_FILTERS_VARIABLES";
const SET_CATEGORY_VIEW = "bago/categoryReducer/SET_CATEGORY_VIEW";
const SET_THIRD_CATEGORY_VIEW = "bago/categoryReducer/SET_THIRD_CATEGORY_VIEW";

const SET_CATEGORY_DELIVERY = "bago/categoryReducer/SET_CATEGORY_DELIVERY";
const SET_CATEGORY_STATE = "bago/categoryReducer/SET_CATEGORY_STATE";
const SET_CATEGORY_PHOTO = "bago/categoryReducer/SET_CATEGORY_PHOTO";
const SET_QUANTITY_PHOTOS = "bago/categoryReducer/SET_QUANTITY_PHOTOS";
const SET_CATEGORY_DONT_SHOW_OTHER_ADS =
	"bago/categoryReducer/SET_CATEGORY_DONT_SHOW_OTHER_ADS";
const SET_CATEGORY_AUTO_CONTINUE =
	"bago/categoryReducer/SET_CATEGORY_AUTO_CONTINUE";
const SET_CATEGORY_ADD_FILTERES =
	"bago/categoryReducer/SET_CATEGORY_ADD_FILTERES";
const SET_LOADER_SHOW = "bago/categoryReducer/SET_LOADER_SHOW";

const SET_ERROR_SHOW = "bago/categoryReducer/SET_ERROR_SHOW";
const SET_NEAREST_LIST = "bago/categoryReducer/SET_NEAREST_LIST";

export type categoryStateType = {
	categoryData: categoryDataType;
};
const initialState: categoryDataType = {
	categories: [
		{
			value: "",
			label: "",
		},
	],
	someCategoryList: [],
	categoryFirstLevel: [],
	categorySecondLevel: [],
	categorySecondLevelMobile: [],
	allRubrics: {
		level1: [],
		level2: [],
		level3: [],
		level4: [],
	},
	thirdLevelCategories: [],
	thorLevelCategories: [],
	filters: [],
	dependFilters: null,
	view:/* {
		active: null,
		alias: "",
		created_at: null,
		filled: null,
		filter_link: null,
		has_children_active: null,
		icon: null,
		icon_in_menu: null,
		id: null,
		is_last_level: null,
		level: null,
		link_cat_id: null,
		name: "",
		notices: null,
		parent_id: null,
		plus_18: 0,
		position: null,
		seo_title: "",
		seo_text: "",
		seo_description: "",
		updated_at: null,
		views: null,
		links_data: {
			realCatAlias: "",
			realCatId: "",
			tree: [],
		},
		tree: [],
		type: null,
	}*/ null,
	filtersVariables: {},
	blockDelivery: true,
	blockState: true,
	blockPhoto: true,
	blockDontShowOtherAds: true,
	blockAutoContinue: true,
	blockAddFilters: true,
	showLoader: false,
	showError: false,
	thirdCategoryView: null,
	isRedactionFilterAd:false,
	quantityPhotosData:null,
	nearestList:null,
};

const category_Reducer = (state = initialState, action: ActionType) => {
	switch (action.type) {
		case SET_ALL_CATEGORY_LIST:
			const categoryList = [];
			for (const key in action.data) {
				categoryList.push({ value: key, label: action?.data[key] });
			}
			return {
				...state,
				categories: categoryList,
			};
		case SET_SOME_CATEGORY_LIST:
			return {
				...state,
				someCategoryList: action.data,
			};
		case SET_FIRST_CATEGORY_LIST:
			return {
				...state,
				categoryFirstLevel: action.data,
			};
		case SET_SECOND_CATEGORY_LIST:
			let chunkedArray = [];
			const chunkArray = (array: OneCategoryType[], chunkSize: number): any => {
				return Array.from(
					{ length: Math.ceil(array.length / chunkSize) },
					(_, index) =>
						array.slice(index * chunkSize, index * chunkSize + chunkSize)
				);
			};
			// const filteredCategoriesListBy18Plus = action.data.filter((item: OneCategoryType) => item.plus_18 === 0)
			chunkedArray = chunkArray(action.data, 9); //.filter((i:OneCategoryType)=>i.plus_18 !== 0);
			return {
				...state,
				categorySecondLevel: chunkedArray,
				categorySecondLevelMobile: action.data,
			};

		case SET_FILTERS_LIST:
			return {
				...state,
				filters: action.data,
			};
		case SET_DEPEND_FILTERS_LIST:
			return {
				...state,
				dependFilters: action.data,
			};
		case SET_CLEAR_FILTERS_LIST:
			return {
				...state,
				filters: [],
			};
		case SET_CATEGORY_VIEW:
			return {
				...state,
				view: action.data,
			};
		case SET_ALL_RUBRICS_LIST:
			return {
				...state,
				allRubrics: action.data,
			};
		case SET_THIRD_LEVEL_CATEGORIES:
			const rubrics3 = state.allRubrics.level3.filter((r: OneRubricType) => {
				if (r.parent_id) {
					return +r.parent_id === +action.id;
				}
			});
			return {
				...state,
				thirdLevelCategories: rubrics3,
			};
		case SET_CLEAR_THIRD_LEVEL_CATEGORIES:
			return {
				...state,
				thirdLevelCategories: [],
			};
		case SET_THOR_LEVEL_CATEGORIES:
			const rubrics4 = state.allRubrics.level4.filter((r: OneRubricType) => {
				if (r.parent_id) {
					return +r.parent_id === +action.id;
				}
			});
			return {
				...state,
				thorLevelCategories: rubrics4,
			};

		case SET_FILTERS_VARIABLES:
			return {
				...state,
				filtersVariables: action.data,
			};
		case SET_CATEGORY_DELIVERY:
			return {
				...state,
				blockDelivery: action.bool,
			};
		case SET_CATEGORY_STATE:
			return {
				...state,
				blockState: action.bool,
			};
		case SET_CATEGORY_PHOTO:
			return {
				...state,
				blockPhoto: action.bool,
			};
		case SET_CATEGORY_DONT_SHOW_OTHER_ADS:
			return {
				...state,
				blockDontShowOtherAds: action.bool,
			};
		case SET_CATEGORY_AUTO_CONTINUE:
			return {
				...state,
				blockAutoContinue: action.bool,
			};
		case SET_CATEGORY_ADD_FILTERES:
			return {
				...state,
				blockAddFilters: action.bool,
			};
		case SET_LOADER_SHOW:
			return {
				...state,
				showLoader: action.bool,
			};
		case SET_ERROR_SHOW:
			return {
				...state,
				showError: action.bool,
			};
		case SET_THIRD_CATEGORY_VIEW:
			return {
				...state,
				thirdCategoryView: action.data,
			};
		case SET_QUANTITY_PHOTOS:
			return {
				...state,
				quantityPhotosData: action.data,
			};
		case SET_IS_REDACTION_FILTER_IN_AD:
			return {
				...state,
				isRedactionFilterAd: action.bool,
			};
		case SET_NEAREST_LIST:
			return {
				...state,
				nearestList: action.data,
			};
		default:
			return state;
	}
};

/*export const setAllCategoryList = (data: any) => ({
	type: SET_ALL_CATEGORY_LIST,
	data,
});*/
export const setAllRubricsList = (data: any) => ({
	type: SET_ALL_RUBRICS_LIST,
	data,
});

export const setFiltersList = (data: any) => ({ type: SET_FILTERS_LIST, data });
export const setDependFiltersList = (data: any) => ({
	type: SET_DEPEND_FILTERS_LIST,
	data,
});
export const setClearFiltersList = () => ({ type: SET_CLEAR_FILTERS_LIST });
export const setFiltersVariables = (data: any) => ({
	type: SET_FILTERS_VARIABLES,
	data,
});
export const setCategoryView = (data: any) => ({
	type: SET_CATEGORY_VIEW,
	data,
});
export const setViewThirdCategory = (data: any) => ({
	type: SET_THIRD_CATEGORY_VIEW,
	data,
});

export const setSomeCategoryList = (data: any) => ({
	type: SET_SOME_CATEGORY_LIST,
	data,
});
export const setFirstCategoryList = (data: any) => ({
	type: SET_FIRST_CATEGORY_LIST,
	data,
});
export const setSecondCategoryList = (data: any) => ({
	type: SET_SECOND_CATEGORY_LIST,
	data,
});
export const setThirdCategoryList = (id: number | null) => ({
	type: SET_THIRD_LEVEL_CATEGORIES,
	id,
});
export const setThorCategoryList = (id: number | null) => ({
	type: SET_THOR_LEVEL_CATEGORIES,
	id,
});
export const setClearThirdCategoryList = () => ({
	type: SET_CLEAR_THIRD_LEVEL_CATEGORIES,
});

export const setCategoryDelivery = (bool: boolean) => ({
	type: SET_CATEGORY_DELIVERY,
	bool,
});
export const setCategoryState = (bool: boolean) => ({
	type: SET_CATEGORY_STATE,
	bool,
});
export const setCategoryDontShowOtherAds = (bool: boolean) => ({
	type: SET_CATEGORY_DONT_SHOW_OTHER_ADS,
	bool,
});
export const setCategoryAutoContinue = (bool: boolean) => ({
	type: SET_CATEGORY_AUTO_CONTINUE,
	bool,
});
export const setCategoryAddFilters = (bool: boolean) => ({
	type: SET_CATEGORY_ADD_FILTERES,
	bool,
});
export const setQuantityPhotos = (data:Block | null) => ({
	type: SET_QUANTITY_PHOTOS,
	data,
});
export const setLoaderShow = (bool: boolean) => ({
	type: SET_LOADER_SHOW,
	bool,
});
export const setErrorShow = (bool: boolean) => ({ type: SET_ERROR_SHOW, bool });
export const setIsRedactionFilterAd = (bool: boolean) => ({ type: SET_IS_REDACTION_FILTER_IN_AD, bool });
export const setNearestList = (data:CategoryWithTree[]) => ({ type: SET_NEAREST_LIST, data });

/*export const getAllCategoryList = () => async (dispatch: Dispatch) => {
	try {
		const response: any = await categoryAPI.getAllCategories();
		if (response.data?.categories) {
			dispatch(setAllCategoryList(response.data?.categories));
		}
	} catch (error: any) {
		console.log(error.message);
	}
};*/

export const getAllRubricsList =
	(withLinks: string) => async (dispatch: Dispatch) => {
		try {
			const response: any = await categoryAPI.getRubricsList(withLinks);
			if (response.data) {
				dispatch(setFiltersOneIsAction([]));
				dispatch(setFiltersManyAction([]));
				dispatch(setAllRubricsList(response.data[0]));
			}
		} catch (error: unknown) {
			dispatch(setErrorShow(true));
			/*console.log(error.message)*/
		}
	};
export const getFiltersList =
	(categoryId: number | null) => async (dispatch: Dispatch) => {
		dispatch(setLoaderShow(true));
		try {
			const response: any = await categoryAPI.getCategoryFilters(categoryId);
			if (response.data?.filters) {
				dispatch(setFiltersList(response.data?.filters));
			}
			dispatch(setLoaderShow(false));
		} catch (error: unknown) {
			dispatch(setErrorShow(true));
		}
	};

export const getNearestList =
	(categoryId: number) => async (dispatch: Dispatch) => {

		try {
			const response: any = await categoryAPI.getNearest(categoryId);
			if (response.data) {
				dispatch(setNearestList(response.data?.categories));
			}
		} catch (error:unknown ) {
			console.log(error);

		}
	};

export const getDependFiltersList =
	(
		categoryId: string,
		propertyValueId: number,
		property_id: number,
		allFilters: any[]
	) =>
	async (dispatch: Dispatch, getState: any) => {
		try {

			const state: StateType = getState();
			//const { filtersOneIs, filtersMany } = state.noticeData;
			//const allChangedFilters = [...filtersOneIs, ...filtersMany];
			const dependFilterInState = state.categoryData.dependFilters;

			const accFiltersList = [...allFilters, ...dependFilterInState ?? []];
			const isMainFilterSelected = accFiltersList.some(
				(filter: any) => filter.property_id === property_id
			);
			// Функция для фильтрации зависимых фильтров, связанных с конкретным property_id
			const filterOutSpecificDependents = (
				filters: any[],
				property_id: number
			) => filters.filter((filter: any) => filter.parent_id !== property_id);

			// Удаляем зависимые фильтры, которые зависят от текущего property_id
			let updatedFilters = filterOutSpecificDependents(accFiltersList, property_id);

			// Если основной фильтр выбран, добавляем зависимые фильтры из API
			if (isMainFilterSelected) {

				const response = await categoryAPI.getCategoryDependFilters(
					+categoryId,
					propertyValueId
				);
				const dependFilters = response.data.filters;

				const uniqueDependFilters = dependFilters.filter(
					(dependFilter: any) =>
						dependFilterInState &&
						!dependFilterInState.some(
							(stateFilter: any) => stateFilter.id === dependFilter.id
						)
				);

				// Добавляем уникальные элементы из dependFilters в dependFilterInState
				const updatedDependFilters = [
					...(dependFilterInState ?? []),
					...uniqueDependFilters,
				];

				// Обновляем состояние с новым списком фильтров
			 	dispatch(setFiltersList(updatedDependFilters));

				// Добавляем зависимые фильтры сразу после основного фильтра
				updatedFilters = updatedFilters.reduce((acc: any[], filter: any) => {
					acc.push(filter);

					if (filter.property_id === property_id) {
						dependFilters.forEach((dependFilter: any) => {
							acc.push({
								...dependFilter,
								parent_id: property_id,
							});
						});
					}

					return acc;
				}, []);
			}
			// Обновляем состояние с новым списком фильтров
			dispatch(setFiltersList(updatedFilters));
		} catch (error: unknown) {
			dispatch(setErrorShow(true));
		}
	};

export const getDependSearchFilters = (categoryId: number,propertyValueId:number,name:string | null) => async (dispatch: Dispatch,getState:any) => {
	try {
		const response: any = await categoryAPI.getCategoryDependFilters(categoryId, propertyValueId);
		if (response.data) {
			const state: StateType = getState();
			const dependFiltersInState = state.categoryData.dependFilters || [];
			const dependFilters = response.data.filters;
			const dependFilter =dependFilters.length > 0 && dependFilters[0];

			const updatedDependFilter = {
					...dependFilter,
					parent_name:name
			}

			const allDependFilters = [...dependFiltersInState,updatedDependFilter];
			// Проверка на уникальность: исключаем уже существующие фильтры
			const uniqueDependFilters = allDependFilters.filter((filter: any) =>
				!dependFiltersInState.some((stateFilter: any) => stateFilter.id === filter.propertyValueId)
			);
			dispatch(setDependFiltersList(uniqueDependFilters));
		}
	} catch (error: unknown) {
		console.log("Error getDependSearchFilters");
	}
}
export const getCategoryBlocks =
	(categoryId: number | null) => async (dispatch: Dispatch) => {
		try {
			const response: any = await categoryAPI.getCategoryBlocks(categoryId);
			if (response.data?.blocs) {
				const result:Block[] = response.data?.blocs;
				const quantityPhotosBlock = result.find((b: Block) => b.bloc_id === 12)
				if (quantityPhotosBlock) {
					dispatch(setQuantityPhotos(quantityPhotosBlock))
				}
				const uniqueBlocksId: number[] = [];
				result.forEach((item: any) => {
					uniqueBlocksId.push(item?.bloc_id);
				});
				uniqueBlocksId.includes(16)
					? dispatch(setCategoryState(true))
					: dispatch(setCategoryState(false));
				uniqueBlocksId.includes(17)
					? dispatch(setCategoryDelivery(true))
					: dispatch(setCategoryDelivery(false));
				uniqueBlocksId.includes(21)
					? dispatch(setCategoryDontShowOtherAds(true))
					: dispatch(setCategoryDontShowOtherAds(false));
				uniqueBlocksId.includes(24)
					? dispatch(setCategoryAutoContinue(true))
					: dispatch(setCategoryAutoContinue(false));
				uniqueBlocksId.includes(22)
					? dispatch(setCategoryAddFilters(true))
					: dispatch(setCategoryAddFilters(false));
			}
			return response?.data?.blocs;
		} catch (error: unknown) {
			dispatch(setErrorShow(true));
		}
	};

export const getSomeCategory =
	(props: SomeCategoriesApi, level: 1 | 2) => async (dispatch: Dispatch) => {
		dispatch(setLoaderShow(true));
		try {
			const response: any = await categoryAPI.getSomeCategories(props);
			if (response.data?.items) {
				if (level === 1) {
					dispatch(setCategoryView({}));
					dispatch(setSecondCategoryList([]));
					dispatch(setFirstCategoryList(response.data.items));
				} else if (level === 2) {
					dispatch(setSecondCategoryList(response.data.items));
				}
				dispatch(setSomeCategoryList(response.data?.items));
			}
			dispatch(setLoaderShow(false));
		} catch (error: unknown) {
			dispatch(setErrorShow(true));
		}
	};
export const getFirstLevelCategory = () => async (dispatch: Dispatch) => {
	dispatch(setLoaderShow(true));
	try {
		const response: any = await categoryAPI.getSomeCategories({
			level: "1",
			active: "1",
			perPage: "27",
		});
		if (response.data?.items) {
			dispatch(setFirstCategoryList(response.data.items));
		}
		dispatch(setLoaderShow(false));
	} catch (error: unknown) {
		dispatch(setErrorShow(true));
	}
};
export const getCategoryView =
	(categoryId?: string | number, slug?: string, expand?: string | string[]) =>
	async (dispatch: Dispatch) => {
		dispatch(setLoaderShow(true));
		try {
			let response: any = { data: {} };
			if (categoryId) {
				response = await categoryAPI.getCategoryView(+categoryId, expand);
			} else if (slug) {
				response = await categoryAPI.getCategoryViewBySlug(slug, expand);
			}
			if (response.data?.category) {
				dispatch(setCategoryView(response.data?.category));
				dispatch(setSliderPrice(response.data?.category.notice_price_diapason));
			}
			dispatch(setLoaderShow(false));
		} catch (error: unknown) {
			dispatch(setErrorShow(true));
			/*console.log(error.message)*/
		}
	};
export const getViewThirdCategory =
	(categoryId?: string, slug?: string, expand?: string) =>
	async (dispatch: Dispatch) => {
		try {
			let response: any = { data: {} };
			if (categoryId) {
				response = await categoryAPI.getCategoryView(+categoryId, expand);
			} else if (slug) {
				response = await categoryAPI.getCategoryViewBySlug(slug, expand);
			}
			if (response.data?.category) {
				dispatch(setViewThirdCategory(response.data?.category));
			}
		} catch (error: unknown) {
			dispatch(setErrorShow(true));
			/*console.log(error.message)*/
		}
	};
/*export const getCategoryForSelectorView =
	(slug: string, expand: string, navigate: NavigateFunction) =>
	async (dispatch: Dispatch) => {
		dispatch(setLoaderShow(true));
		try {
			const response: any = await categoryAPI.getCategoryViewBySlug(
				slug,
				expand
			);
			if (response.data?.category) {
				const tree = response.data?.category?.links_data?.tree || [];
				const noticeUrl = tree?.map((s: string) => s).join("/");
				navigate(noticeUrl);
			}
			dispatch(setLoaderShow(false));
		} catch (error: any) {
			navigate("/error");
			dispatch(setErrorShow(true));
			console.log(error.message);
		}
	};*/

export default category_Reducer;
