import React, { useState } from "react";
import style from "./SearchFilters.module.css";
import s from "../../pages/SearchResults/SearchResult.module.css";
import { useTranslation } from "react-i18next";
import { Filter, FiltersValue } from "../../types/publicNoticeTypes";
import Slider from "rc-slider";
import FilterFrontHead from "./FilterFrontHead/FilterFrontHead";

type Props = {
	filter: Filter;
	setFiltersValues: any;
	filtersValues: FiltersValue[];
	showOpened: number;
};
const FilterFrontRange = ({
	filter,
	filtersValues,
	setFiltersValues,
	showOpened,
}: Props) => {
	const { t } = useTranslation();
	const [isExpanded, setExpanded] = useState(showOpened === 1);

	let defaultRange:any
	let numberRange:number[]
	if(filter.propertyType !== 4){
		defaultRange =Object.values(filter?.minMax);
		numberRange = defaultRange.map(Number);
	} else {
		defaultRange = filter?.minMax ? Object.values(filter.minMax) : [];
		numberRange = defaultRange.flatMap((item:any) => [
			Number(item.min),
			Number(item.max)
		])
	}
	//const defaultRange = Object.values(filter?.minMax);
	//const numberRange = defaultRange.map(Number);

	/*const defaultRange = filter?.minMax ? Object.values(filter.minMax) : [];
	const numberRange = defaultRange.flatMap((item:any) => [
		Number(item.min),
		Number(item.max)
	]);*/

	const decodedFilters = filtersValues.map((item) => {
		if (item.filter === "filtersRange[]") {
			const decodedString = decodeURIComponent(
				item.value.replace(/%(?![0-9A-Fa-f]{2})/g, "%25")
			);
			// Преобразуем строку обратно в объект
			const decodedObject = JSON.parse(decodedString);
			return { filter: item.filter, value: decodedObject };
		} else {
			return { filter: null, value: null };
		}
	});
	const initialValues = decodedFilters
		.filter((dF) => dF.value?.filter_id === filter.id)
		.map((dF) => {
			return [+dF.value?.min, +dF.value?.max];
		});
	const [range, setRange] = useState<number[] | []>(
		initialValues[0] ? initialValues[0] : numberRange
	);
	const handleClick = (range: number[]) => {
		const filterRange: Record<string, string | number> = {
			filter_id: filter.id,
			min: range[0],
			max: range[1],
		};
		const encodedString = encodeURIComponent(
			JSON.stringify(filterRange)
		).replace(
			/['"{}]/g,
			(match) => "%" + match.charCodeAt(0).toString(16).toUpperCase()
		);
		const existingIndex = decodedFilters?.findIndex(
			(item: any) => item?.value?.filter_id === filter.id
		);
		const newValue = {
			filter: `filtersRange[]`,
			value: encodedString,
		};
		if (existingIndex === -1) {
			// Если объект с совпадающим filter_id не найден, добавляем новый
			setFiltersValues((prevValues: any) => [...prevValues, newValue]);
		} else {
			// Если объект с совпадающим filter_id найден, обновляем значение
			setFiltersValues((prevValues: any) => {
				const updatedValues = [...prevValues];
				updatedValues[existingIndex] = newValue;
				return updatedValues;
			});
		}
	};
	const handleChangeInput = (
		event: React.ChangeEvent<HTMLInputElement>,
		priceType: string
	) => {
		const value = +event.currentTarget.value;
		if (value >= 0 && value <= +defaultRange[1]) {
			priceType === "min"
				? setRange([value, range[1]])
				: setRange([range[0], value]);
		} else {
			priceType === "min"
				? setRange([0, range[1]])
				: setRange([range[0], numberRange[1]]);
		}
		if (filter?.showRunner !== 1) {
			priceType === "min"
				? handleClick([value, range[1]])
				: handleClick([range[0], value]);
		}
	};

	const clearFilter = () => {
		setFiltersValues();
	};

	return (
		<div
			className={style.filterContainer}
			style={{
				background: isExpanded ? "#F8F8F8" : "",
			}}
		>
			<FilterFrontHead
				name={filter.propertyName}
				isExpanded={isExpanded}
				setExpanded={setExpanded}
				clearFilter={clearFilter}
			/>
			{isExpanded && (
				<div className={style.priceFilterParams}>
					<div className={style.infoSliderPanel}>
						<span>{t("від")}</span>
						<input
							onChangeCapture={(event) => {
								setRange([+event.currentTarget.value, range[1]]);
							}}
							value={range[0]}
							onChange={(event) => {
								handleChangeInput(event, "min");
							}}
						/>
						<span>{t("до")}</span>
						<input
							onChangeCapture={(event) => {
								setRange([range[0], +event.currentTarget.value]);
							}}
							onChange={(event) => {
								handleChangeInput(event, "max");
							}}
							className={s.out}
							value={range[1]}
							min={"1"}
						/>
						{filter?.showRunner === 1 ? (
							<button onClick={() => handleClick(range)}>ok</button>
						) : (
							<div />
						)}
					</div>
					{filter?.showRunner === 1 && (
						<div className={style.filterSlider}>
							<Slider
								draggableTrack={true}
								className={style.sliderPrice}
								range
								value={range}
								onChange={(value) => {
									if (Array.isArray(value)) {
										setRange(value);
									}
								}}
								allowCross={false}
								min={+filter.minMax.min}
								max={+filter.minMax.max}
								step={10}
								trackStyle={[
									{
										height: "3px !Important",
										backgroundColor: "#00BCE5",
									},
								]}
								railStyle={{
									background: "#E0E0E0",
									height: "3px !Important",
								}}
								handleStyle={{
									width: "15px",
									height: "15px",
									marginTop: "-6px",
									backgroundColor: "white",
									cursor: "pointer",
									outline: "1px solid #00BCE6",
									border: "3px solid #fff",
									opacity: 1,
								}}
							/>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default FilterFrontRange;
