import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
	Link,
	useLocation,
	useNavigate,
	useSearchParams,
} from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";

import {
	getSearchCategories,
	setSearchingNoticeName,
} from "../../redux/search_Reducer";
import { Mobile } from "../../responsive";
import { stateUserType } from "../../types/reduxStoreTypes";
import { userTypes } from "../../types/userTypes";
import MainSearch from "../MainSearch/MainSearch";
import NavBarSvgSelector from "../NavBar/NavBarSvgSelector";
import s from "./HeaderMobile.module.css";
import HeaderSvg from "./HeaderSvg";
import { InputComponent } from "./Input";

const HeaderMobile = () => {
	const userView: userTypes = useSelector((state: any) => state.userData.user);
	const auth = useSelector((state: stateUserType) => state.userData.isAuth);
	const { t, i18n } = useTranslation();
	const dispatch: any = useDispatch();
	const getSearch = useSelector(() => getSearchCategories);
	const setSearchName = useSelector(() => setSearchingNoticeName);
	const navigate = useNavigate();
	const [params] = useSearchParams();
	const searchQuery = params.get("search");
	const [currentValue, setCurrentValue] = useState(
		searchQuery ? searchQuery : ""
	);
	const [submit, setSubmit] = useState(false);
	const location = useLocation();
	const [isShowAvatar, setIsShowAvatar] = useState(false);
	const [openSearch, setOpenSearch] = useState<boolean>(false);
	const isAdsPage = location.pathname.includes("/o/");
	const [isVisible, setIsVisible] = useState(!isAdsPage);

	const debounced = useDebouncedCallback((value) => {
		dispatch(getSearch(value));
	}, 300);

	useEffect(() => {
		setCurrentValue(searchQuery ? searchQuery : "");
	}, [searchQuery]);

	useEffect(() => {
		if (submit) {
			navigate(
				`${i18n.language === "uk" ? "/ua" : ""}/search_result?search=${
					currentValue.length !== 0 ? currentValue : ""
				}`
			);
			setSubmit(false);
		}
	}, [submit, navigate, i18n.language, currentValue]);

	const previousUrl = useRef(location.pathname + location.search);

	useEffect(() => {
		const currentUrl = location.pathname + location.search;
		if (previousUrl.current !== currentUrl) {
			setOpenSearch(false);
			previousUrl.current = currentUrl;
		}
	}, [location]);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setIsShowAvatar((prevShowAvatar) => !prevShowAvatar);
		}, 30000);

		return () => clearInterval(intervalId);
	}, []);

	useEffect(() => {
		if (isAdsPage) {
			setIsVisible(false);
			let lastScrollY = window.scrollY;

			const handleScroll = () => {
				const currentScrollY = window.scrollY;

				if (currentScrollY === 0) {
					setIsVisible(false);
				} else if (currentScrollY < lastScrollY) {
					setIsVisible(true);
				} else {
					setIsVisible(false);
				}

				lastScrollY = currentScrollY;
			};

			window.addEventListener("scroll", handleScroll);

			return () => {
				window.removeEventListener("scroll", handleScroll);
			};
		} else {
			setIsVisible(true);
		}
	}, [isAdsPage]);

	const handleChangeInput = (event: any) => {
		setCurrentValue(event?.target.value);
		if (event?.target.value.length >= 3) {
			debounced(event?.target.value);
		}
	};

	const handleFocusInput = () => {
		setOpenSearch(true);
		// Додаткові дії при фокусі
	};

	const handleSendResult = () => {
		dispatch(setSearchName(currentValue));
		setSubmit(true);
	};

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === "Enter") {
			handleSendResult();
		}
	};

	const handleClickInput = () => {
		setOpenSearch(true);
	};

	const handleNavigate = () => {
		if (auth) {
			if (isShowAvatar) {
				navigate("/personal_cabinet");
			} else {
				navigate(i18n.language === "uk" ? "/ua" : "");
			}
		} else {
			navigate(i18n.language === "uk" ? "/ua" : "");
		}
	};

	return (
		<>
			<div
				className={`${s.mobileHeaderWrap} mui-fixed ${
					isAdsPage ? (isVisible ? s.visible : s.hidden) : s.visible
				}`}
			>
				<div className={s.mobileHeaderContainer}>
					<InputComponent
						placeholder={t("Шукати на BAGO")}
						onClick={handleClickInput}
						value={currentValue}
						onChange={handleChangeInput}
						onFocus={handleFocusInput}
						onKeyDown={handleKeyDown}
					/>

					<div className={s.mobileHeaderIconblock} onClick={handleNavigate}>
						{auth ? (
							<>
								{isShowAvatar ? (
									userView?.photo?.photoUrl ? (
										<div className={s.mobileHeaderAvatarBlock}>
											<img
												alt="avatar"
												src={userView.photo.photoUrl}
												className={s.mobileHeaderAvatar}
											/>
										</div>
									) : (
										<div className={s.mobileHeaderAvatarBlock}>
											<NavBarSvgSelector id="profile" />
										</div>
									)
								) : (
									<Link to="/">
										<HeaderSvg id="logoBagoMobile" />
									</Link>
								)}
							</>
						) : (
							<Link to="/">
								<HeaderSvg id="logoBagoMobile" />
							</Link>
						)}
					</div>
				</div>
			</div>
			<Mobile>
				{openSearch && <MainSearch open={openSearch} setOpen={setOpenSearch} />}
			</Mobile>
		</>
	);
};

export default HeaderMobile;
