import React, {
	Fragment,
	useEffect,
	useRef,
	useState,
} from "react";
import s from "../AutomaticSelectionOfCategories/AutomaticSelectionOfCategories.module.css";
import TopTitleBlockSvg from "../TopTitleBlockMobileAllPages/TopTitleBlockSvg";
import AutomaticSelectionOfCategoriesLevelThreeAndFour from "../AutomaticSelectionOfCategories/AutomaticSelectionOfCategoriesLevelThreeAndFour";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "../../redux/redux-store";
import {
	setClearThirdCategoryList,
	setThirdCategoryList,
	setThorCategoryList,
} from "../../redux/category_Reducer";
import { setBooleanAutoChoosing } from "../../redux/autoSelection_Reducer";
import { OneRubricType, RubricTypeOnBoard } from "../../types/categoriesTypes";
import { useGroupedCategories } from "../../CustomHooks/useGroupedCategories";


interface Props {
	handleChoseCategory:(r:RubricTypeOnBoard) => void;
}

const CategorySelectorDesktop: React.FC<Props> = ({
	handleChoseCategory
}) => {
	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const setBooleanAutoChoosingStore = useSelector(() => setBooleanAutoChoosing);
	const arrayRubrics = useSelector(
		(state: StateType) => state.categoryData.allRubrics
	);
	const [firstStepElements, setFirstStepElements] = useState([]);
	const [currentCategoryInfo, setCurrentCategoryInfo] = useState<any>({
		name: "",
		alias: "",
		id: null,
	});
	const [currentIdx, setCurrentIdx] = useState(0);
	const thirdStepElements = useSelector(
		(state: StateType) => state.categoryData.thirdLevelCategories
	);
	const [slugState, setSlugState] = useState("");
	const selectionCategoryRef = useRef<HTMLDivElement | null>(null);

	const scrollToElement = () => {
		if (selectionCategoryRef.current) {
			selectionCategoryRef.current.scrollIntoView({ behavior: "smooth" });
		}
	};
	useEffect(() => {
		scrollToElement();
	}, [slugState]);

	const handleClickCategory = (id: number | null, name: string, alias: string) => {
		setCurrentCategoryInfo({ name, alias, id });
		dispatch(setThirdCategoryList(id));
		dispatch(setThorCategoryList(null));
	};
	const handleClick = (rubric: RubricTypeOnBoard,index?:number) => {
		 index && setCurrentIdx(index);
			setSlugState(rubric?.alias);
		if (rubric?.isForNotice === "1" || rubric?.isForNotice === true) {
			handleChoseCategory(rubric)
		} else {
			dispatch(setBooleanAutoChoosingStore(false));
			handleClickCategory(+rubric.id, rubric.name, rubric.alias);
		}
	};
	const handleFirstStep = (rubric: OneRubricType) => {
		const data = arrayRubrics.level2.filter(
			(r2: OneRubricType) => r2.parent_id === rubric?.id
		);
		setFirstStepElements(data);
	};

	const handleClickArrowBack = () => {
		dispatch(setClearThirdCategoryList());
		setSlugState("");
	};

	const groupedCategories = useGroupedCategories(firstStepElements);

	return (
		<div className={s.modalContainerContentBottom}>
			<div className={s.modalContainerContentBottom_title}>
				{slugState && (
					<div
						onClick={handleClickArrowBack}
						className={s.modalContainerContentBottom_arrowBack}
					>
						<TopTitleBlockSvg id="arrowBack" />
					</div>
				)}
				<p>{ t(`Оберіть рубрику`)}</p>
			</div>
			<div>
				<div className={s.boardWrapper}>
					{slugState ? (
						<div className={s.boardContainer} ref={selectionCategoryRef}>
							<div className={s.boardContainerRow}>
								{groupedCategories?.map((arr: any, index: number) => (
									<Fragment key={index}>
										{arr.map((item: any) => (
											<div key={item.id} className={s.catalogItem}>
												<div
													onClick={() =>handleClick(item,index)}
												>
													<div className={s.imageContainer}>
														<div className={s.imageContainerFon} />
														<img
															src={item?.iconUrl || ""}
															alt={item?.name || ""}
															title={item?.name || ""}
															className={s.squarePhoto}
														/>
													</div>
													<p>{item?.name || ""}</p>
												</div>
											</div>
										))}
										{currentIdx == index && (
											<div style={{ width: "100%" }}>
												{!!thirdStepElements.length && (
													<AutomaticSelectionOfCategoriesLevelThreeAndFour
														handleClick={handleClick}
														currentCategoryInfo={currentCategoryInfo}
														thirdStepElements={thirdStepElements}
													/>
												)}
											</div>
										)}
									</Fragment>
								))}
							</div>
						</div>
					) : (
						<div className={s.boardContainer}>
							<div className={s.boardContainerRow}>
								{arrayRubrics?.level1?.map((item: OneRubricType) => {
									return (
										<div
											key={item.id}
											className={s.catalogItem}
											onClick={() => {
												setSlugState(item?.alias);
												handleFirstStep(item);
											}}
										>
											<div className={s.imageContainer}>
												<div className={s.imageContainerFon} />
												<img
													src={item?.iconUrl || ""}
													alt={item?.name || ""}
													title={item?.name || ""}
													className={s.roundPhoto}
												/>
											</div>
											<p>{item?.name || ""}</p>
										</div>
									);
								})}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default CategorySelectorDesktop;
