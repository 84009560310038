import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import style from "../../../GlobalStyles.module.css";
import {
	getAllRubricsList,
	getViewThirdCategory,
	setCategoryView,
	setThirdCategoryList,
	setThorCategoryList,
} from "../../../redux/category_Reducer";
import { StateType } from "../../../redux/redux-store";
import { OneCategoryType } from "../../../types/reduxStoreTypes";
import BreadCrumbs from "../../BreadCrumbs/BreadCrumbs";
import PreLoaderComponent from "../../../common/Animations/PreLoaderComponent/PreLoaderComponent";
import CategoriesThirdThorStep from "./CategoriesThirdThorStep";
import s from "./CategoryBoardFirstLevel.module.css";

type HandleClickProps = {
	filter_link: string | null;
	link_cat_id: string | null;
	links_data: {
		realCatAlias: string;
		realCatId: string;
		tree: string[];
	} | null;
	has_children_active: number | null;
	id: number | null;
	is_last_level: number | null;
	alias: string;
	name: string;
};
type Props = {
	categoriesList: OneCategoryType[];
	currentCategory: OneCategoryType;
	prevCategoryName: string | null;
};
const CategoryBoardSecondLevel = ({
	currentCategory,
	categoriesList,
	prevCategoryName,
}: Props) => {
	const { i18n } = useTranslation();
	const dispatch: any = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const [breadCrumbsLinks, setBreadCrumbsLinks] = useState<any[]>([]);
	const getAllCategories = useSelector(() => getAllRubricsList);
	const getThirdCategory = useSelector(() => getViewThirdCategory);
	const [category3levelPath, setCategory3levelPath] = useState<any>("");
	const [thirdCategoryAlias, setThirdCategoryAlias] = useState("");
	const thirdStepElements = useSelector(
		(state: StateType) => state.categoryData.thirdLevelCategories
	);
	const thirdCategory = useSelector(
		(state: StateType) => state.categoryData.thirdCategoryView
	);
	const loader = useSelector((state: StateType) => state.pagesData.showLoader);
	const isUserAdult = useSelector(
		(state: StateType) => state.userData.isUserAdult
	);


	useEffect(() => {
		setBreadCrumbsLinks([
			{
				name: currentCategory.name,
				alias: `${currentCategory.alias}`,
				active: true,
			},
		]);
		const parts = location.pathname.split("/");
		// Ищем индекс метки 'c2'
		const indexC2 = parts.indexOf("c2");
		if (indexC2 !== -1 && indexC2 + 1 < parts.length) {
			setCategory3levelPath(parts[indexC2 + 1]);
			dispatch(getThirdCategory("", parts[indexC2 + 1]));
			if (thirdCategory?.id) {
				dispatch(setThirdCategoryList(thirdCategory?.id ?? null));
				setBreadCrumbsLinks([
					{
						name: currentCategory.name,
						alias: `${currentCategory.alias}`,
						active: false,
					},
					{
						name: thirdCategory?.name ?? "",
						alias: thirdCategory?.alias ?? "",
						active: true,
					},
				]);
			}
		}
	}, [location.pathname, thirdCategory?.id, currentCategory.alias]);

	useEffect(() => {
		dispatch(getAllCategories("1"));
	}, [i18n.language]);

	const handleClickElement = (item: HandleClickProps) => {

		if (item.filter_link !== null) {
			window.open(item.filter_link, "_blank");
		} else if (item.link_cat_id !== null && item.links_data) {
			const updateTree = item.links_data.tree.slice(1);
			handleRedirectToParents(updateTree);
		} else if (item.has_children_active !== 0 && item.is_last_level === 0) {
			handleClickCategory(item.id, item.alias);
		} else {
			const alias = item.alias;
			const language = i18n.language === "ru" ? "" : "/ua";
			navigate(
				`${language}/c/${currentCategory?.alias}/c2${
					Boolean(thirdCategory) ? "/" + thirdCategory?.alias : "/" + alias
				}${
					thirdCategoryAlias !== ""
						? "/" + thirdCategoryAlias + "/" + alias
						: "/" + alias
				}`
			);
			dispatch(setCategoryView({}));
		}
		//dispatch(setCategory({}))
	};

	const handleRedirectToParents = (tree: string[]) => {
		const noticeUrl = tree?.map((s: string) => s).join("/");
		navigate(`c2/${noticeUrl}`);
	};
	const handleClickCategory = (id: number | null, alias: string) => {
		dispatch(setThirdCategoryList(id));
		dispatch(setThorCategoryList(null));
		navigate(`c2/${alias}`);
	};

	return (
		<div className={`${s.boardCategoryWrapper}`}>
			<div className={`${s.boardCategoryContainer} __container`}>
				<BreadCrumbs links={breadCrumbsLinks} />
				<div className={s.boardCategory}>
					<div className={s.boardCategoryTitle}>
						<h1>
							{!loader && currentCategory.name
								? currentCategory.name
								: prevCategoryName}
						</h1>
					</div>
					{loader ? (
						<PreLoaderComponent type={"category"} size={"medium"} />
					) : (
						<>
							{categoriesList?.map((row: any, index: any) => {
								return (
									<div key={index}>
										<div className={s.catalogItemRow}>
											{row?.map((item: OneCategoryType) => {
												return (
													<div key={item.id}>
														<div
															className={s.catalogItem}
															onClick={() => {
																handleClickElement(item);
																/*if (item.plus_18 !== 1 || isUserAdult) {
																	//  dispatch(setCategory({}))
																	handleClickElement(item);
																}*/
															}}
														>
															<div
																className={`${s.imageContainerFon} ${s.mainCategory}`}
															/>
															<div className={s.imageContainer}>
																<img
																	src={item?.icon || ""}
																	alt={item?.name || ""}
																	title={item?.name || ""}
																	className={
																		item.plus_18 === 1 && !isUserAdult
																			? style.blurEffect
																			: ""
																	}
																/>
															</div>
															<div className={s.textNameRubrics}>
																<p>{item?.name || ""}</p>
															</div>
														</div>
													</div>
												);
											})}
										</div>
										{/*{row.some((obj: any) => obj.id === +currentCategoryInfo.id) &&*/}
										{row.some(
											(obj: any) => obj.alias === category3levelPath
										) && (
											<CategoriesThirdThorStep
												thirdCategory={thirdCategory}
												thirdStepElements={thirdStepElements}
												currentCategory={currentCategory}
												setThirdCategoryAlias={setThirdCategoryAlias}
												handleClickElement={handleClickElement}
											/>
										)}
									</div>
								);
							})}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default CategoryBoardSecondLevel;
