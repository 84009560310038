import React, { useEffect } from "react";
import s from "../InterlinkingMain.module.css";
import { Link } from "react-router-dom";
import { scrollToTop } from "../../../common/Logic/supporting";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "../../../redux/redux-store";
import {  getNearestList } from "../../../redux/category_Reducer";
import {  CategoryWithTree, Tree } from "../../../types/reduxStoreTypes";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";

const Neighbours = ({categoryId}:{categoryId:number | null}) => {
	const dispatch:any = useDispatch();
	const getList = useSelector(()=>getNearestList)
	const nearestList = useSelector((state:StateType)=>state.categoryData.nearestList)
	const location = useLocation();
	const {i18n } = useTranslation();
	const languagePath = i18n.language === "ru" ? "" : "ua/";

	useEffect(() => {
		if(categoryId){
			dispatch(getList(categoryId))
		}

	}, [categoryId,location.pathname]);

	const linkGenerator = (tree: Tree[]): string => {
		if (!tree.length) return `/${languagePath}c`; // Обработка пустого массива
		const categoriesSlugs = tree
			.map((item, index) => (index === 1 ? `c2/${item.alias}` : item.alias))
			.join("/");

		return `/${languagePath}c/${categoriesSlugs}`;
	};

	return (
		<ul className={s.linksContainer}>
			{nearestList?.map((item:CategoryWithTree) => (
				<li className={s.linkItem} key={`interlinkSettlements_${item.id}`}>
					<Link to={linkGenerator(item.tree)} onClick={() => scrollToTop()}>
						{ item.name }
					</Link>
				</li>
			))}
		</ul>
	);
};

export default Neighbours;