import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { publicNotice } from "../../api/api";
import { StateType } from "../../redux/redux-store";
import { Desktop, Mobile } from "../../responsive";
import { publicOneNotice } from "../../types/publicNoticeTypes";
import { stateUserType } from "../../types/reduxStoreTypes";
import NoRegisterAdCardAdaptive from "../AdCard/NoRegisterAdCardAdaptive";
import MobileProductCard from "../Cards/ProductCard/MobileProductCard";
import ProductCard from "../Cards/ProductCard/ProductCard";
import ExpandButton from "../ExpandButton/ExpandButton";
import ExpandModernButton from "../ExpandButton/ExpandModernButton";
import PreLoaderComponent from "../../common/Animations/PreLoaderComponent/PreLoaderComponent";
import s from "./Bestsellers.module.css";
import BestsellersTabs from "./BestsellersTabs";
import LocationSelectorBestsellers from "./LocationSelectorBestsellers";

const Bestsellers = () => {
	const userLocation = useSelector((state: StateType) => state.settlementData.settlementByLocation);
	const userDistrict = useSelector(
		(state: StateType) => state.settlementData.settlementByLocationOnlyDistrict
	);
	const auth = useSelector((state: stateUserType) => state.userData.isAuth);
	const currentCategory = useSelector((state: StateType) => state.categoryData.view);

	const queryClient = useQueryClient();
	const { i18n, t } = useTranslation();

	const [isExpanded, setExpanded] = useState(false);
	const [type, setType] = useState<number>(2);
	const [onlyTop, setOnlyTop] = useState(1);
	const [settlement, setSettlement] = useState(userLocation[0]?.value || "");
	const [areaId, setAreaId] = useState(userDistrict.id || "");

	const userBagoLocation: string | null = localStorage?.getItem("userBagoLocation");
	const category_id = Number(currentCategory?.id);

	const fetchNotices = async (
		settlement: string | undefined,
		category_id: number,
		pageParam: number
	) => {
		let perPage = 12;
		if (pageParam > 1) {
			perPage = 24;
		}

		const { data } = await publicNotice.getFilteredNotices({
			type,
			page: 1,
			perPage,
			onlyTop,
			category_id,
			settlement_id: settlement,
			areaId: areaId,
		});
		return data?.data;
	};

	const { data, fetchNextPage, hasNextPage, refetch, isLoading } = useInfiniteQuery({
		queryKey: ["bestNotices", settlement, category_id],
		initialPageParam: 1,
		queryFn: ({ pageParam = 1 }) => fetchNotices(settlement, category_id, pageParam),
		getNextPageParam: (lastPage, pages) => {
			const totalItems = lastPage?._meta?.totalCount || 0;
			const currentPerPage = pages.length * 12;
			if (currentPerPage < totalItems) {
				return currentPerPage + 12;
			} else {
				return undefined;
			}
		},
	});

	const loadMore = () => {
		fetchNextPage();
	};
	useEffect(() => {
		refetch();
	}, [type, onlyTop, areaId, queryClient, i18n.language]);

	// если в стейте нет settlement, то берем значение с локал-стореджа
	useEffect(() => {
		if (!settlement && !!userBagoLocation) {
			setSettlement(JSON.parse(userBagoLocation).id);
		}
	}, [userBagoLocation, settlement, userLocation]);

	const newAllItems = data?.pages?.[data.pages.length - 1]?.items || [];

	let allItems = [];

	if (newAllItems.length > 0) {
		if (type === 1) {
			allItems = newAllItems.sort(
				(a: publicOneNotice, b: publicOneNotice) =>
					(b.published_at || Infinity) - (a.published_at || Infinity)
			);
		} else if (onlyTop === 1) {
			allItems = newAllItems.sort(
				(a: any, b: any) =>
					(b?.top_at - b?.top_days * 24 * 60 * 60 || Infinity) -
					(a?.top_at - a?.top_days * 24 * 60 * 60 || Infinity)
			);
		} else {
			allItems = newAllItems;
		}
	}

	return (
		<div className={`${s.bestsellersContainer} __container`}>
			<Desktop>
				<div className={s.bestsellersContainerTopPanel}>
					<div className={s.bestsellersLocNameContainer}>
						<h5>{t("Популярний продукт")}</h5>
						<div className={s.locationSelectWrapper}>
							<LocationSelectorBestsellers
								setSettlement={setSettlement}
								setArea={setAreaId}
							/>
						</div>
					</div>

					<div className={s.tabsWrapper}>
						<BestsellersTabs
							setType={setType}
							type={type}
							setOnlyTop={setOnlyTop}
						/>
					</div>
				</div>
			</Desktop>
			<Mobile>
				<div className={s.bestsellersContainerTopPanelMobile}>
					<div className={s.bestsellersPopularProductBlock}>
						<h5 className={s.bestsellersTopPanelTitleMobile}>
							{t("Популярний продукт")}
						</h5>
						<div className={s.locationSelectWrapperMobile}>
							<LocationSelectorBestsellers
								setArea={setAreaId}
								setSettlement={setSettlement}
							/>
						</div>
					</div>
					<div className={s.tabsWrapperMobile}>
						<BestsellersTabs
							type={type}
							setType={setType}
							setOnlyTop={setOnlyTop}
						/>
					</div>
				</div>
			</Mobile>
			{isLoading ? (
				<PreLoaderComponent type={"category"} size={"medium"} />
			) : (
				<div>
					<div className={s.bestsellersContent}>
						<Desktop>
							{allItems.map((item: publicOneNotice) => (
								<div key={item.id}>
									<ProductCard inSwiper={true} notice={item} />
								</div>
							))}
						</Desktop>
					</div>
					<div className={s.bestsellersMobileContent}>
						<Mobile>
							{allItems.map((item: publicOneNotice) => (
								<MobileProductCard notice={item} key={item.id} />
							))}
						</Mobile>
					</div>
				</div>
			)}
			{hasNextPage && (
				<div className={s.bestsellersBottomPanel}>
					<Desktop>
						<ExpandButton
							isExpanded={isExpanded}
							setExpanded={setExpanded}
							onClick={loadMore}
						/>
					</Desktop>
					<Mobile>
						<div className={s.bannerWrap}>
							<ExpandModernButton onClickCallback={loadMore} />
							<div style={{ marginTop: "40px" }}>
								{!auth && <NoRegisterAdCardAdaptive />}
							</div>
						</div>
					</Mobile>
				</div>
			)}
		</div>
	);
};

export default Bestsellers;
