import React from "react";
import s from "./TopTitleBlock.module.css";
import { useNavigate } from "react-router";
import TopTitleBlockSvg from "./TopTitleBlockSvg";
import { useSelector } from "react-redux";
import { StateType } from "../../redux/redux-store";

interface TopTitleBlockProps {
	background?: string;
	title: string;
	hiddenBorder?: boolean;
	handleBack?: (value: any) => void;
}

const TopTitleBlock: React.FC<TopTitleBlockProps> = ({
	background,
	title,
	hiddenBorder,
	handleBack,
}) => {
	const navigate = useNavigate();
	const categoriesListMobile = useSelector(
		(state: StateType) => state.categoryData.categorySecondLevelMobile
	);

	const handleBackFunk = () => {
		navigate(-1);
		if (handleBack) {
			handleBack("");
		}
	};
	return (
		<div
			className={`${hiddenBorder ? s.topTitleBlockMobileHiddenBorder : s.topTitleBlockMobile}`}
			style={{ background: background ? background : "" }}
		>
			<div onClick={handleBackFunk} className={s.topTitleBlockArrow}>
				<TopTitleBlockSvg id="arrowBack" />
			</div>
			{categoriesListMobile?.length >= 5 ? (
				<div
					className={`${s.topTitleBlockTitle} ${s.topTitleBlockTitleCut}`}
					onClick={handleBackFunk}
				>
					<h1>{title}</h1>
				</div>
			) : (
				<div className={s.topTitleBlockTitle} onClick={handleBackFunk}>
					<h1>{title}</h1>
				</div>
			)}
		</div>
	);
};

export default TopTitleBlock;
