import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useParams, useSearchParams } from "react-router-dom";
import { SuperSEO } from "react-super-seo";
import ArrowsAndCross from "../../common/ArrowsAndCross/ArrowsAndCross";
import NoRegisterAdCard from "../../Components/AdCard/NoRegisterAdCard";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";
import CompainModalWindow from "../../Components/CompainModalWindow/CompainModalWindow";
import HeaderMobile from "../../Components/Header/HeaderMobile";
import MyGallery from "../../Components/ImageGallery/ImageGallery";
import { OtherAdsByAuthor } from "../../Components/OtherAdsByAuthor/OtherAdsByAuthor";
import OutputADSvgSelector from "../../Components/OutputBoardAdComponents/OutputADSvgSelector";
import VerticalScrollBar from "../../Components/OutputBoardAdComponents/VerticalScrollBar";
import PreLoaderComponent from "../../common/Animations/PreLoaderComponent/PreLoaderComponent";
import RecentlyWatched from "../../Components/RecentlyWatched/RecentlyWatched";
import SellerCard from "../../Components/SellerCard/SellerCard";
import PublishedTime from "../../Components/TimeAndDate/PublishedTime";
import { TopAdverts } from "../../Components/TopAdverts/TopAdverts";
import Advertising from "../../Components/Сommercial/Advertising";
import { getCategoryBlocks } from "../../redux/category_Reducer";
import {
	favoriteHandler,
	getFilteredNoticeList,
	getNoticeView,
	getTopInRubrics,
} from "../../redux/publicNotice_Reducer";
import { StateType } from "../../redux/redux-store";
import { Desktop, Mobile } from "../../responsive";
import {
	noticeImageAlt,
	noticeLocationName,
	noticePrice,
	scrollToTop,
	truncateText,
} from "../../common/Logic/supporting";
import { publicOneNotice, TreeLinksType } from "../../types/publicNoticeTypes";
import { stateUserType } from "../../types/reduxStoreTypes";
import NoActiveNotice from "../Page404/NoActiveNotice";
import s from "./OutputBoardAD.module.css";
import OutputBoardAdMobile from "./OutputBoardAdMobile";
import WorkNotice from "./WorkNotice/WorkNotice";
import CommentSection from "./CommentSection/CommentSection";
import { setOpenRegAuthPopup } from "../../redux/user_Reducer";
import InterlinkingMain from "../../Components/Interlinking/InterlinkingMain";

type Props = {
	type: string;
};

const OutputBoardAd = ({ type }: Props) => {
	const { i18n, t } = useTranslation();
	const [openCompainModal, setOpenCompainModal] = useState(false);
	const navigate = useNavigate();
	const tmpNotice = useSelector(
		(state: StateType) => state.noticeData.tmpNoticeForPerView
	);
	// const tmpBackendNotice = useSelector((state: StateType) => state.noticeData.tmpBackendNoticeForPerView)
	const images = useSelector((state: StateType) => state.noticeData.images);
	const noticeView = useSelector(
		(state: StateType) => state.publicNotice.noticeView
	);
	const topInRubrics = useSelector(
		(state: StateType) => state.publicNotice.topInRubrics
	);
	const topInRubricsPag = useSelector(
		(state: StateType) => state.publicNotice.topInRubricsPag
	);
	const getTopInThisRubrics = useSelector(() => getTopInRubrics);
	const auth = useSelector((state: stateUserType) => state.userData.isAuth);
	const getBlocks = useSelector(() => getCategoryBlocks);
	const getNotice = useSelector(() => getNoticeView);
	const { itemSlug } = useParams();
	const dispatch: any = useDispatch();
	const location = useLocation();
	const segmentsArray = location.pathname?.split("/").filter(Boolean);
	const addDelInFavorite = useSelector(() => favoriteHandler);
	const blockState = useSelector(
		(state: StateType) => state.categoryData.blockState
	);
	const blockDelivery = useSelector(
		(state: StateType) => state.categoryData.blockDelivery
	);
	const showLoader = useSelector(
		(state: StateType) => state.publicNotice.showLoader
	);
	const errorNoActiveNotice = useSelector(
		(state: StateType) => state.publicNotice.noActiveNoticeError
	);
	const isFavoriteHandler = (action: string) => {
		dispatch(addDelInFavorite(noticeView?.id || 0, action));
	};

	const getNotices: any = useSelector(() => getFilteredNoticeList);
	const noticesByCategory = useSelector(
		(state: StateType) => state.publicNotice.filteredNoticesAllList
	);

	useEffect(() => {
		const id = noticeView.category_id;
		if (id) {
			dispatch(getBlocks(id));
			dispatch(getTopInThisRubrics({ category_id: id, perPage: "4" }));
			dispatch(
				getNotices({
					category_id: +id,
					page: 1,
					perPage: 4,
				})
			);
		}
	}, [noticeView.category_id]);

	useEffect(() => {
		if (segmentsArray && type !== "perView") {
			dispatch(getNotice("", segmentsArray[segmentsArray.length - 1] || ""));
		}
		// dispatch(getTopInThisRubrics({category_id:noticeView?.category_id || 0,perPage:"8",sortType:"4"}))
		scrollToTop();
	}, [segmentsArray[segmentsArray.length - 1], i18n.language]);

	let sellerCard = {
		sellerName: tmpNotice?.sellerName || "",
		sellerId: (noticeView?.users_id && noticeView?.users_id.toString()) || "",
		price: tmpNotice?.price || "",
		maxSalary: tmpNotice?.maxSalary || "",
		location: tmpNotice?.settlementName || "",
		ownerData: tmpNotice?.ownerData || {
			online: false,
			time_last_visit: null,
			photoUrl: null,
		},
		ownerBPData: {
			slug: noticeView.ownerBPData?.slug || "",
			status: noticeView.ownerBPData?.status || "",
			statusName: noticeView.ownerBPData?.statusName || "",
		},
		views: 0,
		isFavorite: 0,
		ownerPhone: noticeView?.ownerContacts?.ownerPhone || "",
		time_registration: noticeView?.ownerContacts?.time_registration || null,
		viber: noticeView?.ownerContacts?.viber,
		whatsapp: noticeView?.ownerContacts?.whatsapp,
		telegram: noticeView?.ownerContacts?.telegram,
		reputation: noticeView?.ownerData?.reputation || null,
		seeNow: noticeView?.seeNow || 0,
	};
	let scrollBarData = {
		title: tmpNotice?.title,
		description: tmpNotice?.description,
		properties: tmpNotice?.notice_properties || [],
		state: tmpNotice?.state,
	};

	if (itemSlug) {
		sellerCard = {
			sellerName: noticeView?.userName || "",
			sellerId: (noticeView?.users_id && noticeView.users_id.toString()) || "",
			price: noticeView?.price || "",
			maxSalary: noticeView?.maxSalary || "",
			location: noticeView?.settlement_name || "",
			ownerData: noticeView?.ownerData || {
				online: false,
				time_last_visit: null,
				photoUrl: null,
			},
			ownerBPData: {
				slug: noticeView.ownerBPData?.slug || "",
				status: noticeView.ownerBPData?.status || "",
				statusName: noticeView.ownerBPData?.statusName || "",
			},
			views: noticeView?.views || 0,
			seeNow: noticeView?.seeNow || 0,
			isFavorite: noticeView?.isFavorite || 0,
			ownerPhone: noticeView?.ownerContacts?.ownerPhone || "",
			time_registration: noticeView?.ownerContacts?.time_registration || null,
			viber: noticeView?.ownerContacts?.viber,
			whatsapp: noticeView?.ownerContacts?.whatsapp,
			telegram: noticeView?.ownerContacts?.telegram,
			reputation: noticeView?.ownerData?.reputation || null,
		};
		scrollBarData = {
			title: noticeView?.name || "",
			description: noticeView?.description || "",
			properties: noticeView?.notice_properties,
			state: noticeView?.state || "",
		};
	}
	const openEnterDialog = () => {
		dispatch(setOpenRegAuthPopup(true));
	};

	const handleOpenLogin = () => {
		openEnterDialog();
	};

	const handleOpenCompainModal = () => {
		setOpenCompainModal(true);
	};
	const handleCloseCompainModal = () => {
		setOpenCompainModal(false);
	};
	const linksForBreadcrumbs = noticeView?.tree.map((l: TreeLinksType) => ({
		name: l.name ? l.name : l.name_ru ? l.name_ru : "",
		alias: l.alias,
		linksLength: null,
		active: false,
	}));

	if (errorNoActiveNotice) {
		return <NoActiveNotice />;
	}

	const isWorkNotice = noticeView.noticeType?.value === 1;
	
	const [seoTitle, setSeoTitle] = useState(getSeoTitle(noticeView));
	const [seoDescription, setSeoDescription] = useState(getSeoDescription(noticeView));

	function getSeoTitle(noticeView?: publicOneNotice) {
		if (!noticeView) return "";
		return `${noticeView.name}: ${noticePrice(t, noticeView.price, noticeView.maxSalary)} - ${noticeLocationName(noticeView)} на BAGO.UA`;
	}
	function getSeoDescription(noticeView?: publicOneNotice) {
		if (!noticeView) return "";
		return `${noticeLocationName(noticeView)}, ${noticePrice(t, noticeView.price, noticeView.maxSalary)} ❱❱ ${t("купити")} на BAGO.UA ${truncateText(noticeView.description)}`;
	}

	const [params, setParams] = useSearchParams();

	useEffect(() => {
		if (noticeView.settlement_slug) {
			params.set("search-location", noticeView.settlement_slug);
			setParams(params);
		}
		setSeoTitle(getSeoTitle(noticeView));
		setSeoDescription(getSeoDescription(noticeView));
	}, [noticeView]);

	return (
		<>
			<SuperSEO
				title={seoTitle}
				description={seoDescription}
				lang={`${i18n.language}-${i18n.language.toUpperCase()}`}
				robots={"bago.ua/robots.txt"}
				openGraph={{
					ogImage: {
						ogImage: noticeView.noticeImages[0]?.imageUrl || "123",
						ogImageAlt: noticeView.name,
						ogImageWidth: 100,
						ogImageHeight: 100,
						ogImageType: noticeView.noticeImages[0]?.type || "image/webp",
					},
					ogTitle: seoTitle,
					ogDescription: seoDescription,
					ogSiteName: "Bago",
					ogLocale: i18n.language === "ru" ? "ru_Ru" : "uk_UK",
					ogUrl: `https://bago.ua${location.pathname}`,
				}}
				twitter={{
					twitterSummaryCard: {
						summaryCardImage: `https://bago.ua${i18n.language === "ru" ? "" : "/ua/"}/`,
						summaryCardImageAlt: "icon",
						summaryCardSiteUsername: "bago",
					},
				}}
			>
				<meta
					name="title"
					content={`${noticeView.name} в ${noticeLocationName(noticeView)} на ✔️ BAGO`}
				/>
				<meta name={noticeView.name} content={noticeView.description} />
			</SuperSEO>
			<Desktop>
				<div className={`${s.wrapper}`}>
					<div className={`${s.outputBoardAdContainer} `}>
						{type === "view" ? (
							<div className={s.breadCrumbs}>
								<BreadCrumbs
									links={[
										...linksForBreadcrumbs,
										{
											name: noticeView.name,
											alias: noticeView.slug,
											linksLength: null,
											active: true,
										},
									]}
								/>
							</div>
						) : (
							<div
								className={s.backArrowContainer}
								//onClick={() => navigate(`${i18n.language ==="ru"?"":"/ua/"}create_ad`)}
								onClick={() => navigate(-1)}
							>
								<ArrowsAndCross id={"backArrow"} color={"black"} />
								{t("Повернутися до створення")}
							</div>
						)}
						{!isWorkNotice && itemSlug ? (
							<h1 className={s.noticeName}>{noticeView?.name}</h1>
						) : (
							<h1>{tmpNotice?.title || ""}</h1>
						)}
						{isWorkNotice ? (
							<WorkNotice
								handleOpenCompainModal={handleOpenCompainModal}
								handleOpenLogin={handleOpenLogin}
								card={sellerCard}
							/>
						) : (
							<div className={s.adContent}>
								<div className={s.adContentLeft}>
									<div className={s.adContentLeftSlider}>
										{itemSlug ? (
											<>
												{showLoader ? (
													<PreLoaderComponent type={"gallery"} size={"big"} />
												) : (
													<MyGallery
														id={noticeView.id ? noticeView.id.toString() : ""}
														type={type}
														alt={noticeImageAlt(noticeView)}
														imagesUrl={
															noticeView?.noticeImages || images || [""]
														}
													/>
												)}
											</>
										) : (
											images.length > 0 && (
												<>
													{showLoader ? (
														<PreLoaderComponent type={"gallery"} size={"big"} />
													) : (
														<MyGallery
															alt={noticeImageAlt(noticeView)}
															type={type}
															id={noticeView.id ? noticeView.id.toString() : ""}
															images={images.length > 0 ? images : []}
														/>
													)}
												</>
											)
										)}
									</div>
									{type !== "perView" && (
										<Desktop>
											<Advertising idAD={5} />
										</Desktop>
									)}
								</div>
								<div className={s.adContentRight}>
									<div className={s.adContentRightSellerCard}>
										<SellerCard
											sellerCard={sellerCard}
											isFavoriteHandler={isFavoriteHandler}
										/>
									</div>
									<div className={s.adContentRightInfo}>
										<VerticalScrollBar
											marginTop={"20px"}
											scrollBarData={scrollBarData}
											isShowStateProduct={blockState}
											isShowDelivery={blockDelivery}
										/>
									</div>
									<div className={s.adContentBottomPanel}>
										<div className={s.publicationTime}>
											{t("Опубліковано")}:{" "}
											<span>
												<PublishedTime time={noticeView?.published_at || 0} />
											</span>
										</div>
										<div className={s.allRightInfo}>
											<span>ID: {noticeView?.id}</span>
											{auth ? (
												<div
													className={`${s.rightInfoButton} ${s.warningIcon}`}
													onClick={handleOpenCompainModal}
												>
													<OutputADSvgSelector id={"warningComplain"} />
													<span> {t("Поскаржитись")}</span>
												</div>
											) : (
												<div
													className={`${s.rightInfoItem} ${s.warningIcon}`}
													onClick={handleOpenLogin}
												>
													<OutputADSvgSelector id={"warningComplain"} />
													<span> {t("Поскаржитись")}</span>
												</div>
											)}
										</div>
									</div>
									<CommentSection />
								</div>
							</div>
						)}
					</div>
					{type !== "perView" && (
						<div className={s.homePageGreyFon}>
							{/* <NewAdverts title={t("З цим товаром переглядають")}  />*/}
							<OtherAdsByAuthor
								notice={noticeView.ownerBPData}
								userId={noticeView ? noticeView.users_id : 0}
								title={t("Інші оголошення автора")}
							/>

							{!auth && <NoRegisterAdCard />}
							{/*<NewAdverts title={t("Схожі оголошення")}/>*/}
							<Desktop>
								<div className={`${s.adsBlock} __container`}>
									<Advertising idAD={6} />
								</div>
							</Desktop>

							<TopAdverts
								title={t("Інші товари в цій рубриці")}
								notices={
									topInRubrics.length > 0 ? topInRubrics : noticesByCategory
								}
								noticesPag={topInRubricsPag}
								categoryId={noticeView.category_id?.toString() ?? ""}
							/>
							<RecentlyWatched />
						</div>
					)}
					<InterlinkingMain
						page={"OutputBordAD"}
						inSearch={false}
						categoryName={noticeView.category_name}
						categoryId={noticeView.category_id}
					/>
				</div>
			</Desktop>
			<Mobile>
				<HeaderMobile />
				<OutputBoardAdMobile
					scrollBarData={scrollBarData}
					topInRubrics={topInRubrics}
					card={sellerCard}
				/>
			</Mobile>
			{openCompainModal && (
				<CompainModalWindow
					dialogClose={handleCloseCompainModal}
					dialogOpen={openCompainModal}
					sellerName={noticeView?.userName}
					lastVisit={noticeView?.ownerData?.time_last_visit ?? 0}
					ownerImage={noticeView?.ownerData?.photoUrl}
					productImage={noticeView?.noticeImages[0]?.imageUrl ?? ""}
					productName={noticeView?.name ?? ""}
					productPrice={noticeView?.price ?? ""}
					isShowProduct={true}
					idForCreate={noticeView?.id ?? 0}
					userId={""}
				/>
			)}
		</>
	);
};

export default OutputBoardAd;
