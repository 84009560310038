import React, { useEffect, useState } from "react";
import s from "../InterlinkingMain.module.css";
import { colorArray } from "../../../data_arrays/BannersData";
import LogoSvgSelector from "../../../common/LogoSVGSelectors/LogoSvgSelector";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCAllLastUsersSearches } from "../../../redux/search_Reducer";
import { StateType } from "../../../redux/redux-store";
import i18n from "i18next";

const SearchingNow = () => {
	const dispatch:any = useDispatch();
	const [randomColor, setRandomColor] = useState("");
	const { t } = useTranslation();
	const getAllSearches = useSelector(()=>getCAllLastUsersSearches)
	const allLastUsersSearchList = useSelector((state:StateType)=>state.searchData.allLastUsersSearchList);
	const languagePath = i18n.language === "ru" ? "" : "ua/";
	useEffect(() => {
		if(!allLastUsersSearchList){
			dispatch(getAllSearches());
		}
	}, []);

	useEffect(() => {
		const randomColorIndex = Math.floor(Math.random() * colorArray.length);
		setRandomColor(colorArray[randomColorIndex]);
	}, []);


	if (!randomColor) {
		return null;
	} else
		return (
			<div className={`${s.container} `}>
				<div style={{ background: `#${randomColor}`, borderRadius: "5px" }}>
					<div className={s.cardContainer}>
						<div className={s.logo}>
							<LogoSvgSelector id={"logoForSearchParams"} />
						</div>
						<div className={s.content}>
							<div className={s.title}>
								<h4>{t(`Зараз шукають`)}</h4>
							</div>
							<ul className={s.linksContainer}>
								{allLastUsersSearchList?.map((item, index) => (
									<li className={s.linkItem} key={`${item.query}_${index}`}>
										<Link to={`/${languagePath}search_result?search=${item.query}`}>{item.query}</Link>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
		);
};

export default SearchingNow;
