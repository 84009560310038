import { useEffect } from "react";
import ImageGallery from "react-image-gallery";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { getNoticeView } from "../../redux/publicNotice_Reducer";
import { StateType } from "../../redux/redux-store";
import s from "./FullScreen.module.css";
import GallerySvgSelector from "./GallerySvgSelector";
const FixedImageGallery = ImageGallery as any;
const FullScreenImageGallery = () => {
	const navigate = useNavigate();
	const { itemId, itemIndex } = useParams();
	const dispatch: any = useDispatch();
	const getNotice = useSelector(() => getNoticeView);

	const noticeView = useSelector(
		(state: StateType) => state.publicNotice.noticeView
	);

	let items = [
		{
			original: "",
			thumbnail: "",
			originalAlt: "img",
			thumbnailAlt: "img",
		},
	];

	useEffect(() => {
		if (itemId) {
			dispatch(getNotice(itemId));
		}
		window.scrollTo(0, 0);
	}, []);

	if (itemId) {
		items = noticeView?.noticeImages?.map(
			(i) =>
				({
					original: i.originalImageUrl || "",
					thumbnail: i.imageUrl || "",
					originalAlt: noticeView.slug || "icon",
					thumbnailAlt: noticeView.slug || "icon",
				}) || []
		);
	}

	return (
		<div className={s.fullscreenGalleryWrapper}>
			<div className={s.fullscreenGalleryContainer}>
				<div className={`${s.topPanel}`}>
					<div className={s.leftSide}>
						<span className={s.backArrow} onClick={() => navigate(-1)}>
							<GallerySvgSelector id={"backArrow"} />
						</span>
						<h1 onClick={() => navigate(-1)}>{noticeView?.name || ""}</h1>
					</div>
					<span className={s.closeCross} onClick={() => navigate(-1)}>
						<GallerySvgSelector id={"closeCross"} />
					</span>
				</div>
				<div className={`${s.content}`}>
					<div className={s.gallery}>
						<FixedImageGallery
							items={items || []}
							/*onClick={async () => {
                                          navigate(`gallery_chat`);
                                      }}*/
							startIndex={!!itemIndex ? +itemIndex - 1 : 0}
							showIndex
							slideDuration={0}
							showFullscreenButton={false}
							useBrowserFullscreen={false}
							indexSeparator={" з "}
							showPlayButton={false}
							additionalClass={"fullscreenGallery"}
							thumbnailPosition={"left"}
						/>
					</div>
					{/*<div className={s.contacts}>
                    <span className={s.sellerName}>
                        <span className={s.green}/>
                        {noticeView?.userName || ""}
                    </span>
                        <span className={s.buttons}>
                        <button>
                            Позвонить
                        </button>
                        <button>Быстрый чат с продавцом</button>
                    </span>
                        <div className={s.advertising}>

                        </div>
                    </div>*/}
				</div>
			</div>
		</div>
	);
};

export default FullScreenImageGallery;
